import { useTranslation } from 'react-i18next';
import useStepCarousel from '../../../ui/stepCarouselList/useStepCarousel';
import { useColumns } from '../hooks/useColumns';

import { Mode, Type } from '../../../../types/entitiesEnums/esgMetrics';
import SlideToggleFacade from '../../../ui/formComponents/slideToggle3/SlideToggleFacade';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';

import { getCurrentYear, getPreviousYear, parseDataForSelectIntensityMetric } from '../utils/utils';

import { downloadEsgExcel } from '../../../../services/api/esg';
import Button from '../../../ui/button/Button';
import InputWrapperPlain from '../../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import Select from '../../../ui/formComponents2/select/Select';
import TableComponent from '../../../ui/table/Table';

type Props = {
  data?: EsgMetricFrontend; //ojo cambiar
  loading: boolean;
  setAbsoluteOrIntensity: (value: SelectOptionFormat) => void;
  absoluteOrIntensity: SelectOptionFormat<string>;
  intensityMetricData: SelectOptionFormat<string>[];
  impactPerMetricIntensityMetric: SelectOptionFormat<string>;
  setImpactPerMetricIntensityMetric: (value: SelectOptionFormat) => void;
};

const EsgCard = ({
  data,
  loading,
  absoluteOrIntensity,
  setAbsoluteOrIntensity,
  intensityMetricData,
  impactPerMetricIntensityMetric,
  setImpactPerMetricIntensityMetric
}: Props) => {
  const { t, i18n } = useTranslation();

  const previousYear = getPreviousYear();
  const currentYear = getCurrentYear();

  const dateRanges = [
    [`${previousYear}/01/01`, `${previousYear}/12/31`],
    [`${currentYear}/01/01`, `${currentYear}/12/31`]
  ];

  const toggleOptions = [
    {
      id: Mode.ABSOLUT,
      name: t('dashboard.absolute')
    },
    {
      id: Mode.INTENSITY,
      name: t('dashboard.intensity')
    }
  ];

  const steps = [
    {
      id: Type.ENVIRONMENTAL,
      text: t('dashboard.environmental')
    },
    {
      id: Type.SOCIAL_AND_GOVERNANCE,
      text: t('dashboard.socialAndGovernance')
    }
  ];

  const {
    steps: carouselSteps,
    stepSelected,
    handleSelect
  } = useStepCarousel({
    stepsText: steps
  });

  const handleData = () => {
    return stepSelected?.id === Type.ENVIRONMENTAL ? data?.environmental : data?.socialGovernance;
  };

  const handleSwitchMode = (value: string) =>
    setAbsoluteOrIntensity(
      toggleOptions.find((option) => option.id === value) || { id: '', name: '' }
    );

  const { columnsMetrics } = useColumns();

  const onClick = async () => {
    // download excel
    const response = await downloadEsgExcel({
      dateRanges,
      mode: absoluteOrIntensity.id as Mode,
      intensitymetricGroupId: impactPerMetricIntensityMetric.id,
      lang: i18n.resolvedLanguage
    });
    if (response?.response?.status >= 400) return;

    const link = document.createElement('a');
    link.href = response;
    link.setAttribute('download', 'esg-metrics.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const processData = () => {
    let dataToReturn: EsgMetricInfo[] = [];
    if (
      loading ||
      (absoluteOrIntensity.id === Mode.INTENSITY && !impactPerMetricIntensityMetric.id)
    )
      return dataToReturn;

    dataToReturn = handleData() || [];

    return dataToReturn;
  };

  const processTextEmpty = () => {
    if (absoluteOrIntensity.id === Mode.INTENSITY && !impactPerMetricIntensityMetric.id)
      return t('table.selectIntensityMetric');
    return t('table.emptyEsg');
  };

  const dataToShow = processData();
  const textEmpty = processTextEmpty();

  return (
    <div className='flex-col impact-intensity-metric'>
      <div className='flex justify-between'>
        <StepCarouselList steps={carouselSteps} handleSelect={handleSelect} />
        <SlideToggleFacade
          handleSwitchMode={handleSwitchMode}
          idSelected={absoluteOrIntensity.id}
          options={toggleOptions}
        />
      </div>
      <div className='infinite-scroll-container card-border-color main-bg-color solid-border overflow-scroll'>
        <div className='flex justify-end' style={{ marginBottom: '1rem' }}>
          <Button
            lookAndFeel={'primary'}
            text={t('general.download')}
            onClick={onClick}
            style={{
              width: '10rem',
              padding: '0.5rem 1rem',
              margin: '0 0.66rem'
            }}
          />
          {absoluteOrIntensity.id === Mode.ABSOLUT ||
          stepSelected?.id === Type.SOCIAL_AND_GOVERNANCE ? null : (
            <InputWrapperPlain iconV2='filter' width='20%'>
              <Select
                onChange={(e) => setImpactPerMetricIntensityMetric(e)}
                value={impactPerMetricIntensityMetric}
                options={parseDataForSelectIntensityMetric(intensityMetricData)}
                placeholder={t('dashboard.phIntensityMetric')}
              />
            </InputWrapperPlain>
          )}
        </div>

        <div className='infinite-scroll-wrapper'>
          <TableComponent
            columns={columnsMetrics}
            data={dataToShow}
            loading={loading}
            emptyText={textEmpty}
            rowClassName='whitespace-normal-row'
            // sticky={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EsgCard;
