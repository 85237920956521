import moment from 'moment';
import { GroupDataMode } from '../../types/entitiesEnums/report';
import apiFetch from '../../utils/apiFetch';
import dateToTimestamp from '../../utils/dateToTimestamp';

export const getMeasureCategories = async (
  organization_id: string,
  start_date: Date,
  end_date: Date
) => {
  try {
    const response = await apiFetch('post', '/total_impacts/measure_categories', {
      organization_id,
      start_date: new Date(moment(start_date).format('YYYY-MM-DD')),
      end_date: new Date(moment(end_date).format('YYYY-MM-DD'))
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTotalImpactsByDate = async (
  start_date: Date,
  end_date: Date,
  organization_id: string
) => {
  try {
    const response = await apiFetch('post', '/total_impacts/date', {
      start_date: new Date(moment(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
      end_date: new Date(moment(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
      organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTotalImpactsByMonth = async (
  start_date: string,
  end_date: string,
  organization_id: string,
  data_group_id?: string,
  returnRequest = false
) => {
  try {
    const filters = {
      start_date: new Date(moment.utc(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
      end_date: new Date(moment.utc(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
      organization_id
    };
    if (data_group_id) {
      Object.assign(filters, { data_group_id });
      Object.assign(filters, { switch: 'data_group' });
    }
    const request = apiFetch('post', '/total_impacts/month', filters);
    if (returnRequest) return request;
    const response = await request;

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getTotalImpactsOffset = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  scopes: Array<1 | 2 | 3>,
  returnRequest = false
) => {
  try {
    const request = await apiFetch('POST', '/total_impacts/offsets', {
      organization_id: organizationId,
      start_date: moment(new Date(startDate)).format('YYYY-MM-DD'),
      end_date: moment(new Date(endDate)).format('YYYY-MM-DD'),
      scopes: scopes
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTotalImpactsByScope = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  returnRequest = false
) => {
  try {
    const request = await apiFetch('POST', '/total_impacts/scope', {
      organization_id: organizationId,
      start_date: moment(new Date(startDate)).format('YYYY-MM-DD'),
      end_date: moment(new Date(endDate)).format('YYYY-MM-DD')
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const isDashboardEnabled = async (returnRequest = false) => {
  try {
    const request = apiFetch('GET', '/total_impacts/is_dashboard_enabled');
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getBusinessTravelsGroupedByRouteAndTransport = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  groupDataMode: GroupDataMode,
  returnRequest = false
) => {
  try {
    const request = apiFetch(
      'POST',
      '/total_impacts/business_travels/grouped_by_route_and_transport_type',
      {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
        switch: groupDataMode
      }
    );
    if (returnRequest) return request;
    const response = await request;

    return response.data;
  } catch (err) {
    return err;
  }
};

export const getBusinessTravelsGroupedByTransport = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  groupDataMode: GroupDataMode,
  returnRequest = false
) => {
  try {
    const request = apiFetch('POST', '/total_impacts/business_travels/grouped_by_transport_type', {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
      switch: groupDataMode
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getEmployeesFacade = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  groupDataMode: GroupDataMode,
  returnRequest = false
) => {
  try {
    const request = apiFetch('POST', '/total_impacts/employees/facade/report', {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
      switch: groupDataMode
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getEmployees = async (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  returnRequest = false
) => {
  try {
    const request = apiFetch('POST', '/total_impacts/employees', {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getIso14064Report = async (
  start_date: string,
  end_date: string,
  lang: string,
  organization_id: string
) => {
  try {
    const response = await apiFetch('POST', `/total_impacts/category_framework_converter`, {
      organization_id,
      start_date,
      end_date,
      to_framework: 'iso_14064',
      from_framework: 'dcycle',
      lang
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getYearComparison = async (
  start_date: string,
  end_date: string,
  organization_id: string,
  mode: string,
  returnRequest = false
) => {
  try {
    // convert dates from dd/mm/yyyy to timestamp
    const startDateTimestamp = dateToTimestamp(
      new Date(moment(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    );
    const endDateTimestamp = dateToTimestamp(
      new Date(moment(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    );
    const request = apiFetch(
      'GET',
      '/total_impacts/year_comparison',
      {},
      {
        x_organization_id: organization_id
      },
      {
        start_date: startDateTimestamp,
        end_date: endDateTimestamp,
        switch: mode
      }
    );
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getTotalImpactPurchases = async (
  start_date: Date,
  end_date: Date,
  organization_id: string,
  groupDataMode: GroupDataMode,
  report?: string,
  returnRequest = false
) => {
  try {
    const request = apiFetch('post', '/total_impacts/purchases', {
      organization_id,
      start_date,
      end_date,
      report,
      switch: groupDataMode
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTotalImpactsInvestments = async (
  start_date: Date,
  end_date: Date,
  organization_id: string,
  returnRequest = false
) => {
  try {
    const request = apiFetch('post', '/total_impacts/investments', {
      organization_id,
      start_date,
      end_date
    });
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTotalImpactsByCategoryYearly = async (goalId: string) => {
  try {
    const response = await apiFetch('GET', `/total_impacts/yearly/${goalId}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getTotalImpactsByCategoryYearlySbti = async ({ id }: { id: string }) => {
  try {
    const response = await apiFetch('GET', `/total_impacts/yearly_sbti/${id}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getYearComparision = async (category: any, dateRanges: any) => {
  try {
    const response = await apiFetch('GET', '/year_comparision', { category, dateRanges });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const esgMetrics = async (
  intensityMetric: string | null,
  dateRanges: string[][],
  mode: string
) => {
  try {
    const response = await apiFetch('POST', '/esg/metrics', {
      intensity_metric_group_id: intensityMetric,
      date_ranges: dateRanges,
      mode: mode
    });

    return response.data;
  } catch (err) {
    return err;
  }
};

export const getEsgImpacts = async (dateRanges: string[][], mode: string, metric: string) => {
  try {
    const response = await apiFetch('POST', '/esg/impacts', {
      date_ranges: dateRanges,
      mode: mode,
      metric: metric
    });

    return response.data;
  } catch (err) {
    return err;
  }
};
