import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import SlideToggleFacade from '../../../../ui/formComponents/slideToggle3/SlideToggleFacade';
import { useSearchParams } from 'react-router-dom';
import { getMode } from '../utils/getMode';
import { LCA_DETAIL_OPTIONS, LCA_DETAIL_STEPS } from '../constants';

export const LCADetailFacade = () => {
  const { t } = useLCATranslation();

  const [searchParams, setSearchParams] = useSearchParams({
    mode: LCA_DETAIL_OPTIONS.environmental_impacts
  });

  const handleSwitchMode = (newMode: string) => {
    setSearchParams({ mode: newMode });
  };

  const mode = getMode(searchParams.get('mode'));

  return (
    <SlideToggleFacade
      handleSwitchMode={handleSwitchMode}
      options={LCA_DETAIL_STEPS.map((step) => ({
        id: step.id,
        name: t(step.name)
      }))}
      idSelected={mode}
    />
  );
};
