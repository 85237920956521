import ReactFlow, { Background, BackgroundVariant, Controls, Edge, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';

import { EDGE_TYPES, NODE_COMPONENTS } from '../reactFlowConfig';
import { useCallback, useEffect, useState } from 'react';
import { useLCANodes } from '../hooks/useLCANodes';
import { useLCABlocks } from '../hooks/useLCABlocks';
import { useDeleteLCAConnection } from '../hooks/useDeleteLCAConnection';
import { useLCAEdges } from '../hooks/useLCAEdges';
import { useParams } from 'react-router-dom';
import { ScenarioNodeData } from '../types/LCAScenarios';
import { CreateNode } from './CreateNode/CreateNode';
import { SideModal } from '../../../layout/sideModal/SideModal';
import { useDrop } from 'react-dnd';
import { nodeToSubmit } from '../schema/nodeToSubmit';
import { useCreateNode } from '../hooks/useCreateNode';
import { withReactFlow } from '../../../../lib/ReactFlow/withReactFlow';
import { usePatchNode } from '../hooks/usePatchNode';
import { SelectMaterialModal } from './SelectMaterialModal/SelectMaterialModal';
import { useCreateLCAConnection } from '../hooks/useCreateLCAConnection';

export const LCADiagram = withReactFlow(() => {
  const { id } = useParams<{ id: string }>();
  const acv_id = id ?? 'none';
  const { createNode } = useCreateNode({ acv_id });
  const { patchNode } = usePatchNode({ acv_id });
  const { screenToFlowPosition } = useReactFlow();
  const { lcaBlocks, isLoading: loadingLCABlocks } = useLCABlocks({ acv_id });

  const { deleteConnection } = useDeleteLCAConnection();
  const { createConnection } = useCreateLCAConnection();

  const [edges, _setEdges, onEdgesChange] = useLCAEdges({
    lcaBlocks: lcaBlocks ?? [],
    isLoading: loadingLCABlocks
  });

  const [nodes, _setNodes, onNodesChange] = useLCANodes({
    lcaBlocks: lcaBlocks ?? [],
    isLoading: loadingLCABlocks
  });

  const [isDeletable, setIsDeletable] = useState(false);
  const [objectToSubmit, setObjectToSubmit] = useState<
    | {
        type?: 'process' | 'material';
        target?: string;
        coordinate_x?: number;
        coordinate_y?: number;
      }
    | undefined
  >();
  const [target, setTarget] = useState<ScenarioNodeData | undefined>();

  const [_collected, drop] = useDrop(
    () => ({
      accept: 'lca-node',
      drop: (item: { type: 'process' | 'material' }) => {
        setObjectToSubmit((prev) => ({
          ...prev,
          type: item.type,
          target: target?.id
        }));
      }
    }),
    [JSON.stringify(target)]
  );

  const handleDeleteConnection = useCallback(async (edges: Edge[]) => {
    await Promise.all(
      edges.map((edge) => {
        deleteConnection({ acv_id, source_id: edge.source, target_id: edge.target });
      })
    );
  }, []);

  useEffect(() => {
    const validation = nodeToSubmit.safeParse(objectToSubmit);

    if (validation.success) {
      createNode({
        ...validation.data,
        acv_id
      });

      setObjectToSubmit(undefined);
    }
  }, [JSON.stringify(objectToSubmit)]);

  return (
    <SelectMaterialModal.Root>
      <SelectMaterialModal acv_id={acv_id} />
      <CreateNode.Provider target={target} setTarget={setTarget}>
        <SideModal.Base isOpen={Boolean(target)} setIsOpen={() => setTarget(undefined)}>
          {target && <CreateNode />}
        </SideModal.Base>

        <div className='flex-grow relative border-box on-card-gray-bg-color card-border-color border-1 border-solid rounded-8'>
          <ReactFlow
            nodes={nodes}
            nodeTypes={NODE_COMPONENTS}
            edges={edges}
            edgeTypes={EDGE_TYPES}
            proOptions={{ hideAttribution: true }}
            fitView
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgesDelete={handleDeleteConnection}
            onNodeDragStop={async (_event, node) => {
              await patchNode({
                acv_id,
                node_id: node.id,
                coordinate_x: node.position.x,
                coordinate_y: node.position.y
              });
            }}
            onDrop={(event) => {
              const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY
              });

              setObjectToSubmit((prev) => ({
                ...prev,
                coordinate_x: position.x,
                coordinate_y: position.y
              }));
            }}
            onSelectionChange={({ nodes }) => {
              setIsDeletable(Boolean(!nodes?.length));
            }}
            onConnect={({ source, target }) => {
              if (!source || !target) return;

              createConnection({ acv_id, source_id: source, target_id: target });
            }}
            deleteKeyCode={isDeletable ? ['Backspace', 'Delete'] : ''}
            ref={drop}>
            <Controls />
            <Background variant={BackgroundVariant.Lines} gap={48} />
          </ReactFlow>
        </div>
      </CreateNode.Provider>
    </SelectMaterialModal.Root>
  );
});
