import { useQuery } from '@tanstack/react-query';
import { getLCAMaterialNodeMetadata } from '../../../../services/api/lca/lcaScenarios';

type GetNodeMaterialParams = {
  node_id: string;
  acv_id: string;
};

export const useGetNodeMaterial = ({ node_id, acv_id }: GetNodeMaterialParams) => {
  const { data } = useQuery({
    queryKey: ['lcaNodeMaterial', acv_id, node_id],
    queryFn: () => getLCAMaterialNodeMetadata({ node_id, acv_id })
  });

  return data;
};
