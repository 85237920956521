import apiFetch from '../../../../utils/apiFetch';

type CancelLCARequestParams = {
  id?: string;
};

const URL = '/organization_recommendations';

export const deleteLCARequest = async ({ id }: CancelLCARequestParams) => {
  if (!id) {
    throw new Error('ID is required');
  }

  const res = await apiFetch('delete', `${URL}/${id}`);

  return res.data;
};
