import { useLCATranslation } from '../../../customHooks/translations/useLCATranslation';
import { Card } from '../../layout/Cards/Cards';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import { useLCAPortfolio } from './hooks/useLCAPortfolio';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import InfiniteScroll from 'react-infinite-scroll-component';
import TotalLegendV2 from '../../ui/totalLegend/TotalLegendV2';
import { useState } from 'react';
import { IFilter } from '../../../utils/url';
import { FilterText } from '../../layout/NewFilters/FilterText';
import { Filters } from '../../layout/NewFilters/Filters';
import { RequestLCA } from './RequestLCA';
import { CreateLCA } from './CreateLCA/CreateLCA';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';

export const LCAPortfolio = () => {
  const { t } = useLCATranslation();

  const [filters, setFilters] = useState<IFilter[]>([]);

  const flags = useFeatureFlags();

  const { data, isLoading, fetchNextPage, hasNextPage } = useLCAPortfolio({
    filters,
    old_flow: !flags?.lcaScenariosDiagram
  });

  if (!isLoading && !data?.items.length && !flags?.lcaScenariosDiagram) {
    return <RequestLCA />;
  }

  return (
    <section className='flex-col gap-y-6 h-95svh overflow-hidden'>
      <Filters.Root.FiltersOnly filters={filters} setFilters={setFilters}>
        <SectionHeader title={t('portfolio.sectionHeader')} />
        <div className='flex items-center justify-between w-full'>
          <div className='card-bg-color w-70 p-2 card-border-color border-solid border-1 rounded-8'>
            <FilterText field='name' type='il' placeholder={t('portfolio.searchBar')} />
          </div>
          <div className='card-bg-color p-2 card-border-color border-solid border-1 rounded-8'>
            <TotalLegendV2
              totalLabel={t('portfolio.total')}
              totalElements={[
                {
                  value: data?.total ?? 0,
                  label: t('portfolio.lca')
                }
              ]}
            />
          </div>
        </div>

        <div className='flex-grow h-full overflow-y-auto' id='infinite-scroll-container'>
          {isLoading && <LoaderTables />}

          {!isLoading && data && (
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={data?.items.length ?? 0}
              loader={null}
              scrollableTarget='infinite-scroll-container'>
              <Card.Container cardSizeInRem={10}>
                {flags?.lcaScenariosDiagram && <CreateLCA />}
                {data?.items.map((item) => (
                  <Card.Link to={item.id} key={item.id}>
                    <Card.Layout type='item'>
                      <Card.Title text={item.name} />
                      <Card.Subtitle text={item.description} />
                    </Card.Layout>
                  </Card.Link>
                ))}
              </Card.Container>
            </InfiniteScroll>
          )}
        </div>
      </Filters.Root.FiltersOnly>
    </section>
  );
};
