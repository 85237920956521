import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useFetchInfiniteList<GenericData = any, GenericRestPagination extends object = any>(
  fetch: (
    pageNumber: number
  ) => Promise<Pagination<GenericData, GenericRestPagination> | undefined>,
  dependencyArray: Array<unknown>,
  idLabel: keyof GenericData = 'id' as keyof GenericData
) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<GenericData[]>([]);
  const [total, setTotal] = useState(0);
  const [rest, setRest] = useState<GenericRestPagination>(); // eslint-disable-line

  const [loading, setLoading] = useState(false);

  // common function to fetch data
  const fetchData = useCallback(
    async (
      pageNumber: number
    ): Promise<Pagination<GenericData, GenericRestPagination> | undefined> => {
      let response;

      // get new data
      try {
        response = await fetch(pageNumber);
      } catch (error) {
        return;
      }

      return response;
    },
    [fetch]
  );

  const refreshData = async () => {
    if (loading) return;

    // reset page in order to make first loading works
    setPage(1);

    // adapt data
    setLoading(true);
    const response = await fetchData(1);
    setLoading(false);

    setPage(2);

    if (!response) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { items: responseItems, total: responseTotal, page: _, ...responseRest } = response;

    setData(responseItems);
    setTotal(responseTotal);
    setRest(responseRest as GenericRestPagination);
  };

  // fetch data for the next pages
  const fetchMoreData = useCallback(async () => {
    if (loading || data.length >= total) return;

    // adapt data
    setLoading(true);
    const response = await fetchData(page);
    setLoading(false);

    setPage((prev) => prev + 1);

    if (!response) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { items: responseItems, total: responseTotal, page: _, ...responseRest } = response;

    setData((prev) => prev.concat(responseItems));
    setTotal(responseTotal);
    setRest(responseRest as GenericRestPagination);
  }, [fetchData]);

  // Every time the array of dependencies changes, we fetch the data again
  useEffect(() => {
    let ignore = false;

    // reset page in order to make first loading works
    setPage(1);

    // adapt data
    setLoading(true);
    fetchData(1).then((res) => {
      if (ignore) {
        return;
      }

      setLoading(false);

      setPage(2);

      if (!res) return;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { items: responseItems, total: responseTotal, page: _, ...responseRest } = res;

      setData(responseItems);
      setTotal(responseTotal);
      setRest(responseRest as GenericRestPagination);
    });

    return () => {
      ignore = true;
    };
  }, dependencyArray);

  const addElement = useCallback((element: GenericData) => {
    setData((prev) => [element, ...prev]);
    setTotal((prev) => prev + 1);
  }, []);

  const removeElement = useCallback((id: string, key?: keyof GenericData) => {
    setData((prev) => prev.filter((element) => element[key || idLabel] !== id));
    setTotal((prev) => prev - 1);
  }, []);

  const addElements = useCallback((elements: GenericData[]) => {
    setData((prev) => [...prev, ...elements]);
    setTotal((prev) => prev + elements.length);
  }, []);

  const editElement = useCallback((element: GenericData) => {
    setData((prev) =>
      prev.map((prevElement) => (prevElement[idLabel] === element[idLabel] ? element : prevElement))
    );
  }, []);

  return {
    fetchData: fetchMoreData,
    refreshData,
    setData,
    firstLoading: page === 1 && loading,
    loading,
    total,
    setTotal,
    data,
    addElement,
    editElement,
    removeElement,
    addElements,
    rest // if the response has different fields, we can access thems
  };
}

export default useFetchInfiniteList;
