import { useTranslation } from 'react-i18next';
import { CASE_OF_SUCCESS_BLUE_BANANA } from '../../../../constants';
import './styles.scss';

const OffsetText = () => {
  const { t, i18n } = useTranslation();
  const CASE_OF_SUCCESS_BLUE_BANANA_TRANSLATED =
    i18n.resolvedLanguage === 'es'
      ? CASE_OF_SUCCESS_BLUE_BANANA.es
      : CASE_OF_SUCCESS_BLUE_BANANA.en;
  return (
    <div className='text-wrapper-gap'>
      <span className='headline2-font'>{t('offset.text.title')}</span>
      <h2 className='subtitle2-font' style={{ margin: 0 }}>
        {t('offset.text.subtitle')}
      </h2>

      <div className='list-wrapper-offset'>
        <div className='element-offset span-font'>
          <img className='image-checked' src='/images/icons/tickGradientCircle.svg' alt='checked' />
          <span>{t('offset.text.span1')}</span>
        </div>

        <div className='element-offset span-font'>
          <img className='image-checked' src='/images/icons/tickGradientCircle.svg' alt='checked' />
          <span>{t('offset.text.span2')}</span>
        </div>

        <div className='element-offset span-font'>
          <img className='image-checked' src='/images/icons/tickGradientCircle.svg' alt='checked' />
          <span>{t('offset.text.span3')}</span>
        </div>
      </div>

      {/* <span className='headline4-font'>
        {t('offset.text.exitCase')}{' '}
        <a
          href={CASE_OF_SUCCESS_BLUE_BANANA_TRANSLATED}
          target='_blank'
          rel='noreferrer'
          className='highlight-text-color'>
          {t('offset.text.blueBanana')}
        </a>
      </span> */}
    </div>
  );
};

export default OffsetText;
