import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { METRICS_ARTICLE } from '../../../../constants';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { DashboardMode } from '../../../../types/entitiesEnums/dashboard';
import Button from '../../../ui/button/Button';
import SlideToggleFacade from '../../../ui/formComponents/slideToggle3/SlideToggleFacade';
import HelpCTA from '../../../ui/helpCTA/HelpCTA';
import DashboardV2 from '../../DashboardV2';
import './styles.scss';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';

export const Layout = () => {
  const selectedOrganization = useSelectedOrganization();
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState<DashboardMode>(DashboardMode.TOTAL);
  const flags = useFeatureFlags();

  const toggleOptions = [
    {
      id: DashboardMode.TOTAL,
      name: t('dashboard.consolidated'),
      tooltip: t('dashboard.consolidatedTooltip')
    },
    {
      id: DashboardMode.INVESTMENT,
      name: t('dashboard.investment'),
      tooltip: t('dashboard.investmentTooltip')
    },
    {
      id: DashboardMode.ESG_METRICS,
      name: t('dashboard.esg'),
      tooltip: t('dashboard.esgMetricsTooltip')
    }
  ];

  if (flags?.grouping) {
    toggleOptions.push({
      id: DashboardMode.GROUPING,
      name: t('dashboard.grouping'),
      tooltip: t('dashboard.groupingTooltip')
    });
  }

  // Show it only to Dcycle Demos
  const isLogisticCompany =
    selectedOrganization?.sector === 'transport' &&
    selectedOrganization?.id === 'cefc7985-41f5-42d1-8d49-536c1ab0977f';

  if (isLogisticCompany) {
    toggleOptions.push({
      id: DashboardMode.LOGISTICS,
      name: t('dashboard.logistic'),
      tooltip: ''
    });
  }

  useEffect(() => {
    setMode(DashboardMode.TOTAL);
  }, [selectedOrganization?.id]);

  const handleSwitchMode = (value: string) => {
    setMode(value as DashboardMode);
  };

  const handleGoToMetrics = () => {
    window.open(METRICS_ARTICLE[i18n.resolvedLanguage as keyof typeof METRICS_ARTICLE], '_blank');
  };

  return (
    <div className='dashboard-wrapper'>
      <section className='dashboard'>
        <div className='dashboard__header page-header'>
          <h3 className='headline3-font on-light-text-color'>{t('dashboard.title')}</h3>
          <h4 className='subtitle2-font on-light-text-color'>{t('dashboard.subtitle')}</h4>
        </div>
        <div className='dashboard-wrapper__mode flex'>
          <SlideToggleFacade
            handleSwitchMode={handleSwitchMode}
            idSelected={mode}
            options={toggleOptions}
          />
          <div
            style={{
              marginLeft: 'auto'
            }}>
            <HelpCTA description={t('dashboard.help')}>
              <div className='flex'>
                <Button
                  lookAndFeel='primary'
                  text={t('dashboard.helpButton')}
                  size='tiny'
                  onClick={handleGoToMetrics}
                />
              </div>
            </HelpCTA>
          </div>
        </div>
        <DashboardV2 mode={mode} />
      </section>
    </div>
  );
};
