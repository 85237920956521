import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import './styles.scss';
type Props = {
  reviewText: string;
  profileImage: string;
  profileName: string;
  profileCompany: string;
  mainImage: string;
};
const ReviewImage = ({
  reviewText,
  profileImage,
  profileName,
  profileCompany,
  mainImage
}: Props) => {
  const flags = useFeatureFlags();

  return (
    <div className='review-image review-image-bg-color'>
      {/* {!flags?.registerQuotes ? null : (
        <div className='review-image__profile'>
          <p className='span-font'>{reviewText}</p>
          <div className='review-image__profile__image-wrapper'>
            <img src={profileImage} alt='profile' />
            <div className='review-image__profile__image-wrapper__text'>
              <p className='span-font'>{profileName}</p>
              <p className='description-card-font on-light-text-color2'>{profileCompany}</p>
            </div>
          </div>
        </div>
        )} */}
      <img src={mainImage} alt='main' className='review-image__main-image' />
    </div>
  );
};

export default ReviewImage;
