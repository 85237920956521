import { adaptLCAFormToAPI } from '../../../adapters/lca';
import type { LCABlockBack } from '../../../components/lca/types/LCABlock';
import apiFetch from '../../../utils/apiFetch';

const URL = '/acv/portfolio';

type ILCAPostResponse = {
  acv_id: string;
  process: LCABlockBack;
  final_product: LCABlockBack;
};

export const createLCABackbone = async (data: ILCAFormType): Promise<ILCAPostResponse> => {
  const response = await apiFetch('POST', URL, adaptLCAFormToAPI(data));

  return response.data as ILCAPostResponse;
};
