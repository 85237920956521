import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteLCARequest } from '../service/deleteLCARequest';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';

export const useDeleteLCARequest = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { t } = useLCATranslation();

  const org = useSelectedOrganization();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteLCARequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lcaRequestStatus', org?.id] });

      dispatch(setNotification(t('requestLCA.deleted')));
    },
    onError: () => {
      dispatch(setNotification(t('requestLCA.error')));
    }
  });

  return {
    deleteLCARequest: mutateAsync,
    isDeleting: isPending
  };
};
