import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteVehicle } from '../../../../services/api/vehicle';
import { User } from '../../../../types/entities/user';
import Button from '../../../ui/button/Button';
import './styles.scss';

type Props = {
  removeVehicle: (id: string) => void;
  vehicleToDelete: string;
  user: User | null;
};
function DeleteVehicle({ user, vehicleToDelete, removeVehicle }: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteVehicle();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteVehicle = async () => {
    if (!user?.selectedOrganization) {
      return;
    }
    setLoadingButton(true);
    const deletedVehicle = await deleteVehicle(vehicleToDelete, user?.selectedOrganization);
    if (deletedVehicle) {
      removeVehicle(vehicleToDelete);
    }
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal-vehicles'>
      <h1 className='headline4-font'>{t('vehicles.deleteVehicle')}</h1>
      <h2 className='subtitle3-font '>{t('vehicles.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('vehicles.delete')}
          onClick={handleDeleteVehicle}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default DeleteVehicle;
