import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import { BOOK_MEETING } from '../../../constants';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div className='flex-col items-center justify-center px-12 gap-y-6 border-box'>{children}</div>
);

const ProTag: React.FC = () => (
  <div className='m-0 font-14 weight-400 bg-secondary text-white px-1 rounded-4 w-fit-content'>
    PRO
  </div>
);

type TitleProps = {
  children: React.ReactNode;
};
const Title: React.FC<TitleProps> = ({ children }) => (
  <h2 className='m-0 font-headings-h3-sb w-full'>{children}</h2>
);

const ImageContainer: React.FC = ({ children }) => (
  <div className='flex justify-center w-full' style={{ minWidth: '488px', marginLeft: '-8px' }}>
    {children}
  </div>
);

const ButtonContainer: React.FC = ({ children }) => (
  <div className='flex gap-x-3 w-full'>{children}</div>
);

const ScheduleButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Button
      lookAndFeel={'primary'}
      text={t('premiumFeatureAdvise.bookMeeting')}
      onClick={() => window.open(BOOK_MEETING, '_blank')}
      size='small'
    />
  );
};

const ReadMoreButton: React.FC<{ url: string }> = ({ url }) => {
  const { t } = useTranslation();

  return (
    <Button
      lookAndFeel={'secondary'}
      text={t('premiumFeatureAdvise.readMore')}
      onClick={() => window.open(url, '_blank')}
      size='small'
    />
  );
};

const ModalButtons = {
  Schedule: ScheduleButton,
  ReadMore: ReadMoreButton,
  Container: ButtonContainer
};

type BulletPointProps = {
  title: string;
  description: string;
};

const BulletPoint: React.FC<BulletPointProps> = ({ title, description }) => (
  <li className='font-body-b2-r m-0 max-w-60ch gradient:marker'>
    <span className='font-body-b2-sb text-gradient mr-1'>{`${title}:`}</span>
    <span>{description}</span>
  </li>
);

const BulletPointContainer: React.FC = ({ children }) => (
  <ul className='flex-col gap-y-2 w-full m-0'>{children}</ul>
);

export const PremiumFeatureModal = {
  Layout,
  ProTag,
  Title,
  ImageContainer,
  Button: ModalButtons,
  BulletPoint,
  BulletPointContainer
};
