import { useTranslation } from 'react-i18next';
import './styles.scss';
function Closing() {
  const { t } = useTranslation();
  return (
    <div className='ghg-closing-page'>
      <div className='title'>
        <img src='/images/report/logoBlack.svg' alt='logo' />
        <div className='divider-line' />
        <h1 className='name-icon-big-font '>{t('ghgReport.closingTitle')}</h1>
        <h1 className='percentage-big-font'>{t('ghgReport.closingTitle2')}</h1>
      </div>
    </div>
  );
}

export default Closing;
