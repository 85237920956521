import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { GroupDataMode } from '../../../types/entitiesEnums/report';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import { convertDateBackToFront } from '../../../utils/convertDates';
import Button from '../../ui/button/Button';
import SlideToggle2 from '../../ui/formComponents/slideToggle2/SlideToggle2';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import { Assumptions } from './assumptions/Assumptions';
import BusinessDescription from './businessDescription/BusinessDescription';
import BusinessTravel from './businessTravel/BusinessTravel';
import Closing from './closing/Closing';
import CommonSection from './common/commonSection/CommonSection';
import Layout from './common/layout/Layout';
import ElectricityEmissions from './electricityEmissions/ElectricityEmissions';
import EmissionFactors from './emissionFactors/EmissionFactors';
import Emissions from './emissions/Emissions';
import Employees from './employees/Employees';
import FrontPage from './frontPage/FrontPage';
import useGetData from './hooks/useGetData';
import Investments from './investments/Investments';
import Methodology from './methodology/Methodology';
import MobileEmissions from './mobileEmissions/MobileEmissions';
import Purchases from './purchases/Purchases';
import ReportIndex from './reportIndex/ReportIndex';
import StationaryEmissions from './stationaryEmissions/StationaryEmissions';
import './styles.scss';
import TransportDistribution from './transportDistribution/TransportDistribution';
import Wastes from './wastes/Wastes';

const GHGReport = () => {
  const { t } = useTranslation();

  const foundOrganization = useSelectedOrganization();
  const flags = useFeatureFlags();

  const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const paperSize = 'auto';

  const indexList = [
    {
      title: t('ghgReport.businessDescription'),
      image: '/images/icons/building.svg',
      id: '#business-description'
    },
    {
      title: t('ghgReport.methodologyScopePeriod'),
      image: '/images/icons/menu.svg',
      id: '#methodology-scope-period'
    },
    {
      title: t('ghgReport.emissions'),
      image: '/images/icons/cloud50.svg',
      id: '#emissions'
    },
    {
      title: t('ghgReport.offsets'),
      image: '/images/icons/leaf.svg',
      id: '#offsets'
    }
  ];

  const [loadingButton, setLoadingButton] = useState(false);
  const [groupDataMode, setGroupDataMode] = useState<GroupDataMode>(
    flags?.consolidation ? GroupDataMode.TOTAL : GroupDataMode.INVESTMENT
  );

  const {
    reportData,
    facilities,
    vehicles,
    dataElectricity,
    // dataEvolution,
    dataScopes,
    dataYearComparison,
    totalBiomass,
    organizationEfs,
    dataAccuracy,
    intensityMetric1,
    intensityMetric2,
    purchases,
    organizationCo2e,
    renewableEnergy,
    shipments,
    deliveries,
    businessTravels,
    employees,
    investments,
    investmentTotalImpacts,
    showTemporalComparative,
    assumptions,
    wastes,
    gwpTable,
    ipccVersion,
    loading
  } = useGetData({ groupDataMode });

  const startDate = reportData?.start_date
    ? convertDateBackToFront(reportData.start_date)
    : 'undefined'; // change

  const endDate = reportData?.end_date ? convertDateBackToFront(reportData?.end_date) : 'undefined'; // change

  const exportPDFWithMethod = () => {
    const element = container.current || document.body;

    // setLoadingButton(true);
    const fileName = t('ghgReport.downloadName', {
      groupDataMode:
        groupDataMode === GroupDataMode.TOTAL
          ? t('dashboard.consolidated')
          : t('dashboard.investment'),
      // companyName: org_name,
      startDate: reportData?.start_date,
      endDate: reportData?.end_date
    });
    savePDF(
      element,
      {
        paperSize: paperSize,
        margin: 0,
        fileName,
        forcePageBreak: '.page-break'
      },
      () => {
        // setLoadingButton(false);
      }
    );
  };

  const handleSwitchGroupMode = () => {
    if (groupDataMode === GroupDataMode.TOTAL) {
      setGroupDataMode(GroupDataMode.INVESTMENT);
    } else {
      setGroupDataMode(GroupDataMode.TOTAL);
    }
  };

  const assumptionsToUse: Category[] = [];

  const org_name = foundOrganization?.trade_name ?? '';

  // if (!foundOrganization || !startDate || !endDate || checkFreePlan(foundOrganization)) {
  //   return null;
  // }

  if (loading || !flags) {
    return (
      <div className='min-h-100svh grid-cols-1'>
        <div className='flex items-end justify-center'>
          <span>
            <LoaderTables mode='fit' />
          </span>
        </div>
        <Trans i18nKey='ghgReport.loading'>
          <p className='flex-col gap-y-2 text-center m-0 pt-8'>
            <strong>Estamos generando tu informe</strong>
            <div className='flex-col gap-y-1'>
              <span>Puede llevar unos minutos según el volumen de tus datos.</span>
              <span>Por favor, no cierres esta pantalla</span>
            </div>
          </p>
        </Trans>
      </div>
    );
  }

  if (
    !foundOrganization ||
    !flags ||
    !startDate ||
    !endDate ||
    !foundOrganization ||
    checkFreePlan(foundOrganization)
  ) {
    return null;
  }

  const sections = [
    <FrontPage startDate={startDate} endDate={endDate} key='front' groupDataMode={groupDataMode} />,
    <ReportIndex key='report-index' indexList={indexList} />,
    <CommonSection
      key='business-description'
      id='business-description'
      title={t('ghgReport.businessDescription')}
      description={t('ghgReport.carbonFootprintResults')}>
      <BusinessDescription
        approach={reportData?.approach}
        baseYear={reportData?.base_measure_year}
        baseyearChangeJustification={reportData?.text_base_measure_year}
        contactInfo={reportData?.contact_info}
      />
    </CommonSection>
  ];

  if (reportData?.other_limitation_organization) {
    sections.push(
      <CommonSection
        id='other-limitations'
        title={t('ghgReport.businessDescription')}
        description={t('ghgReport.carbonFootprintResults')}>
        <BusinessDescription.Item
          title={t('ghgReport.otherLimitations')}
          value={reportData.other_limitation_organization}
          image='signal'
        />
      </CommonSection>
    );
  }

  sections.push(
    <CommonSection
      key='methodology-scope-period'
      id='methodology-scope-period'
      title={t('ghgReport.methodologyScopePeriod')}
      description={t('ghgReport.analysisBetween')
        .replace('{{startDate}}', startDate)
        .replace('{{endDate}}', endDate)}>
      <Methodology
        verification={reportData?.verification}
        scopes={reportData?.scopes || []}
        ipccVersion={ipccVersion}
        gwpTable={gwpTable}
      />
    </CommonSection>,

    <CommonSection
      key='emissions'
      id='emissions'
      title={t('ghgReport.emissions')}
      description={t('ghgReport.analysisBetween')
        .replace('{{startDate}}', startDate)
        .replace('{{endDate}}', endDate)}>
      <Emissions
        dataScopes={dataScopes}
        dataYearComparison={dataYearComparison}
        totalBiomass={totalBiomass}
        startDate={startDate}
        endDate={endDate}
        dataAccuracy={dataAccuracy}
        intensityMetric1={intensityMetric1}
        intensityMetric2={intensityMetric2}
        scopesToShow={reportData?.scopes || []}
        showTemporalComparative={showTemporalComparative === 'true'}
      />
    </CommonSection>
  );

  if (reportData?.scopes?.includes(1)) {
    assumptionsToUse.push('heat', 'vehicle', 'recharge');
    sections.push(
      <CommonSection
        id=''
        title={t('ghgReport.stationaryEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <StationaryEmissions
          facilities={facilities}
          organizationCo2e={organizationCo2e}
          organization={foundOrganization}
        />
      </CommonSection>,
      <CommonSection
        id=''
        title={t('ghgReport.mobileEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <MobileEmissions
          vehicles={vehicles}
          organizationCo2e={organizationCo2e}
          organization={foundOrganization}
        />
      </CommonSection>
    );
  }

  if (reportData?.scopes?.includes(2)) {
    assumptionsToUse.push('electricity');
    sections.push(
      <CommonSection
        id='electricity-emissions'
        title={t('ghgReport.electricityEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <ElectricityEmissions
          dataElectricity={dataElectricity}
          startDate={startDate}
          endDate={endDate}
          organizationCo2e={organizationCo2e}
          organization={foundOrganization}
          renewableEnergy={renewableEnergy}
          facilities={facilities}
        />
      </CommonSection>
    );
  }

  if (reportData?.scopes?.includes(3)) {
    assumptionsToUse.push('water');
    sections.push(
      <CommonSection
        id=''
        title={t('ghgReport.purchasesEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <Purchases
          data={purchases}
          startDate={startDate}
          endDate={endDate}
          organizationCo2e={organizationCo2e}
          organization={foundOrganization}
        />
      </CommonSection>,
      <CommonSection
        id=''
        title={t('ghgReport.upstreamEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        {deliveries && (
          <TransportDistribution
            data={deliveries}
            startDate={startDate}
            endDate={endDate}
            organizationCo2e={organizationCo2e}
            organization={foundOrganization}
            i18key={'upstream'}
          />
        )}
      </CommonSection>,
      <CommonSection
        id=''
        title={t('ghgReport.businessTravelEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <BusinessTravel data={businessTravels} startDate={startDate} endDate={endDate} />
      </CommonSection>,
      <CommonSection
        id=''
        title={t('ghgReport.employeesEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <Employees data={employees} startDate={startDate} endDate={endDate} />
      </CommonSection>,
      <CommonSection
        id=''
        title={t('ghgReport.wasteEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        <Wastes
          data={wastes}
          organizationCo2e={organizationCo2e}
          startDate={startDate}
          endDate={endDate}
        />
      </CommonSection>,
      <CommonSection
        id=''
        title={t('ghgReport.downstreamEmissions')}
        description={t('ghgReport.analysisBetween')
          .replace('{{startDate}}', startDate)
          .replace('{{endDate}}', endDate)}>
        {shipments && (
          <TransportDistribution
            data={shipments}
            startDate={startDate}
            endDate={endDate}
            organizationCo2e={organizationCo2e}
            organization={foundOrganization}
            i18key={'downstream'}
          />
        )}
      </CommonSection>
    );
    if (groupDataMode === GroupDataMode.INVESTMENT) {
      sections.push(
        <CommonSection
          id=''
          title={t('ghgReport.investmentsEmissions')}
          description={t('ghgReport.analysisBetween')
            .replace('{{startDate}}', startDate)
            .replace('{{endDate}}', endDate)}>
          <Investments
            data={investments}
            dataByMonth={investmentTotalImpacts}
            startDate={startDate}
            endDate={endDate}
            organizationCo2e={organizationCo2e}
            organization={foundOrganization}
          />
        </CommonSection>
      );
    }
  }

  /* For every 12 organizationEfs elements render another EmissionFactors component */

  organizationEfs.forEach((ef, index) => {
    if (index % 10 === 0) {
      sections.push(
        <CommonSection
          id=''
          title={t('ghgReport.emissionFactors')}
          description={t('ghgReport.analysisBetween')
            .replace('{{startDate}}', startDate)
            .replace('{{endDate}}', endDate)}>
          <EmissionFactors emissionFactors={organizationEfs.slice(index, index + 10)} />
        </CommonSection>
      );
    }
  });

  if (Object.values(assumptions).some((assumptions) => assumptions.length > 0)) {
    const totalAssumptions = Object.values(assumptions).reduce((acc, curr) => acc + curr.length, 0);

    if (totalAssumptions <= 4) {
      sections.push(
        <CommonSection
          id=''
          title={t('ghgReport.assumptions')}
          description={t('ghgReport.assumptionsDescription')}>
          <>
            {Object.entries(assumptions).map(([category, assumptions]) => {
              if (!assumptionsToUse.includes(category as Category)) {
                return null;
              }

              return (
                <Assumptions
                  key={category}
                  assumptions={assumptions}
                  category={category as Category}
                />
              );
            })}
          </>
        </CommonSection>
      );
    } else {
      for (const [category, assumptionsData] of Object.entries(assumptions)) {
        if (assumptionsData.length > 0 && assumptionsToUse.includes(category as Category)) {
          assumptionsData.forEach((_, index) => {
            if (index % 4 === 0) {
              sections.push(
                <CommonSection
                  id=''
                  title={t('ghgReport.assumptions')}
                  description={t('ghgReport.assumptionsDescription')}>
                  <Assumptions
                    assumptions={assumptionsData.slice(index, index + 4)}
                    category={category as Category}
                  />
                </CommonSection>
              );
            }
          });
        }
      }
    }
  }

  if (reportData?.other_assumptions || reportData?.text_scopes) {
    sections.push(
      <CommonSection
        id=''
        title={t('ghgReport.assumptions')}
        description={t('ghgReport.assumptionsDescription')}>
        <span className='font-body-b1-sb'>{t('ghgReport.assumptionCategories.extra')}</span>
        <ul className='font-body-b2-r'>
          {reportData.other_assumptions && <li>{reportData.other_assumptions}</li>}
          {reportData.text_scopes && <li>{reportData.text_scopes}</li>}
        </ul>
      </CommonSection>
    );
  }

  sections.push(<Closing />);

  return (
    <div className='ghg-report' id='ghg-report'>
      <div className='buttons'>
        {flags.consolidation && (
          <SlideToggle2
            options={[
              {
                id: GroupDataMode.TOTAL,
                name: t('dashboard.consolidated'),
                tooltip: t('dashboard.consolidatedTooltip')
              },
              {
                id: GroupDataMode.INVESTMENT,
                name: t('dashboard.investment'),
                tooltip: t('dashboard.investmentTooltip')
              }
            ]}
            value={{ id: groupDataMode, name: groupDataMode }}
            setValue={handleSwitchGroupMode}
            tooltipPosition='left'
          />
        )}
        <Button
          lookAndFeel='primary'
          onClick={exportPDFWithMethod}
          text={t('ghgReport.download')}
          loading={loadingButton}
          size='small'
        />
      </div>

      <PDFExport
        ref={pdfExportComponent}
        paperSize={paperSize}
        margin={0}
        fileName={`GHG Report for ${startDate} to ${endDate}`}
        author='Dcycle'
        forcePageBreak='.page-break'>
        <div ref={container}>
          {sections.map((section, index) => {
            if (index === 0) {
              return (
                <Layout
                  backgroundImage='/images/report/ghgfrontpagebg.png'
                  logo_url={foundOrganization.logo_url}
                  org_name={org_name}
                  newPage={false}
                  key={`section-${index}`}
                  page={index + 1}
                  total={sections.length}>
                  {section}
                </Layout>
              );
            }
            if (index === sections.length - 1) {
              return (
                <Layout
                  key={`section-${index}`}
                  org_name={org_name}
                  backgroundImage='/images/report/ghgfrontpagebg.svg'
                  logo_url={foundOrganization.logo_url}
                  page={index + 1}
                  total={sections.length}>
                  {section}
                </Layout>
              );
            }
            return (
              <Layout
                key={`section-${index}`}
                org_name={org_name}
                logo_url={foundOrganization.logo_url}
                page={index + 1}
                total={sections.length}>
                {section}
              </Layout>
            );
          })}
        </div>
      </PDFExport>
    </div>
  );
};

export default GHGReport;
