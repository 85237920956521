export const datapoints: Datapoint[] = [
  {
    datapoint_id: 'G1.GOV-1_01',
    datapoint:
      'disclosure_of_role_of_administrative,_management_and_supervisory_bodies_related_to_business_conduct',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1.GOV-1_02',
    datapoint:
      'disclosure_of_expertise_of_administrative,_management_and_supervisory_bodies_on_business_conduct_matters',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_01',
    datapoint:
      'description_of_how_the_undertaking_establishes,_develops,_promotes_and_evaluates_its_corporate_culture',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_02',
    datapoint:
      'description_of_the_mechanisms_for_identifying,_reporting_and_investigating_concerns_about_unlawful_behaviour_or_behaviour_in_contradiction_of_its_code_of_conduct_or_similar_internal_rules',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_03',
    datapoint:
      'no_policies_on_anti-corruption_or_anti-bribery_consistent_with_united_nations_convention_against_corruption_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_04',
    datapoint:
      'timetable_for_implementation_of_policies_on_anti-corruption_or_anti-bribery_consistent_with_united_nations_convention_against_corruption',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_05',
    datapoint:
      'disclosure_of_safeguards_for_reporting_irregularities_including_whistleblowing_protection',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_06',
    datapoint: 'no_policies_on_protection_of_whistle-blowers_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_07',
    datapoint: 'timetable_for_implementation_of_policies_on_protection_of_whistle-blowers',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_08',
    datapoint:
      'undertaking_is_committed_to_investigate_business_conduct_incidents_promptly,_independently_and_objectively',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_09',
    datapoint: 'policies_with_respect_to_animal_welfare_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_10',
    datapoint: 'information_about_policy_for_training_within_organisation_on_business_conduct',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_11',
    datapoint:
      'disclosure_of_the_functions_that_are_most_at_risk_in_respect_of_corruption_and_bribery',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-1_12',
    datapoint:
      'entity_is_subject_to_legal_requirements_with_regard_to_protection_of_whistleblowers',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-2_01',
    datapoint: 'description_of_policy_to_prevent_late_payments,_especially_to_smes',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-2_02',
    datapoint:
      'description_of_approaches_in_regard_to_relationships_with_suppliers,_taking_account_risks_related_to_supply_chain_and_impacts_on_sustainability_matters',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-2_03',
    datapoint:
      'disclosure_of_whether_and_how_social_and_environmental_criteria_are_taken_into_account_for_selection_of_supply-side_contractual_partners',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_01',
    datapoint:
      'information_about_procedures_in_place_to_prevent,_detect,_and_address_allegations_or_incidents_of_corruption_or_bribery',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_02',
    datapoint:
      'investigators_or_investigating_committee_are_separate_from_chain_of_management_involved_in_prevention_and_detection_of_corruption_or_bribery',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_03',
    datapoint:
      'information_about_process_to_report_outcomes_to_administrative,_management_and_supervisory_bodies',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_04',
    datapoint:
      'disclosure_of_plans_to_adopt_procedures_to_prevent,_detect,_and_address_allegations_or_incidents_of_corruption_or_bribery_in_case_of_no_procedure',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_05',
    datapoint:
      'information_about_how_policies_are_communicated_to_those_for_whom_they_are_relevant_(prevention_and_detection_of_corruption_or_bribery)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_06',
    datapoint:
      'information_about_nature,_scope_and_depth_of_anti-corruption_or_anti-bribery_training_programmes_offered_or_required',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_07',
    datapoint: 'percentage_of_functions-at-risk_covered_by_training_programmes',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-3_08',
    datapoint:
      'information_about_members_of_administrative,_supervisory_and_management_bodies_relating_to_anti-corruption_or_anti-bribery_training',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-3_09',
    datapoint:
      'disclosure_of_an_analysis_of_its_training_activities_by,_for_example,_region_of_training_or_category',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-4_01',
    datapoint: 'number_of_convictions_for_violation_of_anti-corruption_and_anti-_bribery_laws',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-4_02',
    datapoint: 'amount_of_fines_for_violation_of_anti-corruption_and_anti-_bribery_laws',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-3_03',
    datapoint:
      'prevention_and_detection_of_corruption_or_bribery_-_anti-corruption_and_bribery_training_table',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-4_04',
    datapoint: 'number_of_confirmed_incidents_of_corruption_or_bribery',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-4_05',
    datapoint: 'information_about_nature_of_confirmed_incidents_of_corruption_or_bribery',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-4_06',
    datapoint:
      'number_of_confirmed_incidents_in_which_own_workers_were_dismissed_or_disciplined_for_corruption_or_bribery-related_incidents',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-4_07',
    datapoint:
      'number_of_confirmed_incidents_relating_to_contracts_with_business_partners_that_were_terminated_or_not_renewed_due_to_violations_related_to_corruption_or_bribery',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-4_08',
    datapoint:
      'information_about_details_of_public_legal_cases_regarding_corruption_or_bribery_brought_against_undertaking_and_own_workers_and_about_outcomes_of_such_cases',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_01',
    datapoint:
      'information_about_representative(s)_responsible_in_administrative,_management_and_supervisory_bodies_for_oversight_of_political_influence_and_lobbying_activities',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_02',
    datapoint: 'information_about_financial_or_in-kind_political_contributions',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_03',
    datapoint: 'financial_political_contributions_made',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-5_04',
    datapoint: 'amount_of_internal_and_external_lobbying_expenses',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-5_05',
    datapoint: 'amount_paid_for_membership_to_lobbying_associations',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-5_06',
    datapoint: 'in-kind_political_contributions_made',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-5_07',
    datapoint: 'disclosure_of_how_monetary_value_of_in-kind_contributions_is_estimated',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_08',
    datapoint: 'financial_and_in-kind_political_contributions_made_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-5_09',
    datapoint:
      "disclosure_of_main_topics_covered_by_lobbying_activities_and_undertaking's_main_positions_on_these_topics",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_10',
    datapoint:
      'undertaking_is_registered_in_eu_transparency_register_or_in_equivalent_transparency_register_in_member_state',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_11',
    datapoint:
      'information_about_appointment_of_any_members_of_administrative,_management_and_supervisory_bodies_who_held_comparable_position_in_public_administration_in_two_years_preceding_such_appointment',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-5_12',
    datapoint:
      'the_entity_is_legally_obliged_to_be_a_member_of_a_chamber_of_commerce_or_other_organisation_that_represents_its_interests',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-6_01',
    datapoint:
      'average_number_of_days_to_pay_invoice_from_date_when_contractual_or_statutory_term_of_payment_starts_to_be_calculated',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-6_02',
    datapoint:
      'description_of_undertakings_standard_payment_terms_in_number_of_days_by_main_category_of_suppliers',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'G1-6_03',
    datapoint: 'percentage_of_payments_aligned_with_standard_payment_terms',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-6_04',
    datapoint: 'number_of_outstanding_legal_proceedings_for_late_payments',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'G1-6_05',
    datapoint: 'disclosure_of_contextual_information_regarding_payment_practices',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  }
];
