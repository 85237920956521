import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTaskCategories from '../../../../customHooks/useCategories';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { deleteAttachmentFromTask } from '../../../../services/api/tasks';
import { IFrontendFormTask, TaskComment } from '../../../../types/entities/task';

import { convertDateToString } from '../../../../utils/convertDates';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileText from '../../../ui/formComponents/inputFile/InputFileText';
import InputTextArea from '../../../ui/formComponents/inputTextArea/InputTextArea';
import FormCalendar from '../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormTextSelect from '../../../ui/formComponents2/formInputs/formTextSelect/FormTextSelect';
import Modal from '../../../ui/modal/Modal';
import Comment from './components/comment/Comment';
import WriteComment from './components/comment/WriteComment';
import FileAttached from './components/fileAttached/FileAttached';
import TagAdded from './components/tagAdded/TagAdded';
import './styles.scss';

type Props = {
  formData: IFrontendFormTask;
  setFormData: (value: React.SetStateAction<IFrontendFormTask>) => void;
  handleSubmit: () => void;
  users: SelectOptionFormat[];
  taskId?: string;
  comments?: TaskComment[];
  tags: SelectOptionFormat[];
};

const Form = ({ formData, setFormData, handleSubmit, users, taskId, comments, tags }: Props) => {
  const { t } = useTranslation();

  const categories = useTaskCategories();
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const [attachmentToDelete, setAttachmentToDelete] = useState<string>('');

  const [commentsTask, setCommentsTask] = useState<TaskComment[]>(comments ?? []);

  const [loadingDeleteAttachment, setLoadingDeleteAttachment] = useState(false);

  useEffect(() => {
    if (comments) setCommentsTask(comments);
  }, [JSON.stringify(comments)]);

  const dateInAYear = new Date();
  dateInAYear.setDate(dateInAYear.getDate() + 365);

  const handleAdd = () => {
    if (formData.tag === '' || formData.tagsSelected.includes(formData.tag)) return;
    setFormData((prev) => ({
      ...prev,
      tag: '',
      tagsSelected: [...prev.tagsSelected, prev.tag]
    }));
  };

  const handleRemove = (id: string) => {
    setFormData((prev) => ({
      ...prev,
      tagsSelected: prev.tagsSelected.filter((elem) => elem !== id)
    }));
  };

  const handleUploadFiles = (files: File[]) => {
    const filesParsed = files.map((elem) => ({
      id: Math.random().toString(),
      name: elem.name,
      url: '',
      file: elem
    }));
    setFormData((prev) => ({
      ...prev,
      files: [...prev.files, ...filesParsed]
    }));
  };

  const handleRemoveFile = async (id: string) => {
    const foundAttachment = formData.files.find((elem) => elem.id === id);
    if (foundAttachment?.url) {
      setAttachmentToDelete(foundAttachment.id);
    } else {
      setFormData((prev) => ({
        ...prev,
        files: prev.files.filter((elem) => elem.id !== id)
      }));
    }
  };

  const deleteAttachment = async () => {
    if (!taskId) return;
    setLoadingDeleteAttachment(true);
    await deleteAttachmentFromTask(taskId, attachmentToDelete);
    setLoadingDeleteAttachment(false);
    setAttachmentToDelete('');
    setFormData((prev) => ({
      ...prev,
      files: prev.files.filter((elem) => elem.id !== attachmentToDelete)
    }));
  };

  const addComment = (comment: TaskComment) => {
    setCommentsTask((prev) => [comment, ...prev]);
  };

  const removeComment = (id: string) => {
    setCommentsTask((prev) => prev.filter((elem) => elem.id !== id));
  };

  const onSubmit = async () => {
    setLoadingButton(true);
    await handleSubmit();
    setLoadingButton(false);
  };

  const initialTags = [...tags];

  // Remove tags already selected
  formData.tagsSelected.forEach((elem) => {
    const found = initialTags.findIndex((tag) => tag.id === elem);
    if (found !== -1) initialTags.splice(found, 1);
  });

  return (
    <div className='form-tasks'>
      <FormWrapper>
        <FormSelect
          icon='/images/icons/person.svg'
          placeholder={t('tasks.selectUser')}
          label={t('tasks.assignResponsible')}
          options={users}
          value={formData.userSelected}
          onChange={onChangeValue('userSelected')}
          error={formData.errors.find((elem) => elem.error === 'userSelected')}
        />
        <FormSelect
          icon='/images/icons/catalog.svg'
          placeholder={t('tasks.selectCategory')}
          label={t('tasks.category')}
          options={categories}
          value={formData.category}
          onChange={onChangeValue('category')}
          error={formData.errors.find((elem) => elem.error === 'category')}
        />
        <FormCalendar
          handleChangeDate={onChangeValue('dueDate')}
          dateValue={formData.dueDate}
          label={t('tasks.dueDate')}
          maxDate={convertDateToString(dateInAYear)}
          minDate={convertDateToString(new Date())}
          error={formData.errors.find((elem) => elem.error === 'dueDate')}
        />
        <FormElementFull>
          <InputTextArea
            value={formData.description}
            onChangeValue={onChangeValue('description')}
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {t('tasks.description')}
                <InputFileText handleFiles={handleUploadFiles} fileNames={[]} allowedTypes={[]}>
                  <span className='highlight-text-color pointer'>
                    {t('tasks.uploadYourFilesHere')}
                  </span>
                </InputFileText>
              </div>
            }
            maxLength={500}
          />
        </FormElementFull>
        <div className='files-attached'>
          {formData.files.map((elem) => (
            <FileAttached key={elem.id} file={elem} handleRemove={handleRemoveFile} />
          ))}
        </div>
        <FormElementFull className='add-element-input'>
          <div className='form-select-button'>
            <FormTextSelect
              icon='/images/icons/folder.svg'
              placeholder={t('tasks.writeTag')}
              label={t('tasks.tag')}
              value={formData.tag}
              onChange={onChangeValue('tag')}
              options={initialTags}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleAdd();
              }}
            />
            <Button lookAndFeel='primary' text={t('tasks.add')} onClick={handleAdd} size='small' />
          </div>
        </FormElementFull>
        <FormElementFull>
          <div className='form-selected-elements'>
            {formData.tagsSelected.map((elem) => (
              <TagAdded id={elem} name={elem} removeElement={handleRemove} key={elem} />
            ))}
          </div>
        </FormElementFull>
        {/* <div className='disclaimer pointer' onClick={() => window.open('https://dcycle.io')}>
          <Label lookAndFeel='secondary'>
            <img src='/images/icons/diamond2.svg' />
            <span className='subtitle3-font'>{replaceStrongTags(t('tasks.disclaimer'))}</span>
          </Label>
        </div> */}
        {taskId && (
          <FormElementFull>
            <p className='body1-bold-font' style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              {t('tasks.leaveAComment')}
            </p>
            <div className='comments'>
              <WriteComment taskId={taskId} addComment={addComment} />
              {commentsTask?.map((elem) => (
                <Comment key={elem.id} comment={elem} removeComment={removeComment} />
              ))}
            </div>
          </FormElementFull>
        )}
      </FormWrapper>

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('tasks.save')}
          onClick={onSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      <Modal show={!!attachmentToDelete} onClose={() => setAttachmentToDelete('')}>
        <div className='delete-attachment'>
          <FormHeader title={t('tasks.deleteAttachment')} />
          <FormButtonSection>
            <Button
              lookAndFeel={'primary'}
              text={t('tasks.delete')}
              onClick={deleteAttachment}
              loading={loadingDeleteAttachment}
            />
          </FormButtonSection>
        </div>
      </Modal>
    </div>
  );
};

export default Form;
