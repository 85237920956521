import { useInfiniteQuery } from '@tanstack/react-query';
import { getPortfolio } from '../service/getLCAPortfolio';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { PAGE_SIZE } from '../constants';
import { IFilter } from '../../../../utils/url';

type Params = {
  filters: IFilter[];
  old_flow?: boolean;
};

export const useLCAPortfolio = ({ filters, old_flow }: Params) => {
  const org_id = useSelectedOrganization()?.id;

  const { data, fetchNextPage, isLoading, hasNextPage } = useInfiniteQuery({
    queryKey: ['lcaPortfolio', org_id, JSON.stringify(filters)],
    queryFn: ({ pageParam }) => getPortfolio(pageParam, filters, old_flow),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * PAGE_SIZE >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1
  });

  const dataParsed = data?.pages.reduce(
    (acc, page) => {
      return {
        page: page.page,
        total: page.total,
        items: [...acc.items, ...page.items]
      };
    },
    {
      page: 1,
      total: 0,
      items: []
    }
  );

  return { data: dataParsed, isLoading, fetchNextPage, hasNextPage };
};
