import { useQuery } from '@tanstack/react-query';
import { getLCARequestStatus } from '../service/getLCARequestStatus';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';

export const useLCARequestStatus = () => {
  const org = useSelectedOrganization();

  const { data, isFetching } = useQuery({
    queryKey: ['lcaRequestStatus', org?.id],
    queryFn: async () => await getLCARequestStatus(org?.id)
  });

  return { data, isLoading: isFetching };
};
