export const joinDataEmployee = (data: CategoryData[]) => {
  const newData = [...data];

  let employeesTotalEmissions = 0;

  newData.forEach((element) => {
    if (element.category !== 'employees_in_itinere' && element.category !== 'employees_in_labore')
      return;

    employeesTotalEmissions += element.total_emissions;
  });

  return newData
    .concat({
      category: 'employees',
      scope: 3,
      total_emissions: employeesTotalEmissions
    })
    .filter(
      (element) =>
        element.category !== 'employees_in_itinere' && element.category !== 'employees_in_labore'
    );
};
