import { useTranslation } from 'react-i18next';
import Tooltip from '../tooltip/Tooltip';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import CardContent from './CardContent';
import './styles.scss';

type Props = {
  tooltipPosition: 'top' | 'right' | 'left';
  card: CardTooltip;
};
function Card({ tooltipPosition, card }: Props) {
  const { t } = useTranslation();

  return (
    <div
      id={card.id}
      className={`card ${
        card.disabled ? 'card-disabled-bg-color' : 'main-bg-color'
      } card-border-color`}>
      <div className='card__tags corner-position'>
        {card.group.map((group) => (
          <TooltipWrapper text={t(`scopes.scope${group}`)} position='top' key={group}>
            <div className='text-tag body3-font tag-bg-text-color '>
              {typeof group === 'string'
                ? t(`measureMain.${group}`)
                : `${t('measureMain.scope')} ${group}`}
            </div>
          </TooltipWrapper>
        ))}
      </div>
      <Tooltip text={card.tooltip} position={tooltipPosition} />
      {(card.disabled || card.soon) && (
        <div className='coming-soon-tag subtitle3-font tag2-bg-text-color'>
          <img src='/images/icons/eyeWhite.svg' alt='eye' />
          {card.soon ?? t('measureMain.comingSoon')}
        </div>
      )}
      <div className='card__content-wrapper'>
        {card.data.map((elem, index) => {
          return (
            <>
              {index === 1 && <div className='divider border-color' key={index} />}
              <CardContent data={elem} card={card} />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Card;
