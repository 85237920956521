import { useContext } from 'react';
import { FilterDatesContext } from '../../../context/filterDatesContext';

import { localStorageSetItem } from '../../../utils/localStorage';
import InputCalendar from '../../ui/formComponents/inputCalendar/InputCalendar';
import UserButton from './profile/UserButton';
import './styles.scss';
import SwitchOrganization from './switchOrganization/SwitchOrganization';
import TasksButton from './tasks/TasksButton';
import { HeadwayBadge } from '../../ui/headwayBadge/HeadwayBadge';
import Button from '../../ui/button/Button';
import Icon from '../../ui/icon/Icon';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import NotificationsButton from './notifications/NotificationsButton';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';

type Props = {
  showFilterDates: boolean;
};

function Header({ showFilterDates }: Props) {
  const { t } = useTranslation();

  const selectedOrganization = useSelectedOrganization();

  const { startDate, endDate, setStartDate, setEndDate, setDateError } =
    useContext(FilterDatesContext);

  const flags = useFeatureFlags();

  const handleChangeStartDate = (value: string) => {
    setStartDate(value);
    // change start_date_dashboard session_storage property
    localStorageSetItem('start_date_dashboard', value);
  };

  const handleChangeEndDate = (value: string) => {
    setEndDate(value);
    // change end_date_dashboard session_storage property
    localStorageSetItem('end_date_dashboard', value);
  };

  const handleDateError = (error?: ErrorType) => setDateError(error);
  const endDateOfCurrentYear = new Date(new Date().getFullYear(), 11, 31);

  return (
    <header>
      <div id='date' className={`header-dates ${showFilterDates ? '' : 'hide'}`}>
        <InputCalendar
          mode='range'
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          startDateValue={startDate}
          endDateValue={endDate}
          maxStartDate={new Date()}
          maxEndDate={endDateOfCurrentYear}
          predefinedDates
          height='30px'
          fontClass='body1-font'
          handleDateError={handleDateError}
        />
      </div>
      <div className='header-options'>
        <SwitchOrganization />
        {!(process.env.REACT_APP_PARTNER === 'onau') && (
          <HeadwayBadge>
            <Button
              lookAndFeel='tertiary'
              size='small'
              iconNode={<Icon icon='rocket' color='blocked' />}>
              {t('general.headway')}
            </Button>
          </HeadwayBadge>
        )}
        {selectedOrganization?.role !== 'fund' && !flags?.notifications && <TasksButton />}
        {selectedOrganization?.role !== 'fund' && flags?.notifications && <NotificationsButton />}
        {/* <InviteButton /> */}
        <UserButton />
      </div>
    </header>
  );
}

export default Header;
