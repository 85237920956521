import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/userContext';
import numberToDecimal from '../../../utils/numberToDecimal';
import Button from '../../ui/button/Button';
import IconBackground from '../../ui/iconBackground/IconBackground';
import BudgetInput from './budgetInput/BudgetInput';
import './styles.scss';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { sendEmails } from '../../../services/api/emails';
import { createSlackMessage } from '../utils';
import { sendSlackMessage } from '../../../services/api/slack';

function BudgetCalculator() {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const foundOrganization = useSelectedOrganization();

  const [facilities, setFacilities] = useState('');
  const [employees, setEmployees] = useState('');
  const [vehicles, setVehicles] = useState('');

  const sendNotification = async () => {
    if (!user?.email || !foundOrganization) return;
    if (user.email.includes('@dcycle.io')) return;
    if (foundOrganization?.subscription_plan !== 'free_plan') return;
    const subject = `El ususario ${user?.email} de la organización ${foundOrganization.company_name} ha accedido a la calculadora de presupuesto`;

    await sendEmails(subject, undefined, undefined, [
      'heloisa.marcineiro@dcycle.io',
      'luciano@dcycle.io',
      'jamie.woolley@dcycle.io',
      'carlos.pumar@dcycle.io'
    ]);
    const message = createSlackMessage(user?.email, foundOrganization.company_name);
    await sendSlackMessage(
      message,
      'https://hooks.slack.com/services/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'
    );
  };

  // send email if it is free trial
  useEffect(() => {
    sendNotification();
  }, []);

  const onChangeFacilities = (value: string) => {
    const max = 10000;
    if (value === '' || (!isNaN(Number(value)) && Number(value) <= max)) {
      setFacilities(value);
    }
  };

  const onChangeEmployees = (value: string) => {
    const max = 10000;
    if (value === '' || (!isNaN(Number(value)) && Number(value) <= max)) {
      setEmployees(value);
    }
  };

  const onChangeVehicles = (value: string) => {
    const max = 10000;
    if (value === '' || (!isNaN(Number(value)) && Number(value) <= max)) {
      setVehicles(value);
    }
  };

  const renderCharacteristic = (characteristic: string) => {
    return (
      <div className='characteristic'>
        <img src='/images/icons/tickGradientCircle.svg' alt='tick' />
        <span className='subtitle3-font'>{t(`pricing.characteristics.${characteristic}`)}</span>
      </div>
    );
  };

  const calculatePrice = () => {
    const facilitiesNumber = Number(facilities);
    const employeesNumber = Number(employees);
    const vehiclesNumber = Number(vehicles);

    let price = 0;
    const maxUnitPrice = 600;
    const minUnitPrice = 100;
    let i = 1;
    while (i <= facilitiesNumber && i <= 36) {
      const unitPrice = 0.95 ** (i - 1) * maxUnitPrice;
      price += unitPrice;
      i += 1;
    }
    price += employeesNumber * 3;
    price += vehiclesNumber * 10;
    if (facilitiesNumber > 36) {
      price += (facilitiesNumber - 36) * minUnitPrice;
    }
    return numberToDecimal(price);
  };

  const handleRedirect = () => {
    window.open('https://headwayapp.co/dcycle-changelog', '_blank');
  };

  const clientLogos = ['scalpers', 'habla', 'vivo', 'pozzi', 'mirto'];

  const totalPrice = calculatePrice();
  const monthlyPrice = numberToDecimal(totalPrice / 12);

  if (!foundOrganization || !user) return null;

  return (
    <section className='pricing'>
      <div className='pricing__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('pricing.title')}</h3>
      </div>
      <div className='pricing__body'>
        <div className='pricing__body__content'>
          <h1 className='headline2-font'>{t('pricing.contentTitle')}</h1>
          <p className='subtitle1-font '>{t('pricing.contentDescription')}</p>

          <span className='headline4-font'>{t('pricing.includes')}</span>
          <div className='pricing__body__content__characteristics'>
            {Object.keys(t('pricing.characteristics', { returnObjects: true })).map(
              (characteristic: string) => renderCharacteristic(characteristic)
            )}
          </div>
        </div>
        <div className='card'>
          <IconBackground icon='/images/icons/leaf.svg' alt='leaf' />
          <h4 className='headline4-font'>{t('pricing.calculateYourPlan')}</h4>
          <p className='subtitle3-font'>{t('pricing.discoverWhatMoreYouCanDoAndKeepAdvancing')}</p>
          <div className='card-form'>
            <BudgetInput
              icon={'/images/icons/thunder50.svg'}
              label={t('pricing.facilities')}
              value={facilities}
              onChange={onChangeFacilities}
            />
            <BudgetInput
              icon={'/images/icons/carBigger.svg'}
              label={t('pricing.vehicles')}
              value={vehicles}
              onChange={onChangeVehicles}
            />
            <BudgetInput
              icon={'/images/icons/people.svg'}
              label={t('pricing.employees')}
              value={employees}
              onChange={onChangeEmployees}
            />
          </div>
          <div className='price-container'>
            <span className='headline2-font highlight-text-color'>{`${monthlyPrice}€ ${t(
              'pricing.monthly'
            )}`}</span>
          </div>
          <span className='subtitle3-font on-light-text-color2'>
            {t('pricing.annualPayment')} -{` ${totalPrice}€`}
          </span>
          <Button
            lookAndFeel={'primary'}
            text={t('pricing.improvePlan')}
            onClick={handleRedirect}
          />
        </div>
      </div>
      <div className='pricing__footer'>
        <span className='span-font on-light-text-color2'>{t('pricing.footerTitle')}</span>
        {clientLogos.map((clientLogo: string) => (
          <img
            src={`/images/businessClients/${clientLogo}.png`}
            alt={clientLogo}
            key={clientLogo}
          />
        ))}
      </div>
    </section>
  );
}

export default BudgetCalculator;
