import apiFetch from '../../../../utils/apiFetch';
import { IFilter, generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import { PAGE_SIZE } from '../constants';
import { LCAPortfolioResponse } from '../types/LCAPortfolio';

const OLD_URL = '/acv/portfolio';
const URL = '/lca/portfolio';

/* const MOCK_DATA: LCAPortfolioResponse[] = Array.from({ length: 10 }).map((_, page) => ({
  page: page + 1,
  total: PAGE_SIZE * 10,
  items: Array.from({ length: PAGE_SIZE }).map((_, index) => ({
    id: (index + 1 + page * PAGE_SIZE).toString(),
    name: `Item ${index + 1 + page * PAGE_SIZE}`,
    description: `Description ${index + 1 + page * PAGE_SIZE}`
  }))
})); */

export const getPortfolio = async (
  page: number,
  filters: IFilter[],
  old_flow?: boolean
): Promise<LCAPortfolioResponse> => {
  const url = old_flow ? OLD_URL : URL;

  const response = await apiFetch(
    'GET',
    getUrl(url, {
      queryParams: generateQueryParamsFromObject({
        page,
        size: PAGE_SIZE
      }),
      filters
    })
  );

  return response.data;
};
