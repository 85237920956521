import useMockedData from '../../../../measure/esgMetrics/hooks/useMockedData';

const useData = () => {
  const {
    environmentalCompleted,
    environmentalDatapoints,
    environmentalCompleteness,
    socialCompleted,
    socialDatapoints,
    socialCompleteness,
    governanceCompleted,
    governanceDatapoints,
    governanceCompleteness
  } = useMockedData();

  const dataMock1 = [{ title: 'One', value: environmentalCompleteness, color: 'url(#gradient1)' }];

  const dataMock2 = [{ title: 'One', value: socialCompleteness, color: 'url(#gradient1)' }];

  const dataMock3 = [{ title: 'One', value: governanceCompleteness, color: 'url(#gradient1)' }];

  const allDataMocked = [dataMock1, dataMock2, dataMock3];

  const dataMetricsMocked = [
    {
      name: 'environmentalMetrics',
      progress: `${environmentalCompleted}/${environmentalDatapoints} datapoints`
    },
    {
      name: 'socialMetrics',
      progress: `${socialCompleted}/${socialDatapoints} datapoints`
    },
    {
      name: 'governanceMetrics',
      progress: `${governanceCompleted}/${governanceDatapoints} datapoints`
    }
  ];

  const mockedProgress = [
    `${environmentalCompleteness}%`,
    `${socialCompleteness}%`,
    `${governanceCompleteness}%`
  ];

  return {
    allDataMocked,
    dataMetricsMocked,
    mockedProgress
  };
};

export default useData;
