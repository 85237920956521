import { createContext, useContext, useState } from 'react';
import { schema } from './schema';
import { adaptZodErrors } from '../../../../../../adapters/zodErrors';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';

type ErrorContainer = { [K in keyof ILCAFormType]?: ErrorType };

type FormContextType = {
  form: ILCAFormType;
  setForm: React.Dispatch<React.SetStateAction<ILCAFormType>>;
  errors: ErrorContainer | null;
  setErrors: React.Dispatch<React.SetStateAction<ErrorContainer | null>>;
};
const FormContext = createContext<FormContextType | null>(null);

export const useForm = () => {
  const context = useContext(FormContext);

  const { t } = useLCATranslation();

  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }

  const validate = (section: keyof typeof schema) => {
    const validationResult = schema[section].safeParse(context.form);

    if (validationResult.success) {
      context.setErrors(null);
    } else {
      context.setErrors(adaptZodErrors(validationResult.error, t));
    }

    return validationResult.success;
  };

  const partialValidate = (
    section: keyof typeof schema,
    field: keyof ILCAFormType,
    newValue: ILCAFormType
  ) => {
    const validationResult = schema[section].pick({ [field]: true }).safeParse(newValue);

    if (validationResult.success) {
      context.setErrors((prev) => {
        if (prev) {
          const newErrors = { ...prev };
          delete newErrors[field];
          return newErrors;
        }

        return null;
      });
    } else {
      context.setErrors((prev) => ({
        ...prev,
        ...adaptZodErrors(validationResult.error, t)
      }));
    }

    return validationResult.success;
  };

  return {
    form: context.form,
    setForm: context.setForm,
    errors: context.errors,
    validate,
    partialValidate
  };
};

export const FormProvider: React.FC = ({ children }) => {
  const [form, setForm] = useState<ILCAFormType>({
    name: '',
    startDate: '',
    endDate: '',
    value: '',
    unit: {
      id: '',
      name: ''
    },
    impactCategories: []
  });

  const [error, setError] = useState<ErrorContainer | null>(null);

  return (
    <FormContext.Provider value={{ form, setForm, errors: error, setErrors: setError }}>
      {children}
    </FormContext.Provider>
  );
};

export const withFormProvider = <T extends object>(Component: React.FC<T>) => {
  const ComponentWithFormProvider = (props: T) => (
    <FormProvider>
      <Component {...props} />
    </FormProvider>
  );

  return ComponentWithFormProvider;
};
