import React from 'react';
import IconBackground from '../../../../ui/iconBackground/IconBackground';
import './styles.scss';
import { numberToDecimalNonZero } from '../../../../../utils/numberToDecimal';
type Props = {
  icon: string;
  title: string;
  value: number;
  unit: string;
};
const Co2Info = ({ icon, title, value, unit }: Props) => {
  return (
    <div className='co2-info-emissions'>
      <IconBackground icon={icon} alt={title} />
      <div className='co2-info-emissions__body'>
        <span className='body1-bold-font'>{title}</span>
        <div className='value-wrapper'>
          <span className='body1-font'>{numberToDecimalNonZero(value, 1)}</span>
          <span className='body1-font'>{unit}</span>
        </div>
      </div>
    </div>
  );
};

export default Co2Info;
