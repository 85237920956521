import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { UserContext } from '../../../context/userContext';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { getMatrix } from '../../../services/api/matrices';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../styles/colors';
import { Holding } from '../../../types/entities/holdings';
import apiFetch from '../../../utils/apiFetch';
import { convertStringToDate } from '../../../utils/convertDates';
import numberToDecimal from '../../../utils/numberToDecimal';
import Button from '../../ui/button/Button';
import SlideToggle2 from '../../ui/formComponents/slideToggle2/SlideToggle2';
import IconBackground from '../../ui/iconBackground/IconBackground';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import ScopeTag from '../../ui/scopeTag/ScopeTag';
import Tooltip from '../../ui/tooltip/Tooltip';
import OffsetCard from './components/offsetCard/OffsetCard';
import Tasks from './components/tasks/Tasks';
import './styles.scss';

function ImproveMainPage() {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user?.selectedOrganization
  );
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const navigate = useNavigate();

  const flags = useFeatureFlags();

  const [dataScopes, setDataScopes] = useState<ScopeData[]>([]);
  const [loading, setLoading] = useState(true);
  const [groupDataMode, setGroupDataMode] = useState<'total' | 'investment'>(
    flags?.consolidation ? 'total' : 'investment'
  );

  let pieChartData = [{ title: '', value: 100, color: gray_color2 }];
  let totalCo2 = 0;
  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  useEffect(() => {
    const fetchData = async () => {
      if (dateError) return;
      setLoading(true);
      let dataHolding: Holding | null = null;
      let scopesData: ScopeData[] = [];
      if (
        user?.selectedOrganization &&
        user.selectedOrganization &&
        user.selectedOrganization === user.selectedOrganization &&
        !foundOrganization?.is_group_fund
      ) {
        dataHolding = await getMatrix(
          user?.selectedOrganization ?? '',
          convertStringToDate(startDate),
          convertStringToDate(endDate),
          groupDataMode
        );

        const groupByScope = [
          { scope: 1, total_emissions: 0 },
          { scope: 2, total_emissions: 0 },
          { scope: 3, total_emissions: 0 }
        ];

        dataHolding?.childs_info.forEach((child) => {
          child.co2e.forEach((elem) => {
            if (groupDataMode === 'total') {
              if (elem.scope === 1) {
                groupByScope[0].total_emissions += elem.total_emissions;
              }
              if (elem.scope === 2) {
                groupByScope[1].total_emissions += elem.total_emissions;
              }
              if (elem.scope === 3) {
                groupByScope[2].total_emissions += elem.total_emissions;
              }
            } else {
              groupByScope[2].total_emissions += elem.total_emissions;
            }
          });
        });
        dataHolding?.parent_co2e?.forEach((elem) => {
          if (elem.scope === 1) {
            groupByScope[0].total_emissions += elem.total_emissions;
          }
          if (elem.scope === 2) {
            groupByScope[1].total_emissions += elem.total_emissions;
          }
          if (elem.scope === 3) {
            groupByScope[2].total_emissions += elem.total_emissions;
          }
        });
        setDataScopes(groupByScope);
      } else {
        const response = await apiFetch('post', '/total_impacts/scope', {
          organization_id: user?.selectedOrganization,
          start_date: new Date(moment(convertStringToDate(startDate)).format('YYYY-MM-DD')),
          end_date: new Date(moment(convertStringToDate(endDate)).format('YYYY-MM-DD'))
        });

        scopesData = response.data;
        setDataScopes(scopesData);
      }
      if (dataHolding && foundOrganization?.is_group_fund) {
        // add to the scope 3 the childs co2e
        // find index of scope 3
        const index = scopesData.findIndex((elem) => elem.scope === 3);
        if (index !== -1) {
          scopesData[index].total_emissions += dataHolding.childs_co2e;
        } else {
          scopesData.push({
            scope: 3,
            total_emissions: dataHolding.childs_co2e
          });
        }
      }
      setLoading(false);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [user?.selectedOrganization, startDate, endDate, groupDataMode]);

  const renderCo2Information = () => {
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(totalCo2).toString().length - maxDigits) - 2;
    }
    return (
      <div className='co2-information'>
        <div
          className='co2-value highlight-text-color co2-dashboard-font'
          style={{ fontSize: `${fontSize}px` }}>
          {numberToDecimal(totalCo2)}
        </div>
        <span className='co2-units on-light-text-color body1-font'>{unit}</span>
      </div>
    );
  };

  if (dataScopes && dataScopes.length > 0) {
    dataScopes.forEach((value) => {
      totalCo2 += value.total_emissions;

      if (value.scope === 1) {
        scope1 += value.total_emissions;
      }
      if (value.scope === 2) {
        scope2 = value.total_emissions;
      }
      if (value.scope === 3) {
        scope3 = value.total_emissions;
      }
    });

    pieChartData = [
      { title: '', value: (scope1 * 100) / totalCo2, color: scope1_color },
      { title: '', value: (scope2 * 100) / totalCo2, color: scope2_color },
      { title: '', value: (scope3 * 100) / totalCo2, color: scope3_color }
    ];
    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  totalCo2 = numberToDecimal(totalCo2 / 1000);
  const unit = 't CO₂ eq.';

  const scope1Value = numberToDecimal(scope1 / 1000);

  const scope2Value = numberToDecimal(scope2 / 1000);

  const scope3Value = numberToDecimal(scope3 / 1000);

  const goToDashboard = () => {
    navigate(ROUTES.IMPROVE_DASHBOARD);
  };

  const goToRecommendations = () => {
    navigate(ROUTES.IMPROVE_RECOMMENDATIONS);
  };

  const handleSwitchGroupMode = () => {
    if (loading) return;
    if (groupDataMode === 'total') {
      setGroupDataMode('investment');
    } else {
      setGroupDataMode('total');
    }
  };

  return (
    <section className='improve'>
      <div className='improve__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('improveMain.title')}</h3>
        <h4 className='subtitle2-font on-light-text-color'>{t('improveMain.subtitle')}</h4>
      </div>
      {flags?.consolidation && (
        <div className='improve__mode'>
          <SlideToggle2
            options={[
              {
                id: 'total',
                name: t('dashboard.consolidated'),
                tooltip: t('dashboard.consolidatedTooltip')
              },
              {
                id: 'investment',
                name: t('dashboard.investment'),
                tooltip: t('dashboard.investmentTooltip')
              }
            ]}
            value={{ id: groupDataMode, name: groupDataMode }}
            setValue={handleSwitchGroupMode}
          />
        </div>
      )}
      {loading ? (
        <LoaderTables />
      ) : (
        <div className='improve__body'>
          <div className='co2-info'>
            <div className='card main-bg-color card-border-color'>
              <div className='card-header'>
                <h5 className='headline4-font'>{t('improveMain.dashboard')}</h5>
                <Tooltip text={t('improveMain.dashboardTooltip')} />
              </div>
              <div className='card-dashboard card-border-color'>
                <div className='dashboard-first-column'>
                  <div className='co2-wrapper round on-card-gray-bg-color'>
                    <div className='co2-progress-bar'>
                      <PieChart // your data
                        data={pieChartData}
                        // width and height of the view box
                        viewBoxSize={[100, 100]}
                        lineWidth={24}
                        totalValue={100}
                        rounded
                        animate></PieChart>
                      {renderCo2Information()}
                    </div>
                  </div>
                  <div className='button-wrapper'>
                    <Button
                      lookAndFeel={'primary'}
                      text={t('improveMain.goToDashboard')}
                      onClick={goToDashboard}
                      size='small'
                    />
                  </div>
                </div>
                <div className='scopes'>
                  <ScopeTag scope={1} value={scope1Value} shortText />
                  <ScopeTag scope={2} value={scope2Value} shortText />
                  <ScopeTag scope={3} value={scope3Value} shortText />
                </div>
              </div>
            </div>
            <OffsetCard />
            <div className='card main-bg-color  card-border-color'>
              <div className='card-header'>
                <h5 className='headline4-font'>{t('improveMain.recommendations')}</h5>
                <Tooltip text={t('improveMain.recommendationsTooltip')} position='left' />
              </div>
              <div className='card-recommendation'>
                <div className='card'>
                  <IconBackground icon={'/images/icons/bookmark.svg'} alt={'bookmark'} />
                  <div className='step-info'>
                    <span className='headline4-font'>{t('improveMain.greenEnergy')}</span>
                  </div>
                  <Button
                    lookAndFeel='secondary'
                    size='small'
                    text={t('improveMain.choose')}
                    onClick={goToRecommendations}
                  />
                </div>
                <div className='card'>
                  <IconBackground icon={'/images/icons/thumbsup50.svg'} alt={'thumbsup'} />
                  <div className='step-info'>
                    <span className='headline4-font'>{t('improveMain.solarEnergy')}</span>
                  </div>
                  <Button
                    lookAndFeel='secondary'
                    size='small'
                    text={t('improveMain.choose')}
                    onClick={goToRecommendations}
                  />
                </div>
                <div className='card'>
                  <IconBackground icon={'/images/icons/stacked50.svg'} alt={'stacked50'} />
                  <div className='step-info'>
                    <span className='headline4-font'>{t('improveMain.mobility')}</span>
                  </div>
                  <Button
                    lookAndFeel='secondary'
                    size='small'
                    text={t('improveMain.choose')}
                    onClick={goToRecommendations}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tasks />
        </div>
      )}
    </section>
  );
}

export default ImproveMainPage;
