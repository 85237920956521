import formatNumber from './formatNumber';

const numberToDecimal = (num: number, precision = 2) => {
  if (!num) return 0;
  const str = num.toString();
  const decimalIndex = str.indexOf('.');
  //Check if number contains 'e' (exponential) and convert to string
  if (str.indexOf('e') > -1 && decimalIndex > -1) {
    // split number into number and exponent
    const exponent = parseInt(str.split('e')[1], 10);
    const number = str.split('e')[0];

    // construct number like 3.58e-7
    let croppedNumber = number.substring(0, decimalIndex + precision + 1);

    for (let i = decimalIndex + 1; i < str.length; i++) {
      if (str[i] !== '0') {
        croppedNumber = croppedNumber.substring(0, i + 1);
        break;
      }
    }
    return parseFloat(croppedNumber + 'e' + exponent);
  }
  if (precision > 2 && num < 1) {
    return parseFloat(num.toFixed(precision));
  }
  return parseFloat((Math.round(Number(num) * 100) / 100).toFixed(precision));
};

export const numberToScientificNotation = (n?: number) => {
  // If number is 0 or undefined
  if (!n) return '0.00';

  // If number is very large
  if (n > 999) return Number(n).toPrecision(3);

  // If number is normal
  if (n > 0.009) return Number(n).toFixed(2);

  // If number is very small
  return Number(n).toExponential(2);
};

export const numberToDecimalNonZero = (number: number, precision = 2) => {
  const epsilon = 1e-15; // A small value for comparison

  for (let numDecimals = 1; numDecimals <= 15; numDecimals++) {
    const roundedNumber = parseFloat(number?.toFixed(numDecimals));

    // Check for required number of consecutive non-zero digits
    let nonZeroCount = 0;
    for (let i = 0; i < numDecimals; i++) {
      const digit = Math.floor((roundedNumber * Math.pow(10, i)) % 10);
      if (Math.abs(digit) > epsilon) {
        nonZeroCount++;
        if (nonZeroCount === precision) {
          return roundedNumber;
        }
      }
    }
  }
  return numberToDecimal(number, precision);
};

export default numberToDecimal;

export const formatNumberToDecimalNonZero = (number: number, precision = 2) => {
  return formatNumber(numberToDecimalNonZero(number, precision));
};
