import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getDataQualityAccuracy } from '../../../../services/api/data_quality';
import { getGHGReportInfo } from '../../../../services/api/ghg';
import { getIntensityMetricById } from '../../../../services/api/intensityMetrics';
import { getRenewableEnergyKPI } from '../../../../services/api/invoices';
import { getMatrix } from '../../../../services/api/matrices';
import { getOrganizationEfs } from '../../../../services/api/organization';
import { getGWPTable, getIPCCVersion } from '../../../../services/api/orgEf';
import {
  getBusinessTravelsGroupedByTransport,
  getEmployees,
  getTotalImpactPurchases,
  getTotalImpactsInvestments,
  getYearComparison
} from '../../../../services/api/total_impacts';
import { BusinessTravelGHGReport } from '../../../../types/entities/businessTravels';
import { EmissionFactor } from '../../../../types/entities/emissionFactor';
import { EmployeesGHGReport } from '../../../../types/entities/employee';
import { FacilityReport } from '../../../../types/entities/facility';
import { Holding, InvestmentReport, OrgInfo } from '../../../../types/entities/holdings';
import { TransportDistributionReport } from '../../../../types/entities/shipment';
import { VehicleReportGHG } from '../../../../types/entities/vehicle';
import { WastesGHGReport } from '../../../../types/entities/waste';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import { PurchasesReportGHG } from '../../../../types/purchases';
import apiFetch from '../../../../utils/apiFetch';
import {
  convertDateBackToFront,
  convertDateToStringBackend,
  convertStringToDate,
  convertStringToDateBackend
} from '../../../../utils/convertDates';
import { limitString } from '../../../../utils/limitString';
import makeRequestsInBatches from '../../../../utils/makeRequestInBatches';
import { getAssumptions } from '../assumptions/service/getAssumptions';

type Props = { groupDataMode: GroupDataMode };

const useGetData = ({ groupDataMode }: Props) => {
  const flags = useFeatureFlags();
  const foundOrg = useSelectedOrganization();

  const [reportData, setReportData] = useState<IGHGBack>();
  const [facilities, setFacilities] = useState<FacilityReport[]>([]);
  const [vehicles, setVehicles] = useState<VehicleReportGHG[]>([]);
  const [dataElectricity, setDataElectricity] = useState<TotalImpactElectricity[]>([]);
  // const [dataEvolution, setDataEvolution] = useState<EvolutionData[]>([]);
  const [dataScopes, setDataScopes] = useState<ScopeData[]>([]);
  const [dataCategories, setDataCategories] = useState<CategoryData[]>([]);
  const [dataYearComparison, setDataYearComparison] = useState<YearComparisonData[]>([]);
  const [totalBiomass, setTotalBiomass] = useState(0);
  const [organizationEfs, setOrganizationEfs] = useState<EmissionFactor[]>([]);
  const [dataAccuracy, setDataAccuracy] = useState('');
  const [intensityMetric1, setIntensityMetric1] = useState<IntensityMetricReport>();
  const [intensityMetric2, setIntensityMetric2] = useState<IntensityMetricReport>();
  const [purchases, setPurchases] = useState<PurchasesReportGHG>({
    total: 0,
    total_recycled: 0,
    items: []
  });
  const [organizationCo2e, setOrganizationCo2e] = useState(0);
  const [renewableEnergy, setRenewableEnergy] = useState(0);
  const [shipments, setShipments] = useState<TransportDistributionReport>();
  const [deliveries, setDeliveries] = useState<TransportDistributionReport>();
  const [businessTravels, setBusinessTravels] = useState<BusinessTravelGHGReport[]>([]);
  const [employees, setEmployees] = useState<EmployeesGHGReport[]>([]);
  const [investments, setInvestments] = useState<InvestmentReport[]>([]);
  const [investmentTotalImpacts, setInvestmentTotalImpacts] = useState<TotalImpactPerMonth[]>([]);
  const [wastes, setWastes] = useState<WastesGHGReport[]>([]);
  const [assumptions, setAssumptions] = useState<AssumptionsResponse>({});
  const [gwpTable, setGwpTable] = useState<GWPTable>();
  const [ipccVersion, setIpccVersion] = useState<string>();

  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const intensityMetricId1 = searchParams.get('intensity_metric_id_1');
  const intensityMetricId2 = searchParams.get('intensity_metric_id_2');

  const fetchConfig = async () => {
    if (!foundOrg) return;

    const responseGhgConfigurationInfo = await getGHGReportInfo();
    if (
      !responseGhgConfigurationInfo ||
      responseGhgConfigurationInfo?.response?.status >= 400 ||
      responseGhgConfigurationInfo instanceof Error
    )
      return;

    const config: IGHGBack = {
      ...responseGhgConfigurationInfo,
      description: foundOrg.description,
      trade_name: foundOrg.trade_name
    };

    setReportData(config);

    return {
      startDate: config.start_date,
      endDate: config.end_date,
      scopes_to_show: config.scopes,
      intensityMetrics: config.intensity_metric
    };
  };

  const fetchData = async () => {
    try {
      if (!foundOrg) return;
      setLoading(true);

      const configResponse = await fetchConfig();
      if (!configResponse) return;
      const { startDate, endDate, scopes_to_show, intensityMetrics } = configResponse;

      if (!startDate || !endDate || !scopes_to_show) return;

      const requests = [];

      const startDateToDate = convertStringToDateBackend(startDate);
      let endDateToDate = convertStringToDateBackend(endDate);

      // Check if end_date year is equal to current year, then set end_date to today
      const today = new Date();
      if (endDateToDate.getFullYear() === today.getFullYear()) {
        // Format to YYYY-MM-DD
        endDateToDate = convertStringToDate(convertDateToStringBackend(today));
      }

      startDateToDate.setUTCHours(0, 0, 0, 0);
      endDateToDate.setUTCHours(0, 0, 0, 0);

      const requestMatrix = () =>
        getMatrix(foundOrg.id ?? '', startDateToDate, endDateToDate, groupDataMode, true);
      requests.push(requestMatrix);

      requests.push(() =>
        getYearComparison(
          convertDateBackToFront(startDate),
          convertDateBackToFront(endDate),
          foundOrg.id ?? '',
          groupDataMode,
          true
        )
      );

      let requestFacilities = null;
      if (scopes_to_show.includes(1) || scopes_to_show.includes(2)) {
        requestFacilities = () =>
          apiFetch('POST', '/total_impacts/facilities', {
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString(),
            organization_id: foundOrg.id
          });
      }
      requests.push(requestFacilities);

      let requestVehicles = null;
      if (scopes_to_show.includes(1)) {
        requestVehicles = () =>
          apiFetch('POST', '/total_impacts/vehicles', {
            organization_id: foundOrg.id,
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString()
          });
      }
      requests.push(requestVehicles);

      let requestElectricity = null;
      if (scopes_to_show.includes(2)) {
        requestElectricity = () =>
          apiFetch('POST', '/total_impacts/electricity', {
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString(),
            organization_id: foundOrg.id
          });
      }
      requests.push(requestElectricity);

      const requestBiomass = () =>
        apiFetch('POST', '/total_impacts/biomass', {
          start_date: startDateToDate.toISOString(),
          end_date: endDateToDate.toISOString(),
          organization_id: foundOrg.id
        });
      requests.push(requestBiomass);

      let requestGetOrganizationEfs = null;

      if (flags?.organizationEfs) {
        requestGetOrganizationEfs = () =>
          getOrganizationEfs(
            foundOrg.id ?? '',
            startDateToDate,
            endDateToDate,
            scopes_to_show.toString(),
            true
          );
      }
      requests.push(requestGetOrganizationEfs);

      const requestDataQualityAccuracy = () =>
        getDataQualityAccuracy(foundOrg.id ?? '', startDateToDate, endDateToDate, true);
      requests.push(requestDataQualityAccuracy);

      // Check if the value of the intensity metric in the query url params
      let requestIntensityMetrics1 = null;
      let requestIntensityMetrics2 = null;

      // Get query params of url
      const params = new URLSearchParams(window.location.search);
      const intensityMetric1 = intensityMetricId1 || undefined;
      const intensityMetric2 = intensityMetricId2 || undefined;

      // Request to get the intensity metric value by id and year
      if (intensityMetric1) {
        requestIntensityMetrics1 = () => getIntensityMetricById(intensityMetric1, true);
      }

      if (intensityMetric2)
        requestIntensityMetrics2 = () => getIntensityMetricById(intensityMetric2, true);

      requests.push(requestIntensityMetrics1);
      requests.push(requestIntensityMetrics2);

      let requestPurchases = null;
      if (scopes_to_show.includes(3)) {
        requestPurchases = () =>
          getTotalImpactPurchases(
            startDateToDate,
            endDateToDate,
            foundOrg.id ?? '',
            groupDataMode,
            'ghg',
            true
          );
      }
      requests.push(requestPurchases);

      // check organization create date
      const organizationCreatedAt = convertStringToDate(
        convertDateBackToFront(foundOrg.created_at)
      );
      let combustion = false;
      // 27/05/2024 with moment to date
      const limitDate = moment('27/05/2024', 'DD/MM/YYYY');

      if (organizationCreatedAt < limitDate.toDate() || flags?.renewableEnergyKPI) {
        combustion = true;
      }
      // Fetch Renewable Energy KPI
      const requestRenewableEnergyKPI = () =>
        getRenewableEnergyKPI(startDateToDate, endDateToDate, combustion, true);
      requests.push(requestRenewableEnergyKPI);

      let requestTransportDistributionDownstream = null;
      if (scopes_to_show.includes(3)) {
        requestTransportDistributionDownstream = () =>
          apiFetch('POST', '/total_impacts/transport_distribution', {
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString(),
            organization_id: foundOrg.id,
            direction: 'downstream'
          });
      }
      requests.push(requestTransportDistributionDownstream);

      let requestTransportDistributionUpstream = null;
      if (scopes_to_show.includes(3)) {
        requestTransportDistributionUpstream = () =>
          apiFetch('POST', '/total_impacts/transport_distribution', {
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString(),
            organization_id: foundOrg.id,
            direction: 'upstream'
          });
      }
      requests.push(requestTransportDistributionUpstream);

      let requestBusinessTravels = null;
      if (scopes_to_show.includes(3)) {
        requestBusinessTravels = () =>
          getBusinessTravelsGroupedByTransport(
            foundOrg.id ?? '',
            startDateToDate,
            endDateToDate,
            groupDataMode,
            true
          );
      }
      requests.push(requestBusinessTravels);

      let requestEmployees = null;
      if (scopes_to_show.includes(3)) {
        requestEmployees = () =>
          getEmployees(foundOrg.id ?? '', startDateToDate, endDateToDate, true);
      }
      requests.push(requestEmployees);

      let requestInvestments = null;
      if (scopes_to_show.includes(3)) {
        requestInvestments = () =>
          getTotalImpactsInvestments(startDateToDate, endDateToDate, foundOrg.id ?? '', true);
      }

      requests.push(requestInvestments);

      requests.push(() =>
        getAssumptions({ start_date: startDateToDate.getTime(), end_date: endDateToDate.getTime() })
      );

      const requestWastes = () =>
        apiFetch('POST', '/total_impacts/wastes', {
          start_date: startDateToDate.toISOString(),
          end_date: endDateToDate.toISOString(),
          organization_id: foundOrg.id
        });
      requests.push(requestWastes);

      requests.push(getGWPTable);

      requests.push(getIPCCVersion);

      if (!requests) return;

      const [
        responseMatrix,
        responseYearComparison,
        responseFacilities,
        responseVehicles,
        responseElectricity,
        responseBiomass,
        responseGetOrganizationEfs,
        responseDataQualityAccuracy,
        responseIntensityMetric1,
        responseIntensityMetric2,
        responsePurchases,
        responseRenewableEnergyKPI,
        responseTransportDistributionDownstream,
        responseTransportDistributionUpstream,
        responseBusinessTravels,
        responseEmployees,
        responseInvestments,
        responseAssumptions,
        responseWastes,
        responseGWPTable,
        responseIPCCVersion
      ] = await makeRequestsInBatches(requests);

      let totalCo2 = 0;

      const matrixData = {
        ...(responseMatrix?.data as Holding),
        parent_co2e: responseMatrix?.data.parent_co2e?.map((elem: any) => ({
          ...elem,
          category: elem.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('passenger', 'transport')
            .replace('freight', 'transport')
        })),
        childs_info: responseMatrix?.data.childs_info?.map((elem: OrgInfo) => ({
          ...elem,
          co2e: elem.co2e.map((elem2) => ({
            ...elem2,
            category: elem2.category
              .replace(/_in_labore/g, '')
              .replace(/_in_itinere/, '')
              .replace('passenger', 'transport')
              .replace('freight', 'transport')
          }))
        }))
      };
      totalCo2 = groupByDataHolding(matrixData);
      const investmentsParsed = matrixData.childs_info.map((investment: OrgInfo) => {
        let investmentCo2 = 0;
        investment.co2e.forEach((elem) => {
          investmentCo2 += elem.total_emissions;
        });
        return {
          ...investment,
          id: investment.org_id,
          co2e: investmentCo2
        };
      });
      setInvestments(
        investmentsParsed.sort((a: InvestmentReport, b: InvestmentReport) => b.co2e - a.co2e)
      );

      setOrganizationCo2e(totalCo2);

      setDataYearComparison(responseYearComparison.data);

      if (responseAssumptions) {
        setAssumptions(responseAssumptions);
      }

      if (responseFacilities) {
        const facilitiesSorted = responseFacilities.data.sort(
          (a: FacilityReport, b: FacilityReport) => b.total_emissions - a.total_emissions
        );
        setFacilities(facilitiesSorted);
      }

      if (responseVehicles) {
        const vehiclesSorted = responseVehicles.data.sort(
          (a: VehicleReportGHG, b: VehicleReportGHG) => b.total_emissions - a.total_emissions
        );
        setVehicles(vehiclesSorted);
      }

      if (responseElectricity) {
        setDataElectricity(
          responseElectricity.data.map((elem: { year: number; month: number }) => ({
            ...elem,
            date: new Date(elem.year, elem.month - 1)
          }))
        );
      }

      setTotalBiomass(responseBiomass.data.biomass);

      if (flags?.organizationEfs && responseGetOrganizationEfs && responseGetOrganizationEfs.data) {
        setOrganizationEfs(responseGetOrganizationEfs.data);
      }

      if (responseDataQualityAccuracy) {
        setDataAccuracy(responseDataQualityAccuracy.data.data_quality_uncertainty);
      }

      if (responseIntensityMetric1 && responseIntensityMetric1.data.id) {
        setIntensityMetric1({
          id: responseIntensityMetric1.data.id,
          name: `${limitString(responseIntensityMetric1.data.unit ?? '', 25)}`,
          value: responseIntensityMetric1.data.value / 1000,
          unit: 't CO₂ eq.',
          start_date: responseIntensityMetric1.data.start_date,
          end_date: responseIntensityMetric1.data.end_date
        });
      }

      if (responseIntensityMetric2 && responseIntensityMetric2.data.id) {
        setIntensityMetric2({
          id: responseIntensityMetric2.data.id,
          name: `${limitString(responseIntensityMetric2.data.unit ?? '', 25)}`,
          value: responseIntensityMetric2.data.value / 1000,
          unit: 't CO₂ eq.',
          start_date: responseIntensityMetric2.data.start_date,
          end_date: responseIntensityMetric2.data.end_date
        });
      }

      if (responsePurchases) {
        setPurchases(responsePurchases.data);
      }

      if (responseRenewableEnergyKPI) {
        setRenewableEnergy(responseRenewableEnergyKPI.data.electricity.renewable_ratio * 100);
      }
      if (responseTransportDistributionDownstream) {
        setShipments(responseTransportDistributionDownstream.data);
      }
      if (responseTransportDistributionUpstream) {
        setDeliveries(responseTransportDistributionUpstream.data);
      }
      if (responseBusinessTravels) {
        const businessTravelsSorted = responseBusinessTravels.data.sort(
          (a: BusinessTravelGHGReport, b: BusinessTravelGHGReport) => b.co2e - a.co2e
        );
        setBusinessTravels(businessTravelsSorted);
      }

      if (responseEmployees) {
        const employeesSorted = responseEmployees.data.sort(
          (a: EmployeesGHGReport, b: EmployeesGHGReport) => b.co2e - a.co2e
        );
        setEmployees(employeesSorted);
      }

      if (responseInvestments) {
        setInvestmentTotalImpacts(responseInvestments?.data);
      }

      if (responseWastes) {
        setWastes(responseWastes.data);
      }

      if (responseGWPTable) {
        setGwpTable(responseGWPTable);
      }

      if (responseIPCCVersion) {
        setIpccVersion(responseIPCCVersion);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!foundOrg?.id || !flags || !Object.keys(flags).length) return;

    // call the function
    fetchData();
  }, [groupDataMode]);

  const groupByDataHolding = (dataHolding: Holding) => {
    let totalCo2 = 0;
    const groupByScope = [
      { scope: 1, total_emissions: 0, measured: false },
      { scope: 2, total_emissions: 0, measured: false },
      { scope: 3, total_emissions: 0, measured: false }
    ];

    const groupByCategory = [
      { category: 'stationary', scope: 1, total_emissions: 0, measured: false },
      { category: 'recharge', scope: 1, total_emissions: 0, measured: false },
      { category: 'transport', scope: 1, total_emissions: 0, measured: false },
      { category: 'electricity', scope: 2, total_emissions: 0, measured: false },
      { category: 'water', scope: 3, total_emissions: 0, measured: false },
      { category: 'employees', scope: 3, total_emissions: 0, measured: false },
      { category: 'travels', scope: 3, total_emissions: 0, measured: false },
      { category: 'purchases', scope: 3, total_emissions: 0, measured: false },
      { category: 'equity', scope: 3, total_emissions: 0, measured: false },
      { category: 'energy_fuel', scope: 3, total_emissions: 0, measured: false },
      { category: 'waste', scope: 3, total_emissions: 0, measured: false },
      {
        category: 'transport_distribution_upstream',
        scope: 3,
        total_emissions: 0,
        measured: false
      },
      { category: 'rented_assets_upstream', scope: 3, total_emissions: 0, measured: false },
      {
        category: 'transport_distribution_downstream',
        scope: 3,
        total_emissions: 0,
        measured: false
      },
      { category: 'sold_products_processing', scope: 3, total_emissions: 0, measured: false },
      { category: 'sold_products_usage', scope: 3, total_emissions: 0, measured: false },
      { category: 'rented_assets_downstream', scope: 3, total_emissions: 0, measured: false },
      { category: 'franchise', scope: 3, total_emissions: 0, measured: false },
      { category: 'investment', scope: 3, total_emissions: 0, measured: false }
    ];

    dataHolding.childs_info.forEach((child) => {
      child.co2e.forEach((elem) => {
        totalCo2 += elem.total_emissions;
        const categoryParsed = elem.category
          .replace(/_in_labore/g, '')
          .replace(/_in_itinere/, '')
          .replace('water', 'purchases')
          .replace('passenger', 'transport')
          .replace('freight', 'transport');
        if (groupDataMode === GroupDataMode.TOTAL) {
          if (elem.scope === 1) {
            groupByScope[0].total_emissions += elem.total_emissions;
            groupByScope[0].measured = true;
          }
          if (elem.scope === 2) {
            groupByScope[1].total_emissions += elem.total_emissions;
            groupByScope[1].measured = true;
          }
          if (elem.scope === 3) {
            groupByScope[2].total_emissions += elem.total_emissions;
            groupByScope[2].measured = true;
          }

          const foundCategoryIndex = groupByCategory.findIndex(
            (elem2) => elem2.category === categoryParsed
          );
          if (foundCategoryIndex !== -1) {
            groupByCategory[foundCategoryIndex].total_emissions += elem.total_emissions;
            groupByCategory[foundCategoryIndex].measured = true;
          } else {
            groupByCategory.push({
              category: categoryParsed,
              total_emissions: elem.total_emissions,
              scope: elem.scope,
              measured: true
            });
          }
        } else {
          // Only to investments
          groupByScope[2].total_emissions += elem.total_emissions;
          groupByScope[2].measured = true;
          const foundCategoryIndex = groupByCategory.findIndex(
            (elem2) => elem2.category === 'investment'
          );
          if (foundCategoryIndex !== -1) {
            groupByCategory[foundCategoryIndex].total_emissions += elem.total_emissions;
            groupByCategory[foundCategoryIndex].measured = true;
          } else {
            groupByCategory.push({
              category: 'investment',
              total_emissions: elem.total_emissions,
              scope: elem.scope,
              measured: true
            });
          }
        }
      });
    });

    dataHolding.parent_co2e?.forEach((elem) => {
      totalCo2 += elem.total_emissions;
      const categoryParsed = elem.category
        .replace(/_in_labore/g, '')
        .replace(/_in_itinere/, '')
        .replace('water', 'purchases')
        .replace('passenger', 'transport')
        .replace('freight', 'transport');
      if (elem.scope === 1) {
        groupByScope[0].total_emissions += elem.total_emissions;
        groupByScope[0].measured = true;
      }
      if (elem.scope === 2) {
        groupByScope[1].total_emissions += elem.total_emissions;
        groupByScope[1].measured = true;
      }
      if (elem.scope === 3) {
        groupByScope[2].total_emissions += elem.total_emissions;
        groupByScope[2].measured = true;
      }

      const foundCategoryIndex = groupByCategory.findIndex(
        (elem2) => elem2.category === categoryParsed
      );

      if (foundCategoryIndex !== -1) {
        groupByCategory[foundCategoryIndex].total_emissions += elem.total_emissions;
        groupByCategory[foundCategoryIndex].measured = true;
      } else {
        groupByCategory.push({
          category: categoryParsed,
          total_emissions: elem.total_emissions,
          scope: elem.scope,
          measured: true
        });
      }
    });

    setDataScopes(groupByScope);
    setDataCategories(groupByCategory);
    return totalCo2;
  };

  const query = new URLSearchParams(window.location.search);
  const showTemporalComparative = query.get('showTemporalComparative');

  return {
    reportData,
    facilities,
    vehicles,
    dataElectricity,
    // dataEvolution,
    dataScopes,
    dataCategories,
    dataYearComparison,
    totalBiomass,
    organizationEfs,
    dataAccuracy,
    intensityMetric1,
    intensityMetric2,
    purchases,
    organizationCo2e,
    renewableEnergy,
    shipments,
    deliveries,
    businessTravels,
    employees,
    investments,
    investmentTotalImpacts,
    showTemporalComparative,
    assumptions,
    wastes,
    gwpTable,
    ipccVersion,
    loading
  };
};

export default useGetData;
