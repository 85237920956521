import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadUser, switchGrouping, switchOrganization } from '../../../../actions/auth';
import { SIZE_PAGINATION_BIG } from '../../../../constants';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import { checkMembership } from '../../../../services/api/membership';
import { authParseLoginCallback } from '../../../../services/authService';
import { GroupingType } from '../../../../types/entities/grouping';
import { Organization } from '../../../../types/entities/organization';
import { Session } from '../../../../types/entities/user';

import { InputSize } from '../../../../types/utilsEnums/input';
import apiFetch from '../../../../utils/apiFetch';
import Button from '../../../ui/button/Button';
import InputWrapperPlain from '../../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import SelectWithExtraOptions from '../../../ui/formComponents2/select/Select';
import './styles.scss';

const SwitchOrganization = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get url query params
  const [searchParams, setSearchParams] = useSearchParams();

  const [itemSelected, setItemSelected] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });

  const [groupings, setGroupings] = useState<GroupingType[]>([]);

  const disabledRoutes = [ROUTES.BOOK_MEETING];

  const fetchGroupings = async () => {
    const response = await apiFetch(
      'GET',
      '/data_groups',
      null,
      {},
      {
        size: SIZE_PAGINATION_BIG
      }
    );
    const data = response.data;

    const foundGrouping = data.items.find(
      (grouping: { id: string; name: string }) => grouping.id === user?.selectedGrouping
    );
    setGroupings(data.items);
    if (!foundGrouping && data.items.length === 0) return;
    if (!foundGrouping && data.items.length > 0) {
      // set first grouping as selected
      setItemSelected({
        id: data.items[0].id,
        name: data.items[0].name
      });
      dispatch(switchGrouping(data.items[0].id));
      return;
    }
    setItemSelected({
      id: foundGrouping.id,
      name: foundGrouping.name
    });
    dispatch(switchGrouping(foundGrouping.id));
  };

  useEffect(() => {
    if (user?.selectedGrouping) {
      return;
    }
    const org = user?.organizations?.find((org) => org.id === user.selectedOrganization);

    setItemSelected({
      id: org?.id ?? '',
      name: org?.company_name ?? ''
    });
  }, [user?.selectedOrganization]);

  useEffect(() => {
    if (window.location.pathname !== ROUTES.IMPROVE_DASHBOARD) {
      dispatch(switchGrouping(''));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (user?.selectedGrouping && window.location.pathname === ROUTES.IMPROVE_DASHBOARD) {
      fetchGroupings();
      return;
    } else {
      const org = user?.organizations?.find((org) => org.id === user.selectedOrganization);

      setItemSelected({
        id: org?.id ?? '',
        name: org?.company_name ?? ''
      });
    }
  }, [user?.selectedGrouping]);

  const handleOnChangeValue = async (value: SelectOptionFormat) => {
    if (!user) return;

    if (user?.selectedGrouping) {
      setItemSelected(value);
      dispatch(switchGrouping(value.id));
      return;
    }

    // check if user still has membership with organization before switching
    const membership = await checkMembership(user.id, value.id);

    // if there is no membership just reload window to update memberships
    if (!membership) {
      const sessionData: Session = await authParseLoginCallback();

      await dispatch(loadUser(sessionData, false, false, user.selectedOrganization));
      window.location.reload();
      return;
    }

    await dispatch(switchOrganization(value.id));
    setItemSelected(value);
    navigate(ROUTES.IMPROVE_DASHBOARD);
    window.location.reload();
  };

  const navigateToCreateOrg = () => {
    navigate(`${ROUTES.REGISTER_COMPANY}/${user?.id}`);
  };

  let options: SelectOptionFormat[] = [];

  if (user?.organizations) {
    options = user?.organizations?.map((organization: Organization) => ({
      id: organization?.id ?? '',
      name: organization?.company_name ?? ''
    }));
  }
  if (user?.selectedGrouping && groupings.length > 0) {
    options = groupings?.map((grouping) => ({
      id: grouping.id,
      name: grouping.name
    }));
  }

  if (disabledRoutes.includes(location.pathname)) {
    return null;
  }
  return (
    <div className='header-organizations'>
      <InputWrapperPlain
        icon={
          user?.selectedGrouping ? '/images/icons/folder.svg' : '/images/icons/organization.svg'
        }
        size={InputSize.MEDIUM}>
        <SelectWithExtraOptions
          placeholder={t('header.selectOrganization')}
          options={options}
          value={itemSelected}
          onChange={handleOnChangeValue}
          extraOptions={
            !user?.selectedGrouping ? (
              <div style={{ marginTop: '0.5rem' }}>
                <Button
                  lookAndFeel='primary'
                  text={t('header.create')}
                  onClick={navigateToCreateOrg}
                  size='small'
                />
              </div>
            ) : null
          }
          size={InputSize.MEDIUM}
        />
      </InputWrapperPlain>
    </div>
  );
};

export default SwitchOrganization;
