import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../ui/button/Button';
import CardCTA from '../../../../../ui/cards/cardCTA/CardCTA';
import Modal from '../../../../../ui/modal/Modal';
import UploadModalCommon from '../../../pages/DoubleMateriality/components/UploadModal/UploadModalCommon';

export const HeaderCard = () => {
  const { t } = useTranslation();
  const [showUpload, setShowUpload] = useState<boolean>(false);
  return (
    <>
      <CardCTA style={{ margin: '2rem 0 1rem 0' }}>
        <CardCTA.Header>
          <span className='headline4-font'>{t('esgMetrics.social.title')}</span>
          <span className='subtitle3-font'>{t('esgMetrics.social.startDescription')}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel='primary'
            text={t('esgMetrics.uploadData')}
            size='small'
            onClick={() => setShowUpload(true)}
          />
        </CardCTA.Buttons>
      </CardCTA>
      <Modal show={showUpload} onClose={() => setShowUpload(false)} maxWidth='613px' width='613px'>
        <UploadModalCommon keyTranslation={'socialModal'} onClose={() => setShowUpload(false)} />
      </Modal>
    </>
  );
};
