import { gray_color } from '../../../styles/colors';
import { getCurrentYear, getPreviousYear } from './utils/utils';

export const mockedData = [
  {
    label: String(getPreviousYear()),
    value: '12',
    color: gray_color
  },

  {
    label: String(getCurrentYear()),
    value: '15',
    color: gray_color
  }
];

export const esgMetrics: EsgMetric[] = [
  {
    id: 'totalGroupCompanyCarbonFootprint',
    name: 'totalGroupCompanyCarbonFootprint',
    unit: 't CO2 eq.'
  },
  { id: 'scope1Emissions', name: 'scope1Emissions', unit: 't CO2 eq.' },
  { id: 'scope2Emissions', name: 'scope2Emissions', unit: 't CO2 eq.' },
  { id: 'scope3Emissions', name: 'scope3Emissions', unit: 't CO2 eq.' },
  { id: 'hazardousWaste', name: 'hazardousWaste', unit: 't CO2 eq.' },
  { id: 'waste', name: 'waste', unit: 't CO2 eq.' },
  { id: 'waterConsumption', name: 'waterConsumption', unit: 'm3' },
  { id: 'wasteAmountByLERCode', name: 'wasteAmountByLERCode', unit: 'kg' },
  { id: 'hazardousWasteAmount', name: 'hazardousWasteAmount', unit: 'kg' },
  { id: 'wasteAmountByRDCode', name: 'wasteAmountByRDCode', unit: 'kg' },
  { id: 'totalEnergyConsumption', name: 'totalEnergyConsumption', unit: 'kWh' },
  { id: 'totalElectricityConsumption', name: 'totalElectricityConsumption', unit: 'kWh' },
  {
    id: 'totalRenewableElectricityConsumption',
    name: 'totalRenewableElectricityConsumption',
    unit: 'kWh'
  },
  { id: 'totalRenewableEnergyConsumption', name: 'totalRenewableEnergyConsumption', unit: 'kWh' },
  {
    id: 'basicRenewableThermalEnergyPercentage',
    name: 'basicRenewableThermalEnergyPercentage',
    unit: 'kWh'
  },
  { id: 'averageGrossSalaryMenWomen', name: 'averageGrossSalaryMenWomen', unit: '%' },
  { id: 'ceoToEmployeePayGap', name: 'ceoToEmployeePayGap', unit: '€' },
  { id: 'menInManagement', name: 'menInManagement', unit: '€' },
  { id: 'womenInManagement', name: 'womenInManagement', unit: 'Nº' }
];

export const visualizationMode: SelectOptionFormat[] = [
  {
    id: 'organization',
    name: 'organization'
  },

  {
    id: 'facility',
    name: 'facility'
  }
];
