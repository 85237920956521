import { Mode } from '../../types/entitiesEnums/esgMetrics';
import apiFetch from '../../utils/apiFetch';

type IGetESGPresignedUrl = {
  fileName: string;
  folder: 'double_materiality' | 'environmental' | 'general_information' | 'social' | 'governance';
};

export const getPresignedUrlEsg = async ({ fileName, folder }: IGetESGPresignedUrl) => {
  try {
    const response = await apiFetch('POST', '/esg/upload', {
      file_name: fileName,
      folder
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type IGetESGExcel = {
  dateRanges: string[][];
  mode: Mode;
  intensitymetricGroupId: string;
  lang: string;
};

export const downloadEsgExcel = async ({
  dateRanges,
  mode,
  intensitymetricGroupId,
  lang
}: IGetESGExcel) => {
  try {
    const response = await apiFetch('POST', '/esg/excel', {
      date_ranges: dateRanges,
      mode,
      intensitymetric_group_id: intensitymetricGroupId,
      lang
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
