import moment from 'moment';
import { Dispatch } from 'react';
import { recalculateDatadisErrors } from '../services/api/datadis';
import { getTasksByUser } from '../services/api/tasks';
import { CreateOrganizationHoldingInfo } from '../types/entities/holdings';
import { Organization, OrganizationOptional } from '../types/entities/organization';
import { IGetBackendTask } from '../types/entities/task';
import {
  RegisterInfo1,
  RegisterInfo2,
  RegisterInfoInvited,
  Session,
  User
} from '../types/entities/user';
import apiFetch from '../utils/apiFetch';
import getBase64 from '../utils/getBase64';
import {
  AUTH_ERROR,
  CREATE_ORGANIZATION_AND_LINK_TO_HOLDING,
  GET_PENDING_TASKS,
  GET_TOTAL_IMPACTS_FAIL,
  GET_TOTAL_IMPACTS_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  REMOVE_ORGANIZATION_FROM_USER,
  SET_ORG_LIMIT_OFFICIAL_SUPPLIERS_BONUS,
  SWITCH_GROUPING,
  SWITCH_ORGANIZATION,
  UPDATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_INVOICING_DATA,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_USER_SIGN_IN_COUNT,
  UPLOAD_IMAGE_PROFILE,
  USER_LOADED
} from './types';

type LoadUserDispatch = {
  type: string;
  payload?: any;
};

export type SetUserEmailDispatch = {
  type: string;
  payload: string;
};

/*
  session: value of the session that comes from Cognito
*/
// Load User
export const loadUser =
  (session: Session, patchUser = true, getInvites = true, organization_id?: string) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      let id = session.id;

      // ###### LOCAL DEVELOPMENT NO LOGIN ######
      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        id = '1c8fd77f-4ff7-41cc-a99c-a42ebf4ffe58'; // id used only for login in local because it is the one we populate in the database
        // id = '5c2bcb8c-bdc8-4606-885f-d4de60e0a813'; // Maria
      }
      // ###### LOCAL DEVELOPMENT NO LOGIN ######

      const res = await apiFetch('get', `/users/${id}`, null, null, { organization_id });
      const user = res.data;

      const data: User = {
        // id: session.user_id,
        // email: session.email,
        ...user
      };

      if (patchUser && data.organizations && data.organizations.length > 0) {
        // Update sign in information from user
        await apiFetch('patch', `/users/${user.id}`, {
          sign_in_count: user.sign_in_count + 1,
          current_sign_in_at: new Date()
        });
        if (data.sign_in_count !== undefined && data.sign_in_count >= 0) {
          data.sign_in_count += 1;
        }
      }
      if (getInvites) {
        const resInvites = await apiFetch('get', `/invite_user/${user.email}`);

        data['invites'] = resInvites.data;
      }

      dispatch({
        type: USER_LOADED,
        payload: { ...data, selectedOrganization: organization_id }
      });
    } catch (err) {
      // Mixpanel.track('Unsuccessful login');
      console.log('err load user', err);
      dispatch({
        type: AUTH_ERROR
      });
    }
  };

/*
  id: id of the user to update
  registerInfo: values to update from the user, the organization to create and the membership to create
*/
export const registerUser =
  (id: string, registerInfo: RegisterInfo2) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      const response = await apiFetch('post', `/register/${id}`, registerInfo);

      if (response.status !== 201) {
        return response.data;
      }

      const responseGetUserById = await apiFetch('get', `/users/${id}`);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          ...responseGetUserById.data,
          selectedOrganization: response.data.organization_id
        }
      });

      return response.data;
    } catch (err: any) {
      console.error(err);
      dispatch({
        type: REGISTER_FAIL
      });
      if (err?.response?.status === 422) {
        return err.response.data;
      }
      return null;
    }
  };

/*
  id: id of the organization to update
  organization: values to update from the organization
*/
export const updateOrganizationInfo =
  (id: string, organization: OrganizationOptional) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      const res = await apiFetch('patch', `/organizations/${id}`, {
        ...organization
      });
      dispatch({
        type: UPDATE_ORGANIZATION_SUCCESS,
        payload: { ...res.data, id }
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ORGANIZATION_FAIL
      });
      return null;
    }
  };

/*
  id: id of the user to update
  registerInfo: values to update from the user
*/
export const registerUserInvited =
  (id: string, email: string, registerInfo: RegisterInfoInvited) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      // Set invites to accepted
      await apiFetch('patch', `/invite_user/${email}/accept`);

      // Update information from user
      const res = await apiFetch('patch', `/users/${id}`, {
        first_name: registerInfo.first_name,
        last_name: registerInfo.last_name,
        prefix: registerInfo.prefix,
        phone_number: registerInfo.phone_number,
        sign_in_count: 1,
        current_sign_in_at: new Date(),
        accepted_comms_marketing: registerInfo.accepted_comms_marketing,
        accepted_terms_privacy: registerInfo.accepted_terms_privacy,
        onboarding_done: true
      });

      dispatch({
        type: REGISTER_SUCCESS,
        payload: { ...res.data, invites: [] }
      });
      return { msg: 'success' };
    } catch (err: any) {
      dispatch({
        type: REGISTER_FAIL
      });
      if (err?.response?.status === 422) {
        return err.response.data.detail[0].msg;
      }
      return null;
    }
  };

// Load User
export const uploadUserProfileImage =
  (id: string, file: File) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      getBase64(file, (file_url: string | null | ArrayBuffer) => {
        if (file_url) {
          apiFetch('PATCH', `/users/${id}`, {
            profile_img_url: file_url
          }).then((response) => {
            dispatch({
              type: UPLOAD_IMAGE_PROFILE,
              payload: response.data
            });
          });
        }
      });
    } catch (err) {
      console.log('err upload profile image', err);
      dispatch({
        type: AUTH_ERROR
      });
    }
  };

/*
  id: id of the organization to update
  offset: offset value to update in onboarding info
*/
export const updateOrganizationOffset =
  (id: string, offset: number) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      // Update information from user
      const res = await apiFetch('patch', `/organizations/${id}`, {
        offset
      });

      dispatch({
        type: UPDATE_ORGANIZATION_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      console.log('err update organization Info', err);
      dispatch({
        type: UPDATE_ORGANIZATION_FAIL
      });
      return null;
    }
  };

/*
  organization_id: id of the organization to filter total impacts
  start_date: start date to filter total impacts
  end_date: end date to filter total impacts
*/
export const getTotalImpacts =
  (
    start_date: Date,
    end_date: Date,
    organization_id: string,
    callback?: (data?: TotalImpact[]) => void
  ) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      // Update information from user
      const res = await apiFetch('post', '/total_impacts/date', {
        start_date: new Date(moment(new Date(start_date)).format('YYYY-MM-DD')),
        end_date: new Date(moment(new Date(end_date)).format('YYYY-MM-DD')),
        organization_id
      });

      dispatch({
        type: GET_TOTAL_IMPACTS_SUCCESS,
        payload: { total_impacts: res.data, organization_id }
      });
      if (callback) {
        callback(res.data);
      }
    } catch (err) {
      console.log('err get total impacts info', err);
      dispatch({
        type: GET_TOTAL_IMPACTS_FAIL
      });
      if (callback) {
        callback(undefined);
      }
      return null;
    }
  };

export const switchOrganization = (id: string) => async (dispatch: Dispatch<LoadUserDispatch>) => {
  dispatch({
    type: SWITCH_ORGANIZATION,
    payload: id
  });
  localStorage.setItem('x-organization-id', id);

  await recalculateDatadisErrors(id);
};

export const updateUserSignInCount =
  (id: string, signInCount: number) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      dispatch({
        type: UPDATE_USER_SIGN_IN_COUNT,
        payload: { id, signInCount }
      });
    } catch (err) {
      console.error('err update user sign in count', err);
      return null;
    }
  };

export const updateOrganizationInvoicingData =
  (id: string, invoicingData: InvoicingDataType) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      dispatch({
        type: UPDATE_ORGANIZATION_INVOICING_DATA,
        payload: { id, invoicingData }
      });
    } catch (err) {
      console.error('err update organization invoicing data', err);
      return null;
    }
  };

export const createOrganizationAndLinkToHoldingAction =
  (id: string, organizationHoldingInfo: CreateOrganizationHoldingInfo, user_id: string) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      // Create organization child and link it
      const res = await apiFetch(
        'post',
        '/matrices/link/parent',
        {
          country: organizationHoldingInfo.country,
          company_name: organizationHoldingInfo.company_name,
          sector: organizationHoldingInfo.sector,
          employee_count_signup: organizationHoldingInfo.employee_count_signup,
          vat: organizationHoldingInfo.vat,
          user_id,
          share: organizationHoldingInfo.share,
          investment_scopes: organizationHoldingInfo.includeScope3 ? [1, 2, 3] : [1, 2],
          tag: organizationHoldingInfo.tag
        },
        {
          'x-organization-id': id
        }
      );
      if (res.status === 200) {
        dispatch({
          type: CREATE_ORGANIZATION_AND_LINK_TO_HOLDING,
          payload: { id, organizationCreated: res.data }
        });
        return (res.data as unknown as Organization).id;
      }
      return res.data;
    } catch (err: any) {
      return err.response.data;
    }
  };

export const updateUserRegister =
  (id: string, registerInfo: RegisterInfo1) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      // Update information from user
      const res = await apiFetch('patch', `/users/${id}`, {
        first_name: registerInfo.first_name,
        last_name: registerInfo.last_name,
        prefix: registerInfo.prefix,
        phone_number: registerInfo.phone_number,
        accepted_comms_marketing: registerInfo.accepted_comms_marketing,
        accepted_terms_privacy: registerInfo.accepted_terms_privacy,
        onboarding_done: true
      });

      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
      return res.data;
    } catch (err: any) {
      dispatch({
        type: AUTH_ERROR
      });
      if (err?.response?.status === 422) {
        return err.response.data.detail[0].msg;
      }
      return null;
    }
  };

export const removeOrganizationFromUser =
  (id: string) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    dispatch({
      type: REMOVE_ORGANIZATION_FROM_USER,
      payload: id
    });
  };

export const switchGrouping = (id: string) => async (dispatch: Dispatch<LoadUserDispatch>) => {
  dispatch({
    type: SWITCH_GROUPING,
    payload: id
  });
};

export const getPendingTasks =
  (user_id: string) => async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      const tasks = await getTasksByUser(user_id);
      dispatch({
        type: GET_PENDING_TASKS,
        payload: tasks
      });
    } catch (err) {
      return null;
    }
  };

export const completeTaskCurrentUser =
  (tasks_filtered: { total: number; total_completed: number; items: IGetBackendTask[] }) =>
  async (dispatch: Dispatch<LoadUserDispatch>) => {
    try {
      dispatch({
        type: GET_PENDING_TASKS,
        payload: tasks_filtered
      });
    } catch (err) {
      return null;
    }
  };

export const setOrganizationLimitOfficialSuppliersBonusAction = (
  organization_id: string,
  limit_official_suppliers_bonus: string
) => {
  return {
    type: SET_ORG_LIMIT_OFFICIAL_SUPPLIERS_BONUS,
    payload: {
      organization_id,
      limit_official_suppliers_bonus
    }
  };
};
