import { useTranslation } from 'react-i18next';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import './styles.scss';

type Props = {
  startDate: Date;
  endDate: Date;
  groupDataMode: GroupDataMode;
};
const FrontPage = ({ startDate, endDate, groupDataMode }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='front-page'>
      <div className='title'>
        <h1 className='headline1-big-font'>{t('report.frontPageTitle')}</h1>
        <h1 className='headline1-big-font-bolder'>{t('report.frontPageTitle2')}</h1>
      </div>
      <div className='content'>
        <p className='subtitle3-font subtitle3-font'>{`${t(
          'report.co2Analysis'
        )} ${startDate.toLocaleDateString()} ${t('report.to')} ${endDate.toLocaleDateString()}`}</p>
        {groupDataMode === GroupDataMode.TOTAL && (
          <p className='subtitle3-font body3-font'>{t('report.consolidationDisclaimer')}</p>
        )}
      </div>
    </div>
  );
};

export default FrontPage;
