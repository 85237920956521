import { useTranslation } from 'react-i18next';
import Dot from '../../../../../ui/dot/Dot';
import { LCACard } from '../LCACard';
import { useLCAMethodology } from '../LCAMethodologyContext/LCAMethodologyContext';
import { formatLCAUnit } from '../../utils/formatLCAUnit';
import { isKey } from '../../../../../../utils/isKey';
import { LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS } from '../../constants';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';

type Props = {
  color: string;
  name: string;
  impact: string;
  handleClick?: () => void;
  disabled?: boolean;
};

export const LCAComponentCard: React.FC<Props> = ({
  color,
  handleClick,
  disabled,
  name,
  impact
}) => {
  const { methodology } = useLCAMethodology();

  const { t } = useLCATranslation();

  const { t: translateUnit } = useTranslation('translation', { keyPrefix: 'units_short' });

  const titleClases = ['m-0 font-body-b1-b'];

  if (!methodology) {
    return null;
  }

  const btnClasses: string[] = ['reset'];

  if (!disabled) {
    btnClasses.push('pointer');
  }

  if (disabled) {
    titleClases.push('text-neutral-gray-20');
  }

  const title = isKey(name, LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS)
    ? t(LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS[name])
    : name;

  return (
    <button className={btnClasses.join(' ')} onClick={handleClick} disabled={disabled}>
      <LCACard.Layout disabled={disabled}>
        <LCACard.Content>
          <Dot
            type='custom'
            color={color}
            style={{ height: '1rem', width: '1rem', margin: '0px' }}
          />
          <div className='flex-col gap-y-2 flex-grow py-1 justify-center'>
            <h4 className={titleClases.join(' ')}>{title}</h4>
          </div>
          <LCACard.TotalImpact
            impact={impact}
            unit={formatLCAUnit(translateUnit(methodology.unit.name), methodology.category)}
            disabled={disabled}
          />
        </LCACard.Content>
      </LCACard.Layout>
    </button>
  );
};
