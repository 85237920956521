import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { switchOrganization } from '../../../../actions/auth';
import { groupByCategory } from '../../../../constants/groupByCategoryFunction';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { Holding, OrgInfo } from '../../../../types/entities/holdings';
import formatNumber from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import Dot from '../../../ui/dot/Dot';
import Select from '../../../ui/formComponents/select/Select';
import LoaderCard from '../../../ui/loaders/loaderCard/LoaderCard';
import Table from '../../../ui/table/Table';
import './styles.scss';

type Props = {
  data: Holding;
  loading: boolean;
  disabled: boolean;
  freeTrial: boolean;
};

function List({ data, loading, disabled, freeTrial }: Props) {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    id: 'all',
    name: t('dashboard.all')
  });

  const dispatch = useDispatch();

  const foundOrgHolding = useSelectedOrganization();

  const parseOrg = (org: OrgInfo) => {
    let scope1 = 0;
    let scope2 = 0;
    let scope3 = 0;
    let totalCo2 = 0;

    const categories: Omit<CategoryData, 'scope'>[] = [];

    org.co2e.forEach((elem) => {
      if (elem.scope === 1) {
        scope1 += elem.total_emissions;
        totalCo2 += filter.id === 'all' || filter.id === '1' ? elem.total_emissions : 0;
      } else if (elem.scope === 2) {
        scope2 += elem.total_emissions;
        totalCo2 += filter.id === 'all' || filter.id === '2' ? elem.total_emissions : 0;
      } else if (elem.scope === 3) {
        scope3 += elem.total_emissions;
        totalCo2 += filter.id === 'all' || filter.id === '3' ? elem.total_emissions : 0;
      }

      const foundEmployeeCategory = categories.find(
        (category) => category.category === 'employees'
      );
      if (
        foundEmployeeCategory &&
        (elem.category === 'employees_in_labore' || elem.category === 'employees_in_itinere')
      ) {
        foundEmployeeCategory.total_emissions += elem.total_emissions;
      } else {
        categories.push({
          category: elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, ''),
          total_emissions: elem.total_emissions
        });
      }
    });
    const orgParsed: Record<string, any> = {
      company: (
        <div className='company'>
          <img src={org.holding ? '/images/icons/holding.svg' : '/images/icons/organization.svg'} />
          <span>{org.org_name}</span>
        </div>
      ),
      co2: (
        <span className='highlight-text-color tag-bigger-font'>
          {formatNumber(numberToDecimal(totalCo2 / 1000))}
        </span>
      ),
      scope1: numberToDecimal(scope1 / 1000),
      scope2: numberToDecimal(scope2 / 1000),
      scope3: numberToDecimal(scope3 / 1000),
      //   debt: (
      //     <span
      //       className='tag-bg-color on-tag-text-color environmental-debt
      // '>
      //       {numberToDecimal(org.remaining / 1000)} t CO₂ eq.
      //     </span>
      //   ),
      // progress: (
      //   <span className='tag-bg-color on-tag-text-color environmental-debt'>{`${org.data_quality}%`}</span>
      // ),
      goToCompany:
        foundOrgHolding && foundOrgHolding.role === 'owner' && !foundOrgHolding.is_group_fund ? (
          <div className='redirect'>
            <a href='' onClick={() => handleChangeOrganization(org.org_id)}>
              <img src='/images/icons/redirect.svg'></img>
            </a>
          </div>
        ) : null,
      id: org.org_id,
      holding: org.holding
    };

    categories.forEach(({ category, total_emissions }) => {
      orgParsed[category] = numberToDecimal(total_emissions / 1000);
    });

    return orgParsed;
  };

  const parentOrgInfo: OrgInfo = {
    org_id: foundOrgHolding?.id ?? '',
    org_name: foundOrgHolding?.company_name ?? '',
    co2e: data.parent_co2e,
    remaining: 0,
    country: foundOrgHolding?.country ?? '',
    parent_org_name: '',
    parent_org_id: '',
    // data_quality: dataQualityPercentage,
    holding: true
  };

  const parsedValues = data.childs_info.map((org: OrgInfo) => {
    return parseOrg(org);
  });

  const orgValues = parsedValues.sort((a, b) => b.co2 - a.co2);

  orgValues.unshift(parseOrg(parentOrgInfo));

  if (loading) {
    return <LoaderCard />;
  }

  const columns = [
    {
      title: t('dashboard.company'),
      dataIndex: 'company',
      key: 'company'
    },

    {
      title: (
        <span className='text-center'>
          <Dot type={'scope1'} />
          {t('dashboard.scope1')}
        </span>
      ),
      dataIndex: 'scope1',
      key: 'scope1',
      render: (value: string) => {
        return <span className='text-center'>{!value ? '0' : value}</span>;
      }
    },
    {
      title: (
        <span className='text-center'>
          <Dot type={'scope2'} />
          {t('dashboard.scope2')}
        </span>
      ),
      dataIndex: 'scope2',
      key: 'scope2',
      render: (value: string) => {
        return <span className='text-center'>{!value ? '0' : value}</span>;
      }
    },
    {
      title: (
        <span className='text-center'>
          <Dot type={'scope3'} />
          {t('dashboard.scope3')}
        </span>
      ),
      dataIndex: 'scope3',
      key: 'scope3',
      render: (value: string) => {
        return <span className='text-center'>{!value ? '0' : value}</span>;
      }
    },
    // {
    //   title: t('dashboard.environmentalDebt'),
    //   dataIndex: 'debt',
    //   key: 'debt'
    // },
    {
      title: 'Total',
      dataIndex: 'co2',
      key: 'co2',
      align: 'right'
      // render: (value: any) => value && console.log(value)
    },
    // {
    //   title: t('dashboard.progress'),
    //   dataIndex: 'progress',
    //   key: 'progress'
    // },
    {
      title: '',
      dataIndex: 'goToCompany',
      key: 'goToCompany'
    }
  ];

  const categoriesColumns: any = [];

  groupByCategory()
    .filter((elem) => elem.category !== 'employees_in_labore')
    .map((elem) => ({
      ...elem,
      category: elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, '')
    }))
    .forEach((elem) =>
      filter.id !== 'all' && elem.scope === parseInt(filter.id)
        ? categoriesColumns.push({
            title: (
              <span className='text-center'>
                {elem.scope === 1 || elem.scope === 2 || elem.scope === 3 ? (
                  <Dot type={`scope${elem.scope}`} />
                ) : null}
                {t(`categories.${elem.category}`)}
              </span>
            ),
            dataIndex: elem.category,
            key: elem.category,
            render: (value: string) => {
              return <span className='text-center'>{!value ? '0' : value}</span>;
            }
          })
        : null
    );

  const columns2 = [
    {
      title: t('dashboard.company'),
      dataIndex: 'company',
      key: 'company'
    },
    ...categoriesColumns,
    {
      title: 'Total',
      dataIndex: 'co2',
      key: 'co2',
      align: 'right'
    },
    {
      title: '',
      dataIndex: 'goToCompany',
      key: 'goToCompany'
    }
  ];

  const handleChangeOrganization = (id: string) => {
    dispatch(switchOrganization(id));

    window.location.reload();
  };

  const onChangeFilter = (value: SelectOptionFormat) => {
    setFilter(value);
  };

  return (
    <div className='card dashboard-card'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboard.impactByOrganization')}
        </h4>

        {freeTrial ? (
          <Button
            lookAndFeel='blocked'
            size='tiny'
            text={t('blockedButton.improvePlan')}
            onClick={() => {
              console.log('');
            }}
          />
        ) : (
          <div className='list-wrapper-filter'>
            <Select
              icon='/images/icons/filter.svg'
              placeholder={'select'}
              height='24px'
              size='small'
              options={[
                {
                  id: 'all',
                  name: t('dashboard.all')
                },
                {
                  id: '1',
                  name: t('dashboard.scope1')
                },
                {
                  id: '2',
                  name: t('dashboard.scope2')
                },
                {
                  id: '3',
                  name: t('dashboard.scope3')
                }
              ]}
              value={filter}
              onChangeValue={onChangeFilter}
            />
          </div>
        )}
      </div>
      <div className='dashboard-card__body dashboard-card__body--list'>
        <div className='list-wrapper'>
          <Table
            columns={filter.id === 'all' ? columns : columns2}
            data={freeTrial ? [] : orgValues}
            loading={false}
            size={'small'}
          />
        </div>
      </div>
    </div>
  );
}

export default List;
