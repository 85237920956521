import { Handle, Position } from 'reactflow';
import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { ScenarioNode } from '../../types/LCAScenarios';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { useGetNodeMaterial } from '../../hooks/useGetNodeMaterial';
import { useParams } from 'react-router-dom';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { useMaterialModalContext } from '../SelectMaterialModal/SelectMaterialModal';

type MaterialNodeProps = {
  data: ScenarioNode['data'];
};

export const MaterialNode: React.FC<MaterialNodeProps> = ({ data }) => {
  const { t } = useLCATranslation();

  const { setNodeId } = useMaterialModalContext();

  const { id: acv_id } = useParams<{ id: string }>();

  const material = useGetNodeMaterial({ node_id: data.id, acv_id: acv_id ?? 'none' });

  const textClass = !material?.material_id ? 'text-gray-dark' : 'text-gray-light';

  return (
    <>
      <LCACard.Layout>
        <div className='flex-col gap-y-4 min-w-48'>
          <LCACard.Content>
            <LCACard.Icon
              icon={NODE_ICONS[NODE_TYPES.material]}
              lookAndFeel='material'
              /*  disabled={!material?.material_id} */
            />
            <div className='flex-col'>
              <span className={`font-body-b2-sb ${textClass}`}>{t('lcaScenarios.material')}</span>
              <span className='font-body-b1-sb'>{material ? data.label : '-'}</span>
            </div>
          </LCACard.Content>
          {Boolean(data.tags?.length) && (
            <div className='flex gap-x-2'>
              {data.tags?.map((tag) => (
                <LCACard.Tag key={data.id + tag} disabled>
                  {tag}
                </LCACard.Tag>
              ))}
            </div>
          )}

          {/* {!material?.material_id && (
            <LCACard.Tag disabled>
              <Icon icon='warning' color='disabled' size='medium' />
              {t('lcaScenarios.noMaterial')}
            </LCACard.Tag>
          )} */}
        </div>
        {/* {!material?.material_id && (
          <Button
            lookAndFeel='primary'
            size='small'
            text={t('lcaScenarios.addMaterial')}
            iconNode={<Icon icon='add' color='white' size='medium' />}
            onClick={() => setNodeId(data.id)}
          />
        )} */}
      </LCACard.Layout>
      <Handle
        type='source'
        position={Position.Right}
        isConnectableStart={data.outputs?.length === 0}
      />
    </>
  );
};
