import { useRef } from 'react';
import CardWrapper from '../cards/common/CardWrapper';
import Table from '../table/Table';
import './styles.scss';

interface Props extends React.ComponentProps<typeof Table> {
  fetchData: () => Promise<void>;
  header?: React.ReactNode;
  firstLoading?: boolean;
  total?: number;
}

function InfiniteList({ fetchData, header, firstLoading, ...rest }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const shouldLoad =
    firstLoading ||
    (rest.loading && typeof rest.total === 'number' && rest.data.length < rest.total);

  const handleScrollEnd = async () => {
    if (!ref.current || rest.loading === true) return;

    const { scrollTop, scrollHeight, clientHeight } = ref.current;

    if (scrollTop + clientHeight + 5 < scrollHeight) return;

    if (typeof rest.total === 'number' && rest.data.length >= rest.total) return;

    await fetchData();
  };

  return (
    <CardWrapper
      className='infinite-scroll-container'
      style={{
        padding: '2rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
      }}>
      <div className='table-header'>{header}</div>
      <div className='infinite-scroll-wrapper' onScroll={handleScrollEnd} ref={ref}>
        <Table {...rest} loading={!!firstLoading} loadingFooter={shouldLoad} />
      </div>
    </CardWrapper>
  );
}

export default InfiniteList;
