import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import numberToDecimal from '../../../../utils/numberToDecimal';
import { datapoints as datapointsEnvironmental } from '../environmental/data/datapointsEnvironmental';
import { datapoints as datapointsGeneralInfo } from '../generalInfo/data/datapointsGeneralInfo';
import { datapoints as datapointsGovernance } from '../governance/data/datapoints';
import { datapoints as datapointsSocial } from '../social/data/datapoints';
const useMockedData = () => {
  // mocked data
  const selectedOrganization = useSelectedOrganization();

  let doubleMaterialityCompleteness = 0;

  let generalInformationCompleted = 0;
  const generalInformationRequirements = 16;
  const generalInformationDatapoints = datapointsGeneralInfo.length;
  let generalInformationCompleteness = 0;

  let environmentalCompleted = datapointsEnvironmental.filter(
    (datapoint) => datapoint.state === 'completed'
  ).length;
  const environmentalRequirements = datapointsEnvironmental.filter(
    (datapoint) => datapoint.relevance === 'mandatory'
  ).length;
  const environmentalDatapoints = datapointsEnvironmental.length;
  let environmentalCompleteness = 0;

  let socialCompleted = datapointsSocial.filter(
    (datapoint) => datapoint.state === 'completed'
  ).length;
  const socialRequirements = datapointsSocial.filter(
    (datapoint) => datapoint.relevance === 'mandatory'
  ).length;
  const socialDatapoints = datapointsSocial.length;
  let socialCompleteness = 0;

  let governanceCompleted = datapointsGovernance.filter(
    (datapoint) => datapoint.state === 'completed'
  ).length;
  const governanceRequirements = datapointsGovernance.filter(
    (datapoint) => datapoint.relevance === 'mandatory'
  ).length;
  const governanceDatapoints = datapointsGovernance.length;
  let governanceCompleteness = 0;

  if (
    selectedOrganization?.company_name === 'Dcycle Demos' ||
    selectedOrganization?.company_name === 'Logística Marta'
  ) {
    doubleMaterialityCompleteness = 20;

    generalInformationCompleted = 0;
    generalInformationCompleteness = numberToDecimal(
      (generalInformationCompleted / generalInformationDatapoints) * 100,
      0
    );

    environmentalCompleted = 54; //mocked data
    environmentalCompleteness = numberToDecimal(
      (environmentalCompleted / environmentalDatapoints) * 100,
      0
    );

    socialCompleted = 103; //mocked data
    socialCompleteness = numberToDecimal((socialCompleted / socialDatapoints) * 100, 0);

    governanceCompleted = 22; //mocked data
    governanceCompleteness = numberToDecimal((governanceCompleted / governanceDatapoints) * 100, 0);
  }

  return {
    doubleMaterialityCompleteness,
    generalInformationCompleted,
    generalInformationRequirements,
    generalInformationDatapoints,
    generalInformationCompleteness,
    environmentalCompleted,
    environmentalRequirements,
    environmentalDatapoints,
    environmentalCompleteness,
    socialCompleted,
    socialRequirements,
    socialDatapoints,
    socialCompleteness,
    governanceCompleted,
    governanceRequirements,
    governanceDatapoints,
    governanceCompleteness
  };
};

export default useMockedData;
