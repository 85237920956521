import { createContext, useContext, useState } from 'react';
import Modal from '../../../../ui/modal/Modal';

type Props = {
  acv_id: string;
};

type MaterialModalContextType = {
  node_id: string | null;
  setNodeId: React.Dispatch<React.SetStateAction<string | null>>;
};

const MaterialModalContext = createContext<MaterialModalContextType | null>(null);

export const useMaterialModalContext = () => {
  const context = useContext(MaterialModalContext);

  if (!context) {
    throw new Error('useMaterialModalContext must be used within a MaterialModalContextProvider');
  }

  return context;
};

const Provider: React.FC = ({ children }) => {
  const [node_id, setNodeId] = useState<string | null>(null);

  return (
    <MaterialModalContext.Provider value={{ node_id, setNodeId }}>
      {children}
    </MaterialModalContext.Provider>
  );
};

export const SelectMaterialModal = ({ acv_id }: Props) => {
  const { node_id, setNodeId } = useMaterialModalContext();

  return (
    <Modal.WithPortal show={Boolean(node_id)} onClose={() => setNodeId(null)}>
      <div></div>
    </Modal.WithPortal>
  );
};

SelectMaterialModal.Root = Provider;
