import { ReactNode } from 'react';
import Card from '../../../../ui/cards/card/Card';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
  children: ReactNode;
};
// Principal Card Component
const CardEsg = ({ onClick, children }: Props) => {
  return (
    <Card listView={false} onClick={onClick}>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
};

// Card Title Component
const Title = ({ title }: { title: string }) => {
  return <span className='headline4-font'>{title}</span>;
};

// Card Description Component
const Description = ({ descriptions }: { descriptions: string[] }) => {
  return (
    <div>
      {descriptions.map((description, index) => (
        <div key={index}>
          <span className='subtitle3-font'>{description}</span>
        </div>
      ))}
    </div>
  );
};

// Card Requirements Component
const Requirements = ({
  requirements,
  datapoints
}: {
  requirements: number;
  datapoints: number;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });

  const requirementsText = `${requirements} ${t('requirements')}`;
  const datapointsText = `${datapoints} ${t('datapoints')}`;

  return <Description descriptions={[requirementsText, datapointsText]} />;
};

// Card Completeness Component
const Completeness = ({ completeness }: { completeness: number }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });

  return (
    <span className='subtitle3-font'>
      <span className='highlight-text-color'>{completeness}%</span> {t('completeness')}
    </span>
  );
};

// Card ESRS Component
const Esrs = ({ esrs }: { esrs: string }) => {
  return <div className='text-tag body3-font tag-bg-text-color tag-space-between'>{esrs}</div>;
};

CardEsg.Title = Title;
CardEsg.Description = Description;
CardEsg.Requirements = Requirements;
CardEsg.Completeness = Completeness;
CardEsg.Esrs = Esrs;

export default CardEsg;
