export const datapoints: Datapoint[] = [
  {
    datapoint_id: 'S1.SBM-3_01',
    datapoint:
      'all_people_in_its_own_workforce_who_can_be_materially_impacted_by_undertaking_are_included_in_scope_of_disclosure_under_esrs_2',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_02',
    datapoint:
      'description_of_types_of_employees_and_non-employees_in_its_own_workforce_subject_to_material_impacts_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_03',
    datapoint: 'material_negative_impacts_occurrence_(own_workforce)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_04',
    datapoint:
      'description_of_activities_that_result_in_positive_impacts_and_types_of_employees_and_non-employees_in_its_own_workforce_that_are_positively_affected_or_could_be_positively_affected_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_05',
    datapoint:
      'description_of_material_risks_and_opportunities_arising_from_impacts_and_dependencies_on_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_06',
    datapoint:
      'description_of_material_impacts_on_workers_that_may_arise_from_transition_plans_for_reducing_negative_impacts_on_environment_and_achieving_greener_and_climate-neutral_operations_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_07',
    datapoint:
      'information_about_type_of_operations_at_significant_risk_of_incidents_of_forced_labour_or_compulsory_labour_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_08',
    datapoint:
      'information_about_countries_or_geographic_areas_with_operations_considered_at_significant_risk_of_incidents_of_forced_labour_or_compulsory_labour_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_09',
    datapoint:
      'information_about_type_of_operations_at_significant_risk_of_incidents_of_child_labour_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_10',
    datapoint:
      'information_about_countries_or_geographic_areas_with_operations_considered_at_significant_risk_of_incidents_of_child_labour_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_11',
    datapoint:
      'disclosure_of_whether_and_how_understanding_of_people_in_its_own_workforce_with_particular_characteristics,_working_in_particular_contexts,_or_undertaking_particular_activities_may_be_at_greater_risk_of_harm_has_been_developed_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1.SBM-3_12',
    datapoint:
      'disclosure_of_which_of_material_risks_and_opportunities_arising_from_impacts_and_dependencies_on_people_in_its_own_workforce__relate_to_specific_groups_of_people',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_01',
    datapoint:
      'policies_to_manage_material_impacts,_risks_and_opportunities_related_to_own_workforce,_including_for_specific_groups_within_workforce_or_all_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_02',
    datapoint:
      'disclosure_of_explanations_of_significant_changes_to_policies_adopted_during_reporting_year_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_03',
    datapoint: 'description_of_relevant_human_rights_policy_commitments_relevant_to_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_04',
    datapoint:
      'disclosure_of_general_approach_in_relation_to_respect_for_human_rights_including_labour_rights,_of_people_in_its_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_05',
    datapoint:
      'disclosure_of_general_approach_in_relation_to_engagement_with_people_in_its_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_06',
    datapoint:
      'disclosure_of_general_approach_in_relation_to_measures_to_provide_and_(or)_enable_remedy_for_human_rights_impacts_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_07',
    datapoint:
      'disclosure_of_whether_and_how_policies_are_aligned_with_relevant_internationally_recognised_instruments_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_08',
    datapoint:
      'policies_explicitly_address_trafficking_in_human_beings,_forced_labour_or_compulsory_labour_and_child_labour',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_09',
    datapoint: 'workplace_accident_prevention_policy_or_management_system_is_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_10',
    datapoint: 'specific_policies_aimed_at_elimination_of_discrimination_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_11',
    datapoint: 'grounds_for_discrimination_are_specifically_covered_in_policy',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_12',
    datapoint:
      'disclosure_of_specific_policy_commitments_related_to_inclusion_and_(or)_positive_action_for_people_from_groups_at_particular_risk_of_vulnerability_in_own_workforce_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_13',
    datapoint:
      'disclosure_of_whether_and_how_policies_are_implemented_through_specific_procedures_to_ensure_discrimination_is_prevented,_mitigated_and_acted_upon_once_detected,_as_well_as_to_advance_diversity_and_inclusion_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_14',
    datapoint:
      'disclosure_on_an_illustration_of_the_types_of_communication_of_its_policies_to_those_individuals,_group_of_individuals_or_entities_for_whom_they_are_relevant',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_15',
    datapoint:
      'policies_and_procedures_which_make_qualifications,_skills_and_experience_the_basis_for_the_recruitment,_placement,_training_and_advancement_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_16',
    datapoint:
      'has_assigned_responsibility_at_top_management_level_for_equal_treatment_and_opportunities_in_employment,_issue_clear_company-wide_policies_and_procedures_to_guide_equal_employment_practices,_and_link_advancement_to_desired_performance_in_this_area',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_17',
    datapoint: 'staff_training_on_non-discrimination_policies_and_practices_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_18',
    datapoint:
      'adjustments_to_the_physical_environment_to_ensure_health_and_safety_for_workers,_customers_and_other_visitors_with_disabilities_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_19',
    datapoint:
      'has_evaluated_whether_a_there_is_a_risk_that_job_requirements_have_been_defined_in_a_way_that_would_systematically_disadvantage_certain_groups',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_20',
    datapoint:
      'keeping_an_up-to-date_records_on_recruitment,_training_and_promotion_that_provide_a_transparent_view_of_opportunities_for_employees_and_their_progression',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_21',
    datapoint:
      'has_put_in_place_grievance_procedures_to_address_complaints,_handle_appeals_and_provide_recourse_for_employees_when_discrimination_is_identified,_and_is_alert_to_formal_structures_and_informal_cultural_issues_that_can_prevent_employees_from_raising_concerns_and_grievances',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-1_22',
    datapoint: 'has_programs_to_promote_access_to_skills_development._',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_01',
    datapoint:
      'disclosure_of_whether_and_how_perspectives_of_own_workforce__inform_decisions_or_activities_aimed_at_managing_actual_and_potential__impacts_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_02',
    datapoint: 'engagement_occurs_with_own_workforce__or_their_representatives',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_03',
    datapoint:
      'disclosure_of_stage_at_which_engagement_occurs,_type_of_engagement_and_frequency_of_engagement_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_04',
    datapoint:
      'disclosure_of_function_and_most_senior_role_within_undertaking_that_has_operational_responsibility_for_ensuring_that_engagement_happens_and_that_results_inform_undertakingâ€™s_approach_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_05',
    datapoint:
      'disclosure_of_global_framework_agreement_or_other_agreements_related_to_respect_of_human_rights_of_workers_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_06',
    datapoint: 'disclosure_of_how_effectiveness_of_engagement_with_its_own_workforce__is_assessed_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_07',
    datapoint:
      'disclosure_of_steps_taken_to_gain_insight_into_perspectives_of_people_in_its_own_workforce_that_may_be_particularly_vulnerable_to_impacts_and_(or)_marginalised_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_08',
    datapoint:
      'statement_in_case_the_undertaking_has_not_adopted_a_general_process_to_engage_with_its_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_09',
    datapoint:
      'disclosure_of_timeframe_for_adoption_of_general_process_to_engage_with_its_own_workforce_in_case_the_undertaking_has_not_adopted_a_general_process_for_engagement',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_10',
    datapoint:
      'disclosure_of_how_undertaking_engages_with_at-risk_or_persons_in_vulnerable_situations',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_11',
    datapoint:
      'disclosure_of_how_potential_barriers_to_engagement_with_people_in_its_workforce_are_taken_into_account_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_12',
    datapoint:
      'disclosure_of_how_people_in_its_workforce_are_provided_with_information_that_is_understandable_and_accessible_through_appropriate_communication_channels_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_13',
    datapoint:
      'disclosure_of_any_conflicting_interests_that_have_arisen_among_different_workers_and_how_these_conflicting_interests_have_been_resolved_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_14',
    datapoint:
      'disclosure_of_how_undertaking_seeks_to_respect_human_rights_of_all_stakeholders_engaged_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-2_15',
    datapoint:
      'information_about_effectiveness_of_processes_for_engaging_with_its_own_workforce_from_previous_reporting_periods_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_01',
    datapoint:
      'disclosure_of_general_approach_to_and_processes_for_providing_or_contributing_to_remedy_where_undertaking_has_caused_or_contributed_to_a_material_negative_impact_on_people_in_its_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_02',
    datapoint:
      'disclosure_of_specific_channels_in_place_for_its_own_workforce_to_raise_concerns_or_needs_directly_with_undertaking_and_have_them_addressed_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_03',
    datapoint: 'third-party_mechanisms_are_accessible_to_all_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_04',
    datapoint:
      "disclosure_of_whether_and_how_own_workforce_and_their_workers'_representatives_are_able_to_access_channels_at_level_of_undertaking_they_are_employed_by_or_contracted_to_work_for_",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_05',
    datapoint: 'grievance_or_complaints_handling_mechanisms_related_to_employee_matters_exist',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_06',
    datapoint:
      'disclosure_of_processes_through_which_undertaking_supports_or_requires_availability_of_channels_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_07',
    datapoint:
      'disclosure_of_how_issues_raised_and_addressed_are_tracked_and_monitored_and_how_effectiveness_of_channels_is_ensured_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_08',
    datapoint:
      'disclosure_of_whether_and_how_it_is_assessed_that_its_own_workforce_is_aware_of_and_trust_structures_or_processes_as_way_to_raise_their_concerns_or_needs_and_have_them_addressed_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_09',
    datapoint:
      'policies_regarding_protection_against_retaliation_for_individuals_that_use_channels_to_raise_concerns_or_needs_are_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_10',
    datapoint: 'statement_in_case_the_undertaking_has_not_adopted_a_channel_for_raising_concerns_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-3_11',
    datapoint: 'disclosure_of_timeframe_for_channel_for_raising_concerns_to_be_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_01',
    datapoint:
      'description_of_action_taken,_planned_or_underway_to_prevent_or_mitigate_negative_impacts_on_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_02',
    datapoint:
      'disclosure_on_whether_and_how_action_has_been_taken_to_provide_or_enable_remedy_in_relation_to_actual_material_impact',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_03',
    datapoint:
      'description_of_additional_initiatives_or_actions_with_primary_purpose_of_delivering_positive_impacts_for_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_04',
    datapoint:
      'description_of_how_effectiveness_of_actions_and_initiatives_in_delivering_outcomes_for_own_workforce_is_tracked_and_assessed_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_05',
    datapoint:
      'description_of_process_through_which_it_identifies_what_action_is_needed_and_appropriate_in_response_to_particular_actual_or_potential_negative_impact_on_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_06',
    datapoint:
      'description_of_what_action_is_planned_or_underway_to_mitigate_material_risks_arising_from_impacts_and_dependencies_on_own_workforce__and_how_effectiveness_is_tracked_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_07',
    datapoint:
      'description_of_what_action_is_planned_or_underway_to_pursue_material_opportunities_in_relation_to_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_08',
    datapoint:
      'disclosure_of_whether_and_how_it_is_ensured_that_own_practices_do_not_cause_or_contribute_to_material_negative_impacts_on_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_09',
    datapoint: 'disclosure_of_resources_are_allocated_to_the_management_of_material_impacts',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_10',
    datapoint:
      'disclosure_of_general_and_specific_approaches_to_addressing_material_negative_impacts_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_11',
    datapoint:
      'disclosure_of_initiatives_aimed_at_contributing_to_additional_material_positive_impacts_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_12',
    datapoint:
      'disclosure_of_how_far_undertaking_has_progressed_in_efforts_during_reporting_period_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_13',
    datapoint: 'disclosure_of_aims_for_continued_improvement_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_14',
    datapoint:
      'disclosure_of_whether_and_how_undertaking_seeks_to_use_leverage_with_relevant_business_relationships_to_manage_material_negative_impacts_affecting_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_15',
    datapoint:
      'disclosure_of_how_the_initiative,_and_its_own_involvement,_is_aiming_to_address_the_material_impact_concerned',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_16',
    datapoint:
      "disclosure_of_whether_and_how_workers_and_workers'_representatives_play_role_in_decisions_regarding_design_and_implementation_of_programmes_or_processes_whose_primary_aim_is_to_deliver_positive_impacts_for_workers_",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_17',
    datapoint:
      'information_about_intended_or_achieved_positive_outcomes_of_programmes_or_processes_for_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_18',
    datapoint:
      'initiatives_or_processes_whose_primary_aim_is_to_deliver_positive_impacts_for_own_workforce__are_designed_also_to_support_achievement_of_one_or_more_of_sustainable_development_goals',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_19',
    datapoint:
      'information_about_measures_taken_to_mitigate_negative_impacts_on_workers_that_arise_from_transition_to_greener,_climate-neutral_economy_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-4_20',
    datapoint:
      'description_of_internal_functions_that_are_involved_in_managing_impacts_and_types_of_action_taken_by_internal_functions_to_address_negative_and_advance_positive_impacts_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-5_01',
    datapoint:
      "disclosure_of_whether_and_how_own_workforce_or_workforce'_representatives_were_engaged_directly_in_setting_targets_",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-5_02',
    datapoint:
      "disclosure_of_whether_and_how_own_workforce_or_workforce'_representatives_were_engaged_directly_in_tracking_performance_against_targets_",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-5_03',
    datapoint:
      "disclosure_of_whether_and_how_own_workforce_or_workforce'_representatives_were_engaged_directly_in_identifying_lessons_or_improvements_as_result_of_undertakings_performance_",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-5_04',
    datapoint:
      'disclosure_of_intended_outcomes_to_be_achieved_in_lives_of_people_in_its_own_workforce__',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-5_05',
    datapoint:
      'information_about_stability_over_time_of_target_in_terms_of_definitions_and_methodologies_to_enable_comparability_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-5_06',
    datapoint: 'disclosure_of_references_to_standards_or_commitments_which_targets_are_based_on',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-6_01',
    datapoint: "characteristics_of_undertaking's_employees_-_number_of_employees_by_gender_[table]",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_02',
    datapoint: 'number_of_employees_(head_count)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_03',
    datapoint: 'average_number_of_employees_(head_count)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_04',
    datapoint:
      "characteristics_of_undertaking's_employees_-_number_of_employees_in_countries_with_50_or_more_employees_representing_at_least_10%_of_total_number_of_employees_[table]",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_05',
    datapoint:
      'number_of_employees_in_countries_with_50_or_more_employees_representing_at_least_10%_of_total_number_of_employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_06',
    datapoint:
      'average_number_of_employees_in_countries_with_50_or_more_employees_representing_at_least_10%_of_total_number_of_employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_07',
    datapoint:
      "characteristics_of_undertaking's_employees_-_information_on_employees_by_contract_type_and_gender__[table]",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_08',
    datapoint:
      "characteristics_of_undertaking's_employees_-_information_on_employees_by_region_[table]",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_09',
    datapoint: 'number_of_employees_(head_count_or_full-time_equivalent)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_10',
    datapoint: 'average_number_of_employees_(head_count_or_full-time_equivalent)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_11',
    datapoint: 'number_of_employee_who_have_left_undertaking_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_12',
    datapoint: 'percentage_of_employee_turnover',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_13',
    datapoint: 'description_of_methodologies_and_assumptions_used_to_compile_data_(employees)_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-6_14',
    datapoint: 'employees_numbers_are_reported_in_head_count_or_full-time_equivalent',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-6_15',
    datapoint:
      'employees_numbers_are_reported_at_end_of_reporting_period/average/other_methodology',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-6_16',
    datapoint: 'disclosure_of_contextual_information_necessary_to_understand_data_(employees)_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-6_17',
    datapoint:
      'disclosure_of_cross-reference_of_information_reported_under_paragragph_50_(a)_to_most_representative_number_in_financial_statements_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-6_18',
    datapoint: 'further_detailed_breakdown_by_gender_and_by_region_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_19',
    datapoint: 'number_of_full-time_employees_by_head_count_or_full_time_equivalent',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-6_20',
    datapoint: 'number_of_part-time_employees_by_head_count_or_full_time_equivalent',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-7_01',
    datapoint: 'number_of_non-employees_in_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-7_02',
    datapoint: 'number_of_non-employees_in_own_workforce_-_self-employed_people',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-7_03',
    datapoint:
      'number_of_non-employees_in_own_workforce_-_people_provided_by_undertakings_primarily_engaged_in_employment_activities',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-7_04',
    datapoint: 'undertaking_does_not_have_non-employees_in_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-7_05',
    datapoint:
      'disclosure_of_the_most_common_types_of_non-employees_(for_example,_self-employed_people,_people_provided_by_undertakings_primarily_engaged_in_employment_activities,_and_other_types_relevant_to_the_undertaking),_their_relationship_with_the_undertaking,_and_the_type_of_work_that_they_perform.',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-7_06',
    datapoint: 'description_of_methodologies_and_assumptions_used_to_compile_data_(non-employees)_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-7_07',
    datapoint: 'non-employees_numbers_are_reported_in_head_count/full_time_equivalent',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-7_08',
    datapoint:
      'non-employees_numbers_are_reported_at_end_of_reporting_period/average/other_methodology',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-7_09',
    datapoint:
      'disclosure_of_contextual_information_necessary_to_understand_data_(non-employee_workers)_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-7_10',
    datapoint: 'description_of_basis_of_preparation_of_non-employees_estimated_number_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-8_01',
    datapoint: 'percentage_of_total_employees_covered_by_collective_bargaining_agreements',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-8_02',
    datapoint:
      'percentage_of_own_employees_covered_by_collective_bargaining_agreements_are_within_coverage_rate_by_country_with_significant_employment_(in_the_eea)',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-8_03',
    datapoint:
      'percentage_of_own_employees_covered_by_collective_bargaining_agreements_(outside_eea)_by_region',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-8_04',
    datapoint:
      'working_conditions_and_terms_of_employment_for_employees_not_covered_by_collective_bargaining_agreements_are_determined_based_on_collective_bargaining_agreements_that_cover_other_employees_or_based_on_collective_bargaining_agreements_from_other_undertakings',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-8_05',
    datapoint:
      'description_of_extent_to_which_working_conditions_and_terms_of_employment_of_non-employees_in_own_workforce_are_determined_or_influenced_by_collective_bargaining_agreements_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-8_06',
    datapoint:
      "percentage_of_employees_in_country_country_with_significant_employment_(in_the_eea)_covered_by_workers'_representatives",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-8_07',
    datapoint:
      'disclosure_of_existence_of_any_agreement_with_employees_for_representation_by_european_works_council_(ewc),_societas_europaea_(se)_works_council,_or_societas_cooperativa_europaea_(sce)_works_council_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-8_08',
    datapoint:
      'own_workforce_in_region_(non-eea)_covered_by_collective_bargaining_and_social_dialogue_agreements_by_coverage_rate_and_by_region',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-9_01',
    datapoint: 'gender_distribution_in_number_of_employees_(head_count)_at_top_management_level',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-9_02',
    datapoint: 'gender_distribution_in_percentage_of_employees_at_top_management_level',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-9_03',
    datapoint: 'distribution_of_employees_(head_count)_under_30_years_old',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-9_04',
    datapoint: 'distribution_of_employees_(head_count)_between_30_and_50_years_old',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-9_05',
    datapoint: 'distribution_of_employees_(head_count)_over_50_years_old',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-9_06',
    datapoint: 'disclosure_of_own_definition_of_top_management_used_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-10_01',
    datapoint: 'all_employees_are_paid_adequate_wage,_in_line_with_applicable_benchmarks',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-10_02',
    datapoint:
      'countries_where_employees_earn_below_the_applicable_adequate_wage_benchmark_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-10_03',
    datapoint: 'percentage_of__employees_paid_below_the_applicable_adequate_wage_benchmark',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-10_04',
    datapoint: 'percentage_of_non-employees_paid_below_adequate_wage',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-11_01',
    datapoint:
      'all_employees_in_own_workforce_are_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_sickness',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_02',
    datapoint:
      'all_employees_in_own_workforce_are_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_unemployment_starting_from_when_own_worker_is_working_for_undertaking',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_03',
    datapoint:
      'all_employees_in_own_workforce_are_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_employment_injury_and_acquired_disability',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_04',
    datapoint:
      'all_employees_in_own_workforce_are_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_parental_leave',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_05',
    datapoint:
      'all_employees_in_own_workforce_are_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_retirement',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_06',
    datapoint:
      'social_protection_employees_by_country_[table]_by_types_of_events_and_type_of_employees_[including_non_employees]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-11_07',
    datapoint:
      'disclosure_of_types_of_employees_who_are_not_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_sickness_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_08',
    datapoint:
      'disclosure_of_types_of_employees_who_are_not_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_unemployment_starting_from_when_own_worker_is_working_for_undertaking_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_09',
    datapoint:
      'disclosure_of_types_of_employees_who_are_not_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_employment_injury_and_acquired_disability_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_10',
    datapoint:
      'disclosure_of_types_of_employees_who_are_not_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_maternity_leave_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-11_11',
    datapoint:
      'disclosure_of_types_of_employees_who_are_not_covered_by_social_protection,_through_public_programs_or_through_benefits_offered,_against_loss_of_income_due_to_retirement_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-12_01',
    datapoint:
      'percentage_of_persons_with_disabilities_amongst_employees,_subject_to_legal_restrictions_on_collection_of_data',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-12_02',
    datapoint:
      'percentage_of_employees_with_disabilities_in_own_workforce_breakdown_by_gender_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-12_03',
    datapoint:
      'disclosure_of_contextual_information_necessary_to_understand_data_and_how_data_has_been_compiled_(persons_with_disabilities))_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-13_01',
    datapoint: 'training_and_skills_development_indicators_gender_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-13_02',
    datapoint:
      'percentage_of_employees_that_participated_in_regular_performance_and_career_development_reviews',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-13_03',
    datapoint: 'average_number_of_training_hours__by_gender_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-13_04',
    datapoint: 'average_number_of_training_hours_per_person_for_employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-13_05',
    datapoint:
      'percentage_of_employees_that_participated_in_regular_performance_and_career_development_reviews_by_employee_category_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-13_06',
    datapoint:
      'average_number_of_employees_that_participated_in_regular_performance_and_career_development_reviews_by_employee_category',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-13_07',
    datapoint:
      'percentage_of_non-employees_that_participated_in_regular_performance_and_career_development_reviews',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_01',
    datapoint:
      'percentage_of_people_in_its_own_workforce_who_are_covered_by_health_and_safety_management_system_based_on_legal_requirements_and_(or)_recognised_standards_or_guidelines',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_02',
    datapoint:
      'number_of_fatalities_in_own_workforce_as_result_of_work-related_injuries_and_work-related_ill_health',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_03',
    datapoint:
      "number_of_fatalities_as_result_of_work-related_injuries_and_work-related_ill_health_of_other_workers_working_on_undertaking's_sites",
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_04',
    datapoint: 'number_of_recordable_work-related_accidents_for_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_05',
    datapoint: 'rate_of_recordable_work-related_accidents_for_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_06',
    datapoint: 'number_of_cases_of_recordable_work-related_ill_health_of_employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_07',
    datapoint:
      'number_of_days_lost_to_work-related_injuries_and_fatalities_from_work-related_accidents,_work-related_ill_health_and_fatalities_from_ill_health_related_to_employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_08',
    datapoint: 'number_of_cases_of_recordable_work-related_ill_health_of_non-employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_09',
    datapoint:
      'number_of_days_lost_to_work-related_injuries_and_fatalities_from_work-related_accidents,_work-related_ill_health_and_fatalities_from_ill_health_realted_to_non-employees',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_10',
    datapoint:
      'percentage_of_own_workforce_who_are_covered_by_health_and_safety_management_system_based_on_legal_requirements_and_(or)_recognised_standards_or_guidelines_and_which_has_been_internally_audited_and_(or)_audited_or_certified_by_external_party',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-14_11',
    datapoint:
      'description_of_underlying_standards_for_internal_audit_or_external_certification_of_health_and_safety_management_system_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-14_12',
    datapoint:
      'number_of_cases_of_recordable_work-related_ill_health_detected_among_former_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-15_01',
    datapoint: 'percentage_of_employees_entitled_to_take_family-related_leave',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-15_02',
    datapoint: 'percentage_of_entitled_employees_that_took_family-related_leave',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-15_03',
    datapoint: 'percentage_of_entitled_employees_that_took_family-related_leave_by_gender_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-15_04',
    datapoint:
      'all_employees_are_entitled_to_family-related_leaves_through_social_policy_and_(or)_collective_bargaining_agreements',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-16_01',
    datapoint: 'gender_pay_gap',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-16_02',
    datapoint: 'annual_total_remuneration_ratio',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-16_03',
    datapoint:
      'disclosure_of_contextual_information_necessary_to_understand_data,_how_data_has_been_compiled_and_other_changes_to_underlying_data_that_are_to_be_considered_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-16_04',
    datapoint: 'gender_pay_gap_breakdown_by_employee_category_and/or_country/segment_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-16_05',
    datapoint:
      'gender_pay_gap_breakdown_by_employee_category_and_ordinary_basic_salary_and_complementary/variable_components',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-16_06',
    datapoint: 'remuneration_ratio_adjusted_for_purchasing_power_differences_between_countries',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-16_07',
    datapoint:
      'description_of_methodology_used_for_calculation_of_remuneration_ratio_adjusted_for_purchasing_power_differences_between_countries_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-17_01',
    datapoint: 'number_of_incidents_of_discrimination_[table]',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_02',
    datapoint: 'number_of_incidents_of_discrimination',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_03',
    datapoint:
      'number_of_complaints_filed_through_channels_for_people_in_own_workforce_to_raise_concerns',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_04',
    datapoint:
      'number_of_complaints_filed_to_national_contact_points_for_oecd_multinational_enterprises',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_05',
    datapoint:
      'amount_of_fines,_penalties,_and_compensation_for_damages_as_result_of_incidents_of_discrimination,_including_harassment_and_complaints_filed',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_06',
    datapoint:
      'information_about_reconciliation_of_fines,_penalties,_and_compensation_for_damages_as_result_of_violations_regarding_swork-related_discrimination_and_harassment__with_most_relevant_amount_presented_in_financial_statements_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-17_07',
    datapoint:
      'disclosure_of_contextual_information_necessary_to_understand_data_and_how_data_has_been_compiled_(work-related_grievances,_incidents_and_complaints_related_to_social_and_human_rights_matters)_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-17_08',
    datapoint: 'number_of_severe_human_rights_issues_and_incidents_connected_to_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_09',
    datapoint:
      'number_of_severe_human_rights_issues_and_incidents_connected_to_own_workforce_that_are_cases_of_non_respect_of_un_guiding_principles_and_oecd_guidelines_for_multinational_enterprises',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_10',
    datapoint:
      'no_severe_human_rights_issues_and_incidents_connected_to_own_workforce_have_occurred',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-17_11',
    datapoint:
      'amount_of_fines,_penalties,_and_compensation_for_severe_human_rights_issues_and_incidents_connected_to_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'S1-17_12',
    datapoint:
      'information_about_reconciliation_of_amount_of_fines,_penalties,_and_compensation_for_severe_human_rights_issues_and_incidents_connected_to_own_workforce_with_most_relevant_amount_presented_in_financial_statements_',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-17_13',
    datapoint: 'disclosure_of_the_status_of_incidents_and/or_complaints_and_actions_taken',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'S1-17_14',
    datapoint:
      'number_of_severe_human_rights_cases_where_undertaking_played_role_securing_remedy_for_those_affected',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  }
];
