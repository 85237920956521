import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/userContext';
import apiFetch from '../../../utils/apiFetch';

type SurveyCondition = {
  id: string;
  condition: boolean;
};

const useSurveys = () => {
  const user = useContext(UserContext);
  const flags = useFlags();

  const [formIds, setFormIds] = useState<string[]>([]);

  const surveyConditions: SurveyCondition[] = [
    {
      id: 'DNk6FrwT',
      condition:
        (user?.sign_in_count &&
          user.sign_in_count >= 3 &&
          process.env.REACT_APP_PARTNER === 'dcycle') ||
        false
    }
  ];

  const fetchForms = async () => {
    try {
      const response = await apiFetch(
        'GET',
        `/surveys/forms?email=${user?.email}`,
        {},
        {
          x_organization_id: user?.selectedOrganization
        }
      );
      setFormIds(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (flags?.surveys) fetchForms();
  }, [flags?.surveys]);

  const emptySurveys = () => setFormIds([]);

  const formIdsFiltered = formIds.filter((id) => {
    const foundCondition = surveyConditions.find((condition) => condition.id === id);
    return foundCondition ? foundCondition.condition : true;
  });

  return { formIds: formIdsFiltered, emptySurveys };
};

export default useSurveys;
