import { useTranslation } from 'react-i18next';
import { createRandomDataBarChart } from '../../../../mocks/dashboardChart';
import { scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import BarChartScroll from '../../../ui/charts/BarChartScroll';
import LoaderCard from '../../../ui/loaders/loaderCard/LoaderCard';
import ScopeTag from '../../../ui/scopeTag/ScopeTag';

import { joinDataEmployee } from '../utils';
import './styles.scss';

type Props = {
  data: CategoryData[];
  disabled: boolean;
  loading: boolean;
  handleSwitchIsHolding?: () => void;
  freeTrial: boolean;
};

function ScopeEnvironmentImpact({ data, disabled, loading, freeTrial }: Props) {
  const { t, i18n } = useTranslation();

  const noColor = 'rgba(0, 0, 0, 0)';

  const chooseScopeColor = (scope: number) => {
    const scope1Color = scope1_color;
    const scope2Color = scope2_color;
    const scope3Color = scope3_color;

    switch (scope) {
      case 1:
        return scope1Color;
      case 2:
        return scope2Color;
      case 3:
        return scope3Color;
      default:
        return '';
    }
  };

  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  if (!disabled) {
    if (data && data.length > 0) {
      data.forEach((value) => {
        if (value.scope === 1) {
          scope1 += value.total_emissions;
        }
        if (value.scope === 2) {
          scope2 += value.total_emissions;
        }
        if (value.scope === 3) {
          scope3 += value.total_emissions;
        }
      });
    }

    scope1 = numberToDecimal(scope1 / 1000);
    scope2 = numberToDecimal(scope2 / 1000);
    scope3 = numberToDecimal(scope3 / 1000);
  }

  const joinedData = joinDataEmployee(data);

  const joinedSortedData = joinedData.sort((a, b) =>
    a.total_emissions > b.total_emissions ? -1 : b.total_emissions > a.total_emissions ? 1 : 0
  );

  const dataChart = [
    joinedSortedData.map((elem: CategoryData) => {
      return {
        label: t(`categories.${elem.category}`),
        value: numberToDecimal(elem.total_emissions),
        color: numberToDecimal(elem.total_emissions) ? chooseScopeColor(elem.scope) : noColor
      };
    })
  ];

  if (loading) {
    return <LoaderCard />;
  }

  return (
    <div className='dashboard-card card small-device-flex' id='step4'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboard.environmentImpactReach')}
        </h4>
        {freeTrial ? (
          <Button
            lookAndFeel='blocked'
            size='tiny'
            text={t('blockedButton.improvePlan')}
            onClick={() => {
              // console.log('');
            }}
          />
        ) : null}
      </div>
      <div className='dashboard-card__body'>
        <div className='reach-environment-impact-tag--company small-device-flex'>
          <ScopeTag scope={1} value={scope1} noIcons shortText />
          <ScopeTag scope={2} value={scope2} noIcons shortText />
          <ScopeTag scope={3} value={scope3} noIcons shortText />
        </div>

        <BarChartScroll
          chartData={disabled ? createRandomDataBarChart(i18n.resolvedLanguage) : dataChart}
          disabled={disabled}
          totalCo2={numberToDecimal(scope1 + scope2 + scope3)}
        />
      </div>
    </div>
  );
}

export default ScopeEnvironmentImpact;
