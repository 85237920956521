import { useTranslation } from 'react-i18next';
import { LogisticReportFront } from '../../../../types/entities/logistics';
import formatNumber from '../../../../utils/formatNumber';

const useParseTOC = (tocData: LogisticReportFront['emissionFactorsUsed']['logisticsTocs']) => {
  const { t } = useTranslation();
  const tocDataParsed: string[][] = tocData.map((toc) => [
    t(`logistics.tocs.${toc.tocName}`),
    `${formatNumber(toc.wtwEfSubcontracted.value)} ${toc.wtwEfSubcontracted.units}`,
    `${formatNumber(toc.defaultLoad.value)} ${toc.defaultLoad.units}`
  ]);

  return tocDataParsed;
};

export default useParseTOC;
