import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { INTENSITY_METRIC_ARTICLE, SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import usePaginatedList from '../../../customHooks/paginatedList';
import useDebounceValue from '../../../customHooks/useDebounceValue';
import { InputSize } from '../../../types/utilsEnums/input';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import CardList from '../../ui/cardList/CardListRefactored';
import CardsFilter from '../../ui/filter/CardsFilter';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import HelpCTA from '../../ui/helpCTA/HelpCTA';
import LoaderCard from '../../ui/loaders/loaderCard/LoaderCard';
import Modal from '../../ui/modal/Modal';
import CreateIntensityMetricGroup from './createIntensityMetricGroup/CreateIntensityMetricGroup';
import DeleteIntensityMetricGroup from './deleteIntensityMetricGroup/DeleteIntensityMetricGroup';
import EditIntensityMetricGroup from './editIntensityMetricGroup/EditIntensityMetricGroup';
import './styles.scss';
import Card from '../../ui/cards/card/Card';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../ui/icon/Icon';
import { IFilter, getUrl } from '../../../utils/url';
import { FilterText } from '../../layout/NewFilters/FilterText';
import { Filters } from '../../layout/NewFilters/Filters';

const IntensityMetricsGroups = () => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [showCreateIntensityMetricGroup, setShowCreateIntensityMetricGroup] = useState(false);
  const [intensityMetricGroupToEdit, setIntensityMetricGroupToEdit] =
    useState<IntensityMetricGroup>();

  const [intensityMetricGroupToDelete, setIntensityMetricGroupToDelete] = useState('');

  const [loading, setLoading] = useState(false);

  const [listView, setListView] = useState(false);

  const [filters, setFilters] = useState<IFilter[]>([]);

  const BASE_URL = '/intensity_metric_groups';

  const [url, setUrl] = useState(BASE_URL);

  const size = SIZE_PAGINATION_BIG;

  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values: intensityMetricsGroups,
    setValues: setIntensityMetricsGroups,
    total
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization
  });

  useEffect(() => {
    const urlParsed = getUrl(BASE_URL, {
      filters,
      sorters: []
      // queryParams: [{ key: 'search', value: searchValue?.toString() }]
    });

    setUrl(urlParsed);
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (!url) return;
    fetchData();
  }, [url]);

  useEffect(() => {
    if (searchParams.get('open') === 'create') {
      handleShowCreateIntensityMetricGroup();
      setSearchParams('open', undefined);
    }
  }, [searchParams.get('open')]);

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const handleShowCreateIntensityMetricGroup = () => {
    setShowCreateIntensityMetricGroup(true);
  };

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.CUSTOM_INTENSITY_METRICS}/${id}`);
  };

  const handleEditIntensityMetricGroup = (id: string) => {
    const intensityMetricGroup = intensityMetricsGroups.find((intensityMetricGroup) => {
      return intensityMetricGroup.id === id;
    });
    if (intensityMetricGroup) {
      setIntensityMetricGroupToEdit(intensityMetricGroup);
    }
  };

  const handleCloseModal = () => {
    setShowCreateIntensityMetricGroup(false);
    setIntensityMetricGroupToEdit(undefined);
    setIntensityMetricGroupToDelete('');
  };

  const addIntensityMetricGroup = (intensityMetricGroup: IntensityMetricGroup) => {
    setIntensityMetricsGroups([...intensityMetricsGroups, intensityMetricGroup]);
    handleCloseModal();
    dispatch(setNotification(t('notification.createIntensityMetricGroup')));
  };

  const editIntensityMetricGroup = (intensityMetricGroup: IntensityMetricGroup) => {
    const newIntensityMetricGroups = intensityMetricsGroups.map((intensityMetricGroupItem) => {
      if (intensityMetricGroupItem.id === intensityMetricGroup.id) {
        return intensityMetricGroup;
      }
      return intensityMetricGroupItem;
    });
    setIntensityMetricsGroups(newIntensityMetricGroups);
    handleCloseModal();
    dispatch(setNotification(t('notification.editIntensityMetricGroup')));
  };

  const deleteIntensityMetricGroup = (id: string) => {
    const newIntensityMetricGroups = intensityMetricsGroups.filter(
      (intensityMetricGroup) => intensityMetricGroup.id !== id
    );
    setIntensityMetricsGroups(newIntensityMetricGroups);
    handleCloseModal();
    dispatch(setNotification(t('notification.deleteIntensityMetricGroup')));
  };

  const navigateToArticle = () => {
    type IntensityMetricUrl = {
      [key: string]: string;
    };

    const intensityMetricUrl: IntensityMetricUrl = INTENSITY_METRIC_ARTICLE;
    let url = intensityMetricUrl.en;
    if (intensityMetricUrl[i18n.resolvedLanguage]) {
      url = intensityMetricUrl[i18n.resolvedLanguage];
    }

    window.open(url);
  };

  return (
    <section className='intensity-metrics-groups'>
      <Filters.Root
        setFilters={setFilters}
        filters={filters}
        open={false}
        setOpen={() => {
          return;
        }}>
        <SectionHeader title={t('intensityMetrics.title')} breadcrumb={<Breadcrumb />} />
        <HelpCTA description={t('intensityMetrics.help')}>
          <div className='flex'>
            <Button
              lookAndFeel='primary'
              text={t('intensityMetrics.intensityMetric')}
              onClick={navigateToArticle}
              size='small'
            />
          </div>
        </HelpCTA>
        <CardsFilter listView={listView} onChangeListView={handleChangeListView}>
          <>
            <FilterText
              field='name'
              type='il'
              placeholder={t('intensityMetrics.searchPlaceholder')}
              size={InputSize.SMALL}
            />
          </>
        </CardsFilter>
        <div id={'intensity-metric-group'} className='infinite-scroll-wrapper-card'>
          <InfiniteScroll
            dataLength={intensityMetricsGroups ? intensityMetricsGroups.length : 0}
            next={fetchMoreData}
            hasMore={hasMore}
            style={{ overflow: 'visible' }}
            endMessage={<></>}
            loader={<LoaderCard />}
            scrollableTarget={'intensity-metric-group'}>
            <CardList
              listView={listView}
              title={t('intensityMetrics.newIntensityMetricGroup')}
              description={t('intensityMetrics.newIntensityMetricGroupDescription')}
              buttons={
                <Button
                  lookAndFeel='primary'
                  text={t('intensityMetrics.create')}
                  onClick={handleShowCreateIntensityMetricGroup}
                  size='small'
                />
              }
              loading={loading}>
              {intensityMetricsGroups.map((elem) => {
                const data = {
                  id: elem.id,
                  title: elem.unit,
                  description: t(`createIntensityMetricGroup.${elem.scopes.join(',')}`),
                  icon: '/images/icons/intensityMetric.svg',
                  tag: elem.is_grouping ? t('createIntensityMetricGroup.grouping') : undefined
                };
                return (
                  <Card key={elem.id} listView={listView} onClick={() => onClickCard(elem.id)}>
                    <Card.Options>
                      <ButtonDropdown
                        button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                        options={[
                          {
                            id: 'edit',
                            name: t('intensityMetrics.edit'),
                            onClick: () => handleEditIntensityMetricGroup(elem.id)
                          },
                          {
                            id: 'delete',
                            name: t('intensityMetrics.delete'),
                            onClick: () => setIntensityMetricGroupToDelete(elem.id)
                          }
                        ]}></ButtonDropdown>
                    </Card.Options>
                    <Card.Content>
                      <Card.Icon icon={data.icon} />
                      <Card.Content>
                        <Card.Content>
                          <span className='headline4-font'>{data.title}</span>
                          <span className='subtitle3-font'>{data.description}</span>
                        </Card.Content>
                      </Card.Content>
                    </Card.Content>
                  </Card>
                );
              })}
            </CardList>
          </InfiniteScroll>
        </div>
        <Modal show={showCreateIntensityMetricGroup} onClose={handleCloseModal} width='600px'>
          <CreateIntensityMetricGroup addIntensityMetricGroup={addIntensityMetricGroup} />
        </Modal>
        <Modal show={!!intensityMetricGroupToEdit} onClose={handleCloseModal} width='600px'>
          {intensityMetricGroupToEdit && (
            <EditIntensityMetricGroup
              intensityMetricGroup={intensityMetricGroupToEdit}
              editIntensityMetricGroup={editIntensityMetricGroup}
            />
          )}
        </Modal>
        <Modal show={!!intensityMetricGroupToDelete} onClose={handleCloseModal} width='400px'>
          <DeleteIntensityMetricGroup
            id={intensityMetricGroupToDelete}
            removeIntensityMetricGroup={deleteIntensityMetricGroup}
            closeModal={handleCloseModal}
          />
        </Modal>
      </Filters.Root>
    </section>
  );
};

export default IntensityMetricsGroups;
