// export const datapoints: Datapoint[] = [
//   {
//     datapoint_id: 'E1.IRO-1-1',
//     datapoint: 'description_of_process_in_relation_to_impacts_on_climate_change',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-2',
//     datapoint:
//       'description_of_process_in_relation_to_climate-related_physical_risks_in_own_operations_and_along_value_chain',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-3',
//     datapoint:
//       'climate-related_hazards_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-4',
//     datapoint:
//       'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_climate-related_hazards',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-5',
//     datapoint: 'short-,_medium-_and_long-term_time_horizons_have_been_defined',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-6',
//     datapoint:
//       'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_climate-related_hazards_has_been_assessed',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-7',
//     datapoint:
//       'identification_of_climate-related_hazards_and_assessment_of_exposure_and_sensitivity_are_informed_by_high_emissions_climate_scenarios',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-8',
//     datapoint:
//       'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_physical_risks_over_short,_medium_and_long-term',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-9',
//     datapoint:
//       'description_of_process_in_relation_to_climate-related_transition_risks_and_opportunities_in_own_operations_and_along_value_chain',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-10',
//     datapoint:
//       'transition_events_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-11',
//     datapoint:
//       'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_transition_events',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-12',
//     datapoint:
//       'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_transition_events_has_been_assessed',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-13',
//     datapoint:
//       'identification_of_transition_events_and_assessment_of_exposure_has_been_informed_by_climate-related_scenario_analysis',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-14',
//     datapoint:
//       'assets_and_business_activities_that_are_incompatible_with_or_need_significant_efforts_to_be_compatible_with_transition_to_climate-neutral_economy_have_been_identified',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-15',
//     datapoint:
//       'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_transition_risks_and_opportunities_over_short,_medium_and_long-term',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1.IRO-1-16',
//     datapoint:
//       'explanation_of_how_climate_scenarios_used_are_compatible_with_critical_climate-related_assumptions_made_in_financial_statements',
//     state: 'pending',
//     relevance: 'mandatory',
//     automatic_calculated_by_dcycle: false,
//     type: 'qualitative'
//   },
//   {
//     datapoint_id: 'E1-4-1',
//     datapoint: 'absolute_value_of_total_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-2',
//     datapoint:
//       'percentage_of_total_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-3',
//     datapoint: 'intensity_value_of_total_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-4',
//     datapoint: 'absolute_value_of_scope_1_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-5',
//     datapoint:
//       'percentage_of_scope_1_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-6',
//     datapoint: 'intensity_value_of_scope_1_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-7',
//     datapoint: 'absolute_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-8',
//     datapoint: 'absolute_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-4-9',
//     datapoint: 'absolute_value_of_scope_3_greenhouse_gas_emissions_reduction',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-1',
//     datapoint: 'total_energy_consumption_related_to_own_operations',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-2',
//     datapoint: 'total_energy_consumption_from_fossil_sources',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-3',
//     datapoint: 'total_energy_consumption_from_renewable_sources',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-4',
//     datapoint: 'fuel_consumption_from_renewable_sources',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-5',
//     datapoint:
//       'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_and_cooling_from_renewable_sources',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-6',
//     datapoint: 'consumption_of_self-generated_non-fuel_renewable_energy',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-7',
//     datapoint: 'fuel_consumption_from_coal_and_coal_products',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-8',
//     datapoint: 'fuel_consumption_from_crude_oil_and_petroleum_products',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-9',
//     datapoint: 'fuel_consumption_from_natural_gas',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-10',
//     datapoint: 'fuel_consumption_from_other_fossil_sources',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-11',
//     datapoint:
//       'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_or_cooling_from_fossil_sources',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-12',
//     datapoint: 'percentage_of_fossil_sources_in_total_energy_consumption',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-13',
//     datapoint: 'non-renewable_energy_production',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-14',
//     datapoint: 'renewable_energy_production',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-5-15',
//     datapoint: 'total_energy_consumption_from_activities_in_high_climate_impact_sectors',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-6-1',
//     datapoint: 'gross_scope_1_greenhouse_gas_emissions',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-6-2',
//     datapoint: 'total_ghg_emissions',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-6-3',
//     datapoint:
//       'biogenic_emissions_of_co2_from_the_combustion_or_bio-degradation_of_biomassnot_included_in_scope_1_ghg_emissions',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-6-4',
//     datapoint:
//       'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_not_included_in_scope_2_ghg_emissions',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-6-5',
//     datapoint:
//       'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_that_occur_in_value_chain_not_included_in_scope_3_ghg_emissions',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   },
//   {
//     datapoint_id: 'E1-7-1',
//     datapoint: 'reversals',
//     state: 'completed',
//     relevance: 'not_mandatory',
//     automatic_calculated_by_dcycle: true,
//     type: 'quantitative'
//   }
// ];

export const datapoints: Datapoint[] = [
  {
    datapoint_id: 'E1.IRO-1-1',
    datapoint: 'description_of_process_in_relation_to_impacts_on_climate_change',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-2',
    datapoint:
      'description_of_process_in_relation_to_climate_related_physical_risks_in_own_operations_and_along_value_chain',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-3',
    datapoint:
      'climate_related_hazards_have_been_identified_over_short_medium_and_long_term_time_horizons',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-4',
    datapoint:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_climate_related_hazards',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-5',
    datapoint: 'short_medium_and_long_term_time_horizons_have_been_defined',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-6',
    datapoint:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_climate_related_hazards_has_been_assessed',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-7',
    datapoint:
      'identification_of_climate_related_hazards_and_assessment_of_exposure_and_sensitivity_are_informed_by_high_emissions_climate_scenarios',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-8',
    datapoint:
      'explanation_of_how_climate_related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_physical_risks_over_short_medium_and_long_term',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-9',
    datapoint:
      'description_of_process_in_relation_to_climate_related_transition_risks_and_opportunities_in_own_operations_and_along_value_chain',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-10',
    datapoint:
      'transition_events_have_been_identified_over_short_medium_and_long_term_time_horizons',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-11',
    datapoint:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_transition_events',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-12',
    datapoint:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_transition_events_has_been_assessed',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-13',
    datapoint:
      'identification_of_transition_events_and_assessment_of_exposure_has_been_informed_by_climate_related_scenario_analysis',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-14',
    datapoint:
      'assets_and_business_activities_that_are_incompatible_with_or_need_significant_efforts_to_be_compatible_with_transition_to_climate_neutral_economy_have_been_identified',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-15',
    datapoint:
      'explanation_of_how_climate_related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_transition_risks_and_opportunities_over_short_medium_and_long_term',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1.IRO-1-16',
    datapoint:
      'explanation_of_how_climate_scenarios_used_are_compatible_with_critical_climate_related_assumptions_made_in_financial_statements',
    state: 'completed',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'E1-4-2',
    datapoint:
      'tables_multiple_dimensions_baseline_year_and_targets_ghg_types_scope_3_categories_decarbonisation_levers_entity_specific_denominators_for_intensity_value',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-3',
    datapoint: 'absolute_value_of_total_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-4',
    datapoint:
      'percentage_of_total_greenhouse_gas_emissions_reduction_as_of_emissions_of_base_year',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-5',
    datapoint: 'intensity_value_of_total_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-6',
    datapoint: 'absolute_value_of_scope_1_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-7',
    datapoint:
      'percentage_of_scope_1_greenhouse_gas_emissions_reduction_as_of_emissions_of_base_year',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-8',
    datapoint: 'intensity_value_of_scope_1_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-13',
    datapoint:
      'percentage_of_market_based_scope_2_greenhouse_gas_emissions_reduction_as_of_emissions_of_base_year',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-14',
    datapoint: 'intensity_value_of_market_based_scope_2_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-15',
    datapoint: 'absolute_value_of_scope_3_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-16',
    datapoint:
      'percentage_of_scope_3_greenhouse_gas_emissions_reduction_as_of_emissions_of_base_year',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-4-17',
    datapoint: 'intensity_value_of_scope_3_greenhouse_gas_emissions_reduction',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-1',
    datapoint: 'total_energy_consumption_related_to_own_operations',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-2',
    datapoint: 'total_energy_consumption_from_fossil_sources',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-5',
    datapoint: 'total_energy_consumption_from_renewable_sources',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-6',
    datapoint: 'fuel_consumption_from_renewable_sources',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-7',
    datapoint:
      'consumption_of_purchased_or_acquired_electricity_heat_steam_and_cooling_from_renewable_sources',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-8',
    datapoint: 'consumption_of_self_generated_non_fuel_renewable_energy',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-9',
    datapoint: 'percentage_of_renewable_sources_in_total_energy_consumption',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-10',
    datapoint: 'fuel_consumption_from_coal_and_coal_products',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-1',
    datapoint: 'fuel_consumption_from_crude_oil_and_petroleum_products',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-12',
    datapoint: 'fuel_consumption_from_natural_gas',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-13',
    datapoint: 'fuel_consumption_from_other_fossil_sources',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-14',
    datapoint:
      'consumption_of_purchased_or_acquired_electricity_heat_steam_or_cooling_from_fossil_sources',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-16',
    datapoint: 'non_renewable_energy_production',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-17',
    datapoint: 'renewable_energy_production',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-5-19',
    datapoint: 'total_energy_consumption_from_activities_in_high_climate_impact_sectors',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-1',
    datapoint: 'gross_scopes_1_2_3_and_total_ghg_emissions_ghg_emissions_per_scope_table',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-2',
    datapoint: 'gross_scopes_1_2_3_and_total_ghg_emissions_financial_and_operational_control_table',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-3',
    datapoint:
      'disaggregation_of_ghg_emissions_by_country_operating_segments_economic_activity_subsidiary_ghg_category_or_source_type',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-4',
    datapoint:
      'gross_scopes_1_2_3_and_total_ghg_emissions_scope_3_ghg_emissions_ghg_protocol_table',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-5',
    datapoint: 'gross_scopes_1_2_3_and_total_ghg_emissions_scope_3_ghg_emissions_iso_14064_1_table',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-6',
    datapoint: 'gross_scopes_1_2_3_and_total_ghg_emissions_total_ghg_emissions_value_chain_table',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-7',
    datapoint: 'gross_scope_1_greenhouse_gas_emissions',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-17',
    datapoint:
      'biogenic_emissions_of_co2_from_the_combustion_or_bio_degradation_of_biomassnot_included_in_scope_1_ghg_emissions',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-24',
    datapoint:
      'biogenic_emissions_of_co2_from_combustion_or_bio_degradation_of_biomass_not_included_in_scope_2_ghg_emissions',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-6-28',
    datapoint:
      'biogenic_emissions_of_co2_from_combustion_or_bio_degradation_of_biomass_that_occur_in_value_chain_not_included_in_scope_3_ghg_emissions',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'E1-7-7',
    datapoint: 'reversals',
    state: 'completed',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  }
];

export const steps = [
  {
    id: 'climate_change',
    text: 'esgMetrics.environmental.climate_change',
    isSelected: true
  },
  {
    id: 'pollution',
    text: 'esgMetrics.environmental.pollution',
    isSelected: false
  },
  {
    id: 'water_and_marine_resources',
    text: 'esgMetrics.environmental.water_and_marine_resources',
    isSelected: false
  },
  {
    id: 'biodiversity_and_ecosystems',
    text: 'esgMetrics.environmental.biodiversity_and_ecosystems',
    isSelected: false
  },
  {
    id: 'resources_and_circular_economy',
    text: 'esgMetrics.environmental.resources_and_circular_economy',
    isSelected: false
  }
];
