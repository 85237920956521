import './styles.scss';
type Props = {
  file: {
    id: string;
    name: string;
    url?: string;
  };
  handleRemove: (id: string) => void;
};
const FileAttached = ({ file, handleRemove }: Props) => {
  const handleDownloadFile = () => {
    if (!file.url) return;
    // Check first if file_url is a blob url and download it directly
    if (file.url.startsWith('blob:')) {
      const a = document.createElement('a');
      a.href = file.url;
      a.download = file.name;
      a.click();
      return;
    }

    window.open(file.url, '_blank');
  };
  return (
    <div className='file-attached'>
      <div className='flex' style={{ alignItems: 'center', gap: '1rem' }}>
        {file.url && (
          <img
            src='/images/icons/downloadGradient.svg'
            className='pointer'
            onClick={handleDownloadFile}
          />
        )}
        <img
          src='/images/icons/close.svg'
          className='pointer'
          onClick={() => handleRemove(file.id)}
          width={12}
          height={12}
        />
      </div>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <img src='/images/icons/paper.svg' />
        <span className='subtitle3-font'>{file.name}</span>
      </div>
    </div>
  );
};

export default FileAttached;
