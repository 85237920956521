export const gradient_color = 'linear-gradient(to right, #05534e, #05534e)';
export const gradient_color_secondary = 'linear-gradient(to right, #05534e, #05534e)';

export const primary_color = '#06283b';

export const highlight_color = '#05534e';
export const highlight_color2 = '#05534e';
export const secondary_bg_color = '#ffffff';
export const secondary_color = 'rgba(25, 42, 62, 0.8)';
export const secondary_border_color = '#d1d4d8';

export const scope1_color = '#05534e';
export const scope2_color = '#279992';
export const scope3_color = '#a9b3f8';

export const fourth_color = '#ff2990';
export const fifth_color = '#6e86f7';

export const gray_color = '#d1d4d8';
export const gray_color2 = '#eaeaea';
export const blocked_color = '#6e86f7';
export const gray_dark_color = '#415062';
export const gray_dashboard = '#fafafa';

export const disabled_color = 'var(--disabled-color)';

export const warning_color = 'var(--warning-color)';
export const warning_bg_color = 'var(--warning-bg-color)';

export const main_bg_color = '#ffffff';

export const success_color = '#05534e';
export const error_color = '#FF2990';
export const pending_color = 'var(--pending-color)';
