import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import Button from '../../ui/button/Button';
import './styles.scss';

function FreePlanReminder() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useContext(UserContext);

  const foundOrganization = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  const subscriptionPlan = foundOrganization?.subscription_plan;

  const [error, setError] = useState('');

  const navigateToBudgetCalculator = () => {
    navigate(ROUTES.BUDGET_CALCULATOR);
  };

  const navigateToBookMeeting = () => {
    navigate(ROUTES.BOOK_MEETING);
  };

  return (
    <div className='free-plan-reminder-wrapper'>
      <img src='/images/woman.svg' alt='woman' />
      <h1 className='headline3-font'>
        {subscriptionPlan === 'free_plan'
          ? t('freePlanReminder.title')
          : t('freePlanReminder.titlePaidPlan')}
      </h1>
      <p className='subtitle3-font'>{t('freePlanReminder.description')}</p>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          onClick={navigateToBookMeeting}
          text={t('freePlanReminder.bookMeeting')}
          size='small'
        />
        {/* <Button
          lookAndFeel='secondary'
          onClick={navigateToBudgetCalculator}
          text={t('freePlanReminder.improveMyPlan')}
          size='small'
        /> */}
      </div>
      {error && <span className='error-text error-font error-text-color'>{error}</span>}
    </div>
  );
}

export default FreePlanReminder;
