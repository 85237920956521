import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import CardEsg from '../components/CardEsg/CardEsg';
import useMockedData from './useMockedData';

const useData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });
  const navigate = useNavigate();

  const {
    doubleMaterialityCompleteness,
    generalInformationRequirements,
    generalInformationDatapoints,
    generalInformationCompleteness,
    environmentalRequirements,
    environmentalDatapoints,
    environmentalCompleteness,
    socialRequirements,
    socialDatapoints,
    socialCompleteness,
    governanceRequirements,
    governanceDatapoints,
    governanceCompleteness
  } = useMockedData();

  const data = [
    <CardEsg
      key='double-materiality'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS_DOUBLE_MATERIALITY)}>
      <>
        <CardEsg.Title title={t('doubleMaterialityTitle')} />
        <CardEsg.Description descriptions={[t('doubleMaterialityDescription')]} />
        <CardEsg.Completeness completeness={doubleMaterialityCompleteness} />
        <CardEsg.Esrs esrs={t('startNow')} />
      </>
    </CardEsg>,
    <CardEsg
      key='general-information'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS_GENERAL_INFO)}>
      <>
        <CardEsg.Title title={t('generalInformationTitle')} />
        <CardEsg.Requirements
          requirements={generalInformationRequirements}
          datapoints={generalInformationDatapoints}
        />
        <CardEsg.Completeness completeness={generalInformationCompleteness} />
        <CardEsg.Esrs esrs={'ESRS 2'} />
      </>
    </CardEsg>,
    <CardEsg key='ambiental' onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS_ENVIRONMENTAL)}>
      <>
        <CardEsg.Title title={t('ambientalTitle')} />
        <CardEsg.Requirements
          requirements={environmentalRequirements}
          datapoints={environmentalDatapoints}
        />
        <CardEsg.Completeness completeness={environmentalCompleteness} />
        <CardEsg.Esrs esrs={'ESRS E1 E2 E3 E4 E5'} />
      </>
    </CardEsg>,
    <CardEsg key='social' onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS_SOCIAL)}>
      <>
        <CardEsg.Title title={t('socialTitle')} />
        <CardEsg.Requirements requirements={socialRequirements} datapoints={socialDatapoints} />
        <CardEsg.Completeness completeness={socialCompleteness} />
        <CardEsg.Esrs esrs={'ESRS S1 S2 S3 S4'} />
      </>
    </CardEsg>,
    <CardEsg key='governance' onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS_GOVERNANCE)}>
      <>
        <CardEsg.Title title={t('governanceTitle')} />
        <CardEsg.Requirements
          requirements={governanceRequirements}
          datapoints={governanceDatapoints}
        />
        <CardEsg.Completeness completeness={governanceCompleteness} />
        <CardEsg.Esrs esrs={'ESRS G1'} />
      </>
    </CardEsg>
  ];

  const onClickCard = (url: string) => navigate(url);

  return { data };
};

export default useData;
