import { useTranslation } from 'react-i18next';
import TableComponent from '../../../../ui/table/Table';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import useColumns from '../../hooks/useColumns';
import '../../styles.scss';
import Button from '../../../../ui/button/Button';

type Props = {
  datapoints: Datapoint[];
};

export const DatapointsTable = ({ datapoints }: Props) => {
  const { t } = useTranslation();
  const columns = useColumns();

  const pendings = datapoints.filter((datapoint) => datapoint.state === 'pending').length;

  return (
    <div className='infinite-scroll-container card-border-color main-bg-color solid-border table-container'>
      <div className='flex justify-end' style={{ marginBottom: '1.5rem' }}>
        <Button
          style={{
            width: '7rem',
            marginRight: '0.66rem'
          }}
          size='tiny'
          lookAndFeel={'primary'}
          disabled
          text={t('general.download')}
        />
        <TotalLegend
          total={datapoints.length}
          total2={pendings}
          loading={false}
          i18key={'esgMetrics'}
        />
      </div>
      <div className='infinite-scroll-wrapper' style={{ height: '50vh', overflowY: 'scroll' }}>
        <TableComponent
          data={datapoints}
          columns={columns}
          emptyText={t('table.empty')}
          loading={false}
        />
      </div>
    </div>
  );
};
