import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import { LCADiagram } from '../../../Scenarios/components/LCADiagram';
import { LCADetailFacade } from '../components/LCADetailFacade';

export const LCADetailCanvas = () => {
  const flags = useFeatureFlags();
  return (
    <>
      {flags?.lcaScenariosDiagram && <LCADetailFacade />}
      <LCADiagram />
    </>
  );
};
