import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { checkFlags } from '../../../utils/flags';
import useSections from './hooks/useSections';
import MainSection from './mainSection/MainSection';
import Section from './section/Section';
import './styles.scss';

function SideMenu() {
  const { i18n } = useTranslation('');
  const flags = useFeatureFlags();
  const { t } = useTranslation();

  const location = useLocation();

  const [subSections, setSubSections] = useState<SubSection[]>([]);
  const [sectionSelected, setSectionSelected] = useState('');

  const foundOrganization = useSelectedOrganization();

  const navigate = useNavigate();

  const { sections, sectionsBottom } = useSections({ flags });

  useEffect(() => {
    const pathname = location.pathname;
    let section = sections.find((section) => pathname.split('/')[1] === section.id);
    if (!section) {
      section = sectionsBottom.find((section) => pathname.split('/')[1] === section.id);
    }

    if (section) {
      setSectionSelected(section.id);
      if (section.subSections) {
        setSubSections(section.subSections);
      }
    } else {
      setSectionSelected('');
      setSubSections([]);
    }
  }, [location.pathname, i18n.resolvedLanguage, flags]);

  const navigateToDashboard = () => {
    navigate(ROUTES.IMPROVE_DASHBOARD);
  };

  const onClickMainSection = (id: string) => {
    setSectionSelected(id);
    let section = sections.find((section) => section.id === id);
    if (!section) {
      section = sectionsBottom.find((section) => section.id === id);
    }
    // set sub sections
    if (section?.subSections) {
      setSubSections(section.subSections);
    } else {
      setSubSections([]);
    }
  };

  return (
    <>
      <aside className='side-menu menu-bg-color'>
        <img
          className='logo'
          src={foundOrganization?.logo_url ?? '/images/shortLogo.svg'}
          alt='logo-menu'
          onClick={navigateToDashboard}
        />
        <div className='side-menu__section-wrapper'>
          <div className='side-menu__section-wrapper__top' id='menu'>
            {sections.map((section) =>
              checkFlags(section.id, flags) && !section.hide ? (
                <MainSection
                  key={section.text}
                  id={section.id}
                  idSelected={sectionSelected}
                  text={section.text}
                  icon={section.icon}
                  iconSelected={section.iconSelected}
                  route={section.route}
                  isNew={section.isNew}
                  onClickMainSection={onClickMainSection}
                />
              ) : null
            )}
          </div>
          <div className='side-menu__section-wrapper__bottom'>
            {sectionsBottom.map((section) =>
              checkFlags(section.id, flags) && !section.hide ? (
                <MainSection
                  key={section.text}
                  id={section.id}
                  idSelected={sectionSelected}
                  text={section.text}
                  icon={section.icon}
                  iconSelected={section.iconSelected}
                  route={section.route}
                  isNew={section.isNew}
                  onClickMainSection={onClickMainSection}
                />
              ) : null
            )}
          </div>
        </div>
      </aside>
      {subSections.length > 0 &&
        subSections.some((subSection) => checkFlags(subSection.id, flags)) && (
          <div className='side-menu__sub-section-wrapper'>
            {subSections.map((subSection) =>
              checkFlags(subSection.id, flags) && !subSection.hide ? (
                subSection?.isNew ? (
                  <div className='section-name' key={subSection.id}>
                    <Section
                      section={subSection}
                      key={subSection.id}
                      subscriptionPlan={foundOrganization?.subscription_plan || 'free_plan'}
                    />
                    <span className='tag-new span-small-font tag2-bg-text-color'>
                      {t('sideMenu.new')}
                    </span>
                  </div>
                ) : (
                  <Section
                    section={subSection}
                    key={subSection.id}
                    subscriptionPlan={foundOrganization?.subscription_plan || 'free_plan'}
                  />
                )
              ) : null
            )}
          </div>
        )}
    </>
  );
}

export default SideMenu;
