import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserContext } from '../../../../context/userContext';
import { postLCARequest } from '../service/postLCARequest';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';

export const usePostLCARequest = () => {
  const user = useContext(UserContext);
  const org = useSelectedOrganization();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { t } = useLCATranslation();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => await postLCARequest({ user, orgName: org?.company_name }),
    onSuccess: () => {
      dispatch(setNotification(t('requestLCA.sent')));

      queryClient.invalidateQueries({ queryKey: ['lcaRequestStatus', org?.id] });
    },
    onError: () => {
      dispatch(setNotification(t('requestLCA.error')));
    }
  });

  return { postLCARequest: mutateAsync, isPosting: isPending };
};
