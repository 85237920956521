import { User } from '../../../../types/entities/user';
import apiFetch from '../../../../utils/apiFetch';
import { LCA_STATUS_KEY } from '../constants';

type LCARequestParams = {
  user: User | null;
  orgName?: string;
};

const URL = '/organization_recommendations';

export const postLCARequest = async ({ user, orgName }: LCARequestParams) => {
  if (!user) {
    throw new Error('User is required');
  }

  if (!orgName) {
    throw new Error('Organization name is required');
  }

  const res = await apiFetch('post', URL, {
    name: LCA_STATUS_KEY,
    user_id: user.id,
    email: user.email,
    organization_name: orgName,
    organization_id: user.selectedOrganization
  });

  return res.data;
};
