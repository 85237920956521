import { Handle, Node, Position } from 'reactflow';
import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { PieceNode } from './PieceNode';
import { buildPieceFromOutput } from '../../utils/builders';
import { useRef, useState } from 'react';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { useCreateNodeContext } from '../CreateNode/CreateNode';
import type { LCAOutputs } from '../../../types/LCABlock';

type ProcessNodeProps = {
  data: Node['data'];
};

export const ProcessNode: React.FC<ProcessNodeProps> = ({ data }) => {
  const { t } = useLCATranslation();
  const { setTarget } = useCreateNodeContext();
  const nodeRef = useRef<HTMLDivElement>(null);

  const piece = buildPieceFromOutput(
    data.outputs.find((output: LCAOutputs) => output.type === 'product')
  );

  const [timeoutState, setTimeoutState] = useState<NodeJS.Timeout | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (timeoutState) {
      clearTimeout(timeoutState);
    }

    const timeout = setTimeout(() => {
      setIsHovered(true);
    }, 500);

    setTimeoutState(timeout);
  };

  const handleMouseLeave = () => {
    if (timeoutState) {
      clearTimeout(timeoutState);
    }

    const timeout = setTimeout(() => {
      setIsHovered(false);
    }, 500);

    setTimeoutState(timeout);
  };

  const onClick = () => {
    setTarget(data);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={nodeRef}>
      <Handle type='target' position={Position.Left} isConnectableEnd isConnectableStart={false} />
      <Button
        lookAndFeel='primary'
        iconNode={<Icon icon='add' color='white' />}
        size='tiny'
        style={{
          position: 'absolute',
          top: 'calc(50% - 20px)',
          width: '28px',
          zIndex: 1,
          left: '-43.5px',
          display: isHovered ? 'block' : 'none'
        }}
        onClick={onClick}
      />
      <LCACard.Layout>
        <LCACard.Content>
          <LCACard.Icon icon={NODE_ICONS[NODE_TYPES.process]} lookAndFeel='process' />
          <div className='flex-col'>
            <span className='font-body-b2-sb text-gray-light'>{t('lcaScenarios.process')}</span>
            <span className='font-body-b1-sb'>{data.label}</span>
          </div>
        </LCACard.Content>
        <div className='relative'>
          {piece && <PieceNode.Appearance data={piece} disableHover />}
          <Handle
            type='source'
            position={Position.Right}
            isConnectableStart={data.outputs.length === 0}
          />
        </div>
      </LCACard.Layout>
    </div>
  );
};
