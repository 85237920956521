import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';

import { useRef } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);
function LineChart({ chartData, index, stacked = true }) {
  const chartRef = useRef(null);
  const decimation = {
    enabled: true,
    algorithm: 'min-max'
  };
  const options = {
    responsive: true,
    interaction: {
      mode: 'nearest',
      axis: 'xy',
      intersect: false
    },
    scales: {
      x: {
        stacked,
        display: false,
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        stacked,
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 8,
            family: 'Inter',
            weight: 400,
            lineHeight: 1.2
          }
        }
      }
    },
    elements: {
      point: {
        radius: 0 // hide the data points
      }
    },
    plugins: {
      legend: {
        display: false
      },
      decimation,
      tooltip: {
        enabled: false
      }
    },
    font: {
      size: 8,
      family: 'Inter',
      weight: 400,
      lineHeight: 1.2
    }
  };
  const labels = [...chartData];

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        fill: false,
        borderColor: '#5b6de860'
      }
    ]
  };

  const onMouseLeave = () => {
    //
  };

  const plugins = [
    {
      afterDraw: (chart) => {
        if (chart.data.datasets.length <= 0) return;
        const { ctx } = chart;
        const meta = chart.getDatasetMeta(0);
        if (index < 0) return;
        const x = meta?.data?.at(index)?.x;
        if (!x) return;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        // draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#A4A6B3';
        ctx.stroke();
        ctx.restore();
        ctx.setLineDash([1, 1]);
      }
    }
  ];
  return (
    <>
      <Line
        options={options}
        data={data}
        ref={chartRef}
        plugins={plugins}
        onMouseLeave={onMouseLeave}
        style={{ maxWidth: '100%' }}
      />
    </>
  );
}

export default LineChart;
