import { useContext } from 'react';
import { UserContext } from '../../context/userContext';
import { DashboardMode } from '../../types/entitiesEnums/dashboard';
import { DashboardEsg } from './dashboardEsg/DashboardEsg';
import DashboardGrouping from './dashboardGrouping/DashboardGrouping';
import { DashboardInvestment } from './dashboardInvestment/DashboardInvestment';
import DashboardTotal from './dashboardTotal/DashboardTotal';
import DashboardLogistic from './dashboardLogistic/DashboardLogistic';

type Props = {
  mode: DashboardMode;
};

const DashboardV2 = ({ mode }: Props) => {
  const user = useContext(UserContext);

  // Factory pattern. Return the correct dashboard
  if (mode === DashboardMode.TOTAL) {
    return <DashboardTotal />;
  }

  if (mode === DashboardMode.GROUPING) {
    return <DashboardGrouping groupingId={user?.selectedGrouping ?? ''} />;
  }

  if (mode === DashboardMode.INVESTMENT) {
    return <DashboardInvestment />;
  }

  if (mode === DashboardMode.ESG_METRICS) {
    return <DashboardEsg />;
  }

  if (mode === DashboardMode.LOGISTICS) {
    return <DashboardLogistic />;
  }

  return null;
};

export default DashboardV2;
