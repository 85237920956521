import { AxiosResponse } from 'axios';
import { OrganizationRecommendation } from '../../../../types/entities/organization';
import { LCARequestStatus } from '../types/LCARequestStatus';
import apiFetch from '../../../../utils/apiFetch';
import { LCA_STATUS_KEY } from '../constants';

const URL = '/organization_recommendations/organization';

export const getLCARequestStatus = async (orgId?: string): Promise<LCARequestStatus | null> => {
  if (!orgId) {
    throw new Error('Organization ID is required');
  }

  const response: AxiosResponse<OrganizationRecommendation[]> = await apiFetch(
    'GET',
    `${URL}/${orgId}`
  );

  const lcaStatus = response.data.find(
    (item) => item.name === LCA_STATUS_KEY && item.status === 'pending'
  );

  if (!lcaStatus) {
    return null;
  }

  return {
    id: lcaStatus.id,
    isRequested: true
  };
};
