export const LCA_DETAIL_OPTIONS = {
  environmental_impacts: 'environmental_impacts',
  canvas: 'canvas'
} as const;

export const LCA_DETAIL_STEPS = [
  {
    id: LCA_DETAIL_OPTIONS.environmental_impacts,
    name: 'lcaDetail.environmentalImpacts'
  },
  {
    id: LCA_DETAIL_OPTIONS.canvas,
    name: 'lcaDetail.canvas'
  }
];

export const LCA_ENVIRONMENTAL_IMPACTS_HIGHEST_LEVEL_COMPONENTS = {
  production: 'Producción',
  construction: 'Construcción',
  use: 'Uso',
  end_of_life: 'Final de vida'
} as const;

export const LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS = {
  A1: 'lcaDetail.stages.a1',
  A2: 'lcaDetail.stages.a2',
  A3: 'lcaDetail.stages.a3',
  A4: 'lcaDetail.stages.a4',
  A5: 'lcaDetail.stages.a5',
  B1: 'lcaDetail.stages.b1',
  B2: 'lcaDetail.stages.b2',
  B3: 'lcaDetail.stages.b3',
  B4: 'lcaDetail.stages.b4',
  B5: 'lcaDetail.stages.b5',
  B6: 'lcaDetail.stages.b6',
  B7: 'lcaDetail.stages.b7',
  C1: 'lcaDetail.stages.c1',
  C2: 'lcaDetail.stages.c2',
  C3: 'lcaDetail.stages.c3',
  C4: 'lcaDetail.stages.c4'
} as const;
