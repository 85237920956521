import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { ReactComponent as SVGMoreInfo } from '../../../../../assets/img/double_materiality_image.svg';
import { UserContext } from '../../../../../context/userContext';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { sendEmails } from '../../../../../services/api/emails';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../../../layout/sectionHeader/SectionHeader';
import Button from '../../../../ui/button/Button';
import { CertificationPage } from '../../../../ui/certificationPage/CertificationPage';
import Modal from '../../../../ui/modal/Modal';
import MoreInfoModal from './components/MoreInfoModal/MoreInfoModal';
import UploadModal from './components/UploadModal/UploadModal';

const DoubleMateriality = () => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality' });

  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [loading, setLoading] = useState(false);

  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);

    const subject = `El usuario ${user?.email} de la organización ${selectedOrganization?.company_name} ha clicado en más información de Doble Materialidad`;

    const response = await sendEmails(subject, undefined, undefined, [
      'cecilia@dcycle.io',
      'vanesa@dcycle.io',
      'ricardo.mendez@dcycle.io',
      'clients@dcycle.io'
    ]);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setLoading(false);
    dispatch(setNotification(t('notification.success')));
  };

  return (
    <section className='main-page-layout flex-col gap-y-8'>
      <SectionHeader title={t('title')} breadcrumb={<Breadcrumb />} />
      <CertificationPage.Layout>
        <div className='flex' style={{ height: '43vh' }}>
          <div className='flex-col'>
            <div className='flex-col justify-between' style={{ height: '34vh' }}>
              <CertificationPage.Title>{t('title')}</CertificationPage.Title>
              <CertificationPage.Description>
                <Trans i18nKey='doubleMateriality.startDescription' />
              </CertificationPage.Description>
              <CertificationPage.SectionLayout>
                <CertificationPage.SectionTitle>{t('benefits')}</CertificationPage.SectionTitle>
                <CertificationPage.Checks
                  checks={[t('check1'), t('check2'), t('check3'), t('check4')]}
                />
              </CertificationPage.SectionLayout>
            </div>

            <CertificationPage.ButtonSection>
              <>
                <Button
                  lookAndFeel='primary'
                  text={t('upload')}
                  size='small'
                  onClick={() => setShowUpload(true)}
                  className='mt-1'
                />
                <Button
                  lookAndFeel='secondary'
                  text={t('moreInfoModal.send')}
                  size='medium'
                  onClick={handleSubmit}
                  loading={loading}
                  className='mt-1'
                />
              </>
            </CertificationPage.ButtonSection>
          </div>
        </div>
        <div>
          <SVGMoreInfo style={{ width: '98%' }} />
        </div>
      </CertificationPage.Layout>
      <Modal show={showUpload} onClose={() => setShowUpload(false)} maxWidth='650px' width='650px'>
        <UploadModal onClose={() => setShowUpload(false)} />
      </Modal>

      <Modal
        show={showMoreInfo}
        onClose={() => setShowMoreInfo(false)}
        maxWidth='613px'
        width='613px'>
        <MoreInfoModal onClose={() => setShowMoreInfo(false)} />
      </Modal>
    </section>
  );
};

export default DoubleMateriality;
