import React from 'react';
import CheckboxWrapper from '../../inputUtils/checkboxWrapper/CheckboxWrapper';
import OldCheckbox from '../../checkbox/OldCheckbox';
import useYesNoOptions from '../../../../../customHooks/useYesNoOptions';
import { RadioButtonsJustified } from '../RadioButtonJustified';

type CheckboxWrapperType = Omit<React.ComponentProps<typeof CheckboxWrapper>, 'children'>;

type OwnProps = {
  value: SelectOptionFormat;
  onChange: (value: SelectOptionFormat) => void;
};
type Props = OwnProps & CheckboxWrapperType;

const FormYesNoRadio = (props: Props) => {
  const yesNoOptions = useYesNoOptions();
  return (
    <CheckboxWrapper {...props}>
      {yesNoOptions.map((option) => (
        <OldCheckbox
          key={option.id}
          text={option.name}
          onChange={(value: boolean) => {
            if (!value) return;
            props.onChange({
              id: option.id,
              name: option.name
            });
          }}
          selected={props.value.id === option.id}
          disabled={props.disabled}
        />
      ))}
    </CheckboxWrapper>
  );
};

type YesNoRadioJustifiedProps = {
  value: SelectOptionFormat;
  onChangeRadio: (value: SelectOptionFormat) => void;
  onChangeJustification: (value: string) => void;
  disabled?: {
    all?: boolean;
    yes?: boolean;
    no?: boolean;
  };
  translations?: {
    yes?: string;
    no?: string;
    justificationPlaceholder?: string;
  };
  whenToJustify: 'yes' | 'no';
  label?: string;
  tooltip?: string;
  justificationValue: string;
  error?: ErrorType;
  errorTextArea?: ErrorType;
};

const YesNoRadioJustified: React.FC<YesNoRadioJustifiedProps> = ({
  translations,
  whenToJustify,
  disabled,
  onChangeRadio,
  ...props
}) => {
  const yesNoOptions = useYesNoOptions({ asConst: true });

  // We need to swap the options if the whenToJustify is 'yes', at our current js version .toReversed() is not available
  const options = whenToJustify === 'yes' ? [yesNoOptions[1], yesNoOptions[0]] : [...yesNoOptions];

  return (
    <RadioButtonsJustified
      options={options}
      onChangeRadio={onChangeRadio}
      whenToJustify={[whenToJustify]}
      translations={translations}
      disabled={disabled}
      {...props}
    />
  );
};

/**
 * Component for Yes/No radio buttons with justification
 * @component
 *
 */
FormYesNoRadio.Justified = YesNoRadioJustified;

export default FormYesNoRadio;
