/* eslint-disable prettier/prettier */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import formatNumber from '../../../utils/formatNumber';
import numberToDecimal from '../../../utils/numberToDecimal';
import ButtonArrow from '../buttonArrow/ButtonArrow';
import TooltipChart from '../tooltip/TooltipChart';
import './styles.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChartScroll({ chartData, disabled, totalCo2 }) {
  const PAGINATED_SIZE = 6;

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [paginatedData, setPaginatedData] = useState(
    Number(localStorage.getItem('bar-chart')) ?? 0
  );
  const chartRef = useRef(null);

  const maxValue = Math.max(...chartData[0].map((item) => item.value));

  const chartDataPaginated = useMemo(
    () => chartData.map((array) => array.slice(paginatedData, paginatedData + PAGINATED_SIZE)),
    [paginatedData, chartData]
  );

  const handleRight = () => {
    if (paginatedData + PAGINATED_SIZE >= chartData[0].length) return;
    setPaginatedData((prev) => prev + PAGINATED_SIZE);
    localStorage.setItem('bar-chart', paginatedData + PAGINATED_SIZE);
  };
  const handleLeft = () => {
    if (paginatedData - PAGINATED_SIZE < 0) return;
    setPaginatedData((prev) => prev - PAGINATED_SIZE);
    localStorage.setItem('bar-chart', paginatedData - PAGINATED_SIZE);
  };

  const options = {
    devicePixelRatio: 4,
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x1: {
        position: 'bottom',
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          backdropColor: 'red',
          font: function (context) {
            const size = 10;
            return {
              size,
              family: 'Manrope',
              weight: 400,
              lineHeight: 1.2
            };
          },
          padding: 0,
          margin: 0
        }
      },
      ...(chartDataPaginated.length < 2 && {
        x2: {
          position: 'bottom',
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            color: 'rgba(25, 42, 62, 0.8)',
            font: function (context) {
              const size = 10;
              return {
                size,
                family: 'Manrope',
                weight: 400,
                lineHeight: 1.2
              };
            },
            padding: 0,
            callback: function (index) {
              let value = disabled ? 0 : chartDataPaginated[0][index].value;
              //if (value >= 1000) value = value / 1000;
              value = numberToDecimal(value / 1000);
              return formatNumber(value);
            }
          }
        }
      }),
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 0,
            family: 'Manrope',
            weight: 400,
            lineHeight: 1.2
          }
        },
        min: 0,
        max: maxValue
      }
    },
    hover: {
      onHover: (e) => {
        var el = document.getElementById('canvas1');
        el.style.cursor = e[0] ? 'pointer' : 'default';
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          if (chartRef && chartRef.current) {
            const tooltip = chartRef.current.tooltip;

            if (tooltip.opacity === 0) {
              setShowTooltip(false);
            } else {
              // assuming your tooltip is `position: fixed`
              // set position of tooltip
              const position = context.chart.canvas.getBoundingClientRect();
              const left = position.left + window.scrollX + tooltip.caretX;
              const top = position.top + window.scrollY + tooltip.caretY - 20;

              setLeft(left);
              setTop(top);
              if (disabled) {
                setValue('0 kg CO₂ eq.');
                setShowTooltip(true);
              } else {
                let value = tooltip.dataPoints[0].raw;
                value = (numberToDecimal(value / 1000) * 100) / totalCo2;
                setValue(`${formatNumber(numberToDecimal(value))}%`);
                setShowTooltip(true);
              }
            }
          }
        }
      }
    },
    font: {
      size: 10,
      family: 'Manrope',
      weight: 400,
      lineHeight: 1.2
    }
  };

  const datasets = [
    {
      label: 'company1',
      data: chartDataPaginated[0].map((elem) => elem.value),
      backgroundColor: chartDataPaginated[0].map((elem) => elem.color),
      barThickness: 12,
      borderRadius: 8
    }
  ];

  if (chartDataPaginated.length > 1) {
    datasets.push({
      label: 'company2',
      data: chartDataPaginated[1].map((elem) => elem.value),
      backgroundColor: chartDataPaginated[1].map((elem) =>
        elem.color !== 'rgba(0, 0, 0, 0)' ? `${elem.color}${50}` : 'rgba(0, 0, 0, 0)'
      ),
      barThickness: 12,
      borderRadius: 8
    });
  }

  const data = {
    labels: chartDataPaginated[0].map((elem) => elem.label),
    datasets
  };

  return (
    <>
      {showTooltip && <TooltipChart text={value} top={top} left={left} />}

      <div className='bar-chart-scroll-wrapper'>
        {paginatedData - PAGINATED_SIZE < 0 ? null : (
          <div className='arrow-button-wrapper'>
            <ButtonArrow onClick={handleLeft} type='left' />
          </div>
        )}
        <div className='chart-element-wrapper'>
          <Bar options={options} data={data} ref={chartRef} style={{ maxWidth: '100%' }} />
        </div>
        {paginatedData + PAGINATED_SIZE >= chartData[0].length ? null : (
          <div className='arrow-button-wrapper'>
            <ButtonArrow onClick={handleRight} type='right' />
          </div>
        )}
      </div>
    </>
  );
}

export default BarChartScroll;
