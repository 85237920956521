import { useParams } from 'react-router-dom';
import { getTotal } from '../components/LCADoughnutChart/utils';
import type {
  FrontLCAComponent,
  FrontLCAMethodology,
  LCAFoldersResponse
} from '../types/LCADetail';
import { PageParams } from '../types/PageParams';
import { useLCAFolders } from './useLCAFolders';
import { useLCAComponents } from './useLCAComponents';
import { adaptLCAFolderItems, adaptLCAFolders } from '../adapters/adaptLCAFolders';

type Params = {
  methodology?: FrontLCAMethodology;
  lcaFolder?: keyof LCAFoldersResponse;
  lcaComponentsUsed: FrontLCAComponent[];
};

export const useLCAData = ({ methodology, lcaComponentsUsed, lcaFolder }: Params) => {
  const { id } = useParams<PageParams>();

  const { data: lcaFolders, isLoading: isLoadingFolders } = useLCAFolders({
    id,
    methodologyId: methodology?.id,
    impactCategory: methodology?.impact_category
  });

  const { data: lcaComponents, isLoading: isLoadingComponents } = useLCAComponents({
    id,
    methodologyId: methodology?.id,
    impactCategory: methodology?.impact_category,
    parentId: lcaComponentsUsed[lcaComponentsUsed.length - 1]?.id,
    enabled: lcaComponentsUsed.length > 0
  });

  const isLoading = isLoadingComponents || isLoadingFolders;

  if (lcaComponentsUsed.length > 0) {
    const data = lcaComponents;

    const currentTotal = getTotal(data?.map((item) => Number(item.impact)) ?? [0]);

    const percentage = (currentTotal / (Number(methodology?.impact) ?? 0)) * 100;

    return { data, percentage, isLoading };
  }

  if (!lcaFolders) {
    return { data: undefined, percentage: 0, isLoading };
  }

  if (!lcaFolder) {
    const data = adaptLCAFolders(lcaFolders);

    return { data, percentage: 100, isLoading };
  }

  const data = lcaFolders[lcaFolder];

  const adaptedChildren = adaptLCAFolderItems(data);

  const currentTotal = getTotal(adaptedChildren.map((item) => Number(item.impact)));

  const percentage = (currentTotal / (Number(methodology?.impact) ?? 0)) * 100;

  return { data: adaptedChildren, percentage, isLoading };
};
