export const datapoints: Datapoint[] = [
  // {
  //   datapoint_id: 'BP-1-1',
  //   datapoint: 'disclosure_of_general_basis_for_preparation_of_sustainability_statement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-1-2',
  //   datapoint: 'basis_for_preparation_of_sustainability_statement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-1-3',
  //   datapoint:
  //     'scope_of_consolidation_of_consolidated_sustainability_statement_is_same_as_for_financial_statements',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-1-4',
  //   datapoint:
  //     'indication_of_subsidiary_undertakings_included_in_consolidation_that_are_exempted_from_individual_or_consolidated_sustainability_reporting',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-1-5',
  //   datapoint:
  //     'disclosure_of_extent_to_which_sustainability_statement_covers_upstream_and_downstream_value_chain',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-1-6',
  //   datapoint:
  //     'option_to_omit_specific_piece_of_information_corresponding_to_intellectual_property_know-how_or_results_of_innovation_has_been_used',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-1-7',
  //   datapoint:
  //     'option_allowed_by_member_state_to_omit_disclosure_of_impending_developments_or_matters_in_course_of_negotiation_has_been_used',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-1',
  //   datapoint: 'disclosures_in_relation_to_specific_circumstances',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-2',
  //   datapoint: 'medium-_or_long-term_time_horizons_defined_by_esrs_1_have_been_deviated_from',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-3',
  //   datapoint: 'disclosure_of_definitions_of_medium-_or_long-term_time_horizons',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-4',
  //   datapoint: 'disclosure_of_reasons_for_applying_different_definitions_of_time_horizons',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-5',
  //   datapoint: 'metrics_include_value_chain_data_estimated_using_indirect_sources',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-6',
  //   datapoint:
  //     'disclosure_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-7',
  //   datapoint:
  //     'description_of_basis_for_preparation_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-8',
  //   datapoint:
  //     'description_of_resulting_level_of_accuracy_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-9',
  //   datapoint:
  //     'description_of_planned_actions_to_improve_accuracy_in_future_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-10',
  //   datapoint:
  //     'disclosure_of_quantitative_metrics_and_monetary_amounts_disclosed_that_are_subject_to_high_level_of_measurement_uncertainty',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-11',
  //   datapoint: 'disclosure_of_sources_of_measurement_uncertainty',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-12',
  //   datapoint: 'disclosure_of_assumptions_approximations_and_judgements_made_in_measurement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-13',
  //   datapoint:
  //     'explanation_of_changes_in_preparation_and_presentation_of_sustainability_information_and_reasons_for_them',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-14',
  //   datapoint: 'disclosure_of_revised_comparative_figures',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-15',
  //   datapoint:
  //     'disclosure_of_difference_between_figures_disclosed_in_preceding_period_and_revised_comparative_figures',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-16',
  //   datapoint: 'disclosure_of_nature_of_prior_period_material_errors',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-17',
  //   datapoint: 'disclosure_of_corrections_for_prior_periods_included_in_sustainability_statement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-18',
  //   datapoint: 'disclosure_of_why_correction_of_prior_period_errors_is_not_practicable',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-19',
  //   datapoint:
  //     'disclosure_of_other_legislation_or_generally_accepted_sustainability_reporting_standards_and_frameworks_based_on_which_information_has_been_included_in_sustainability_statement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-20',
  //   datapoint: 'disclosure_of_reference_to_paragraphs_of_standard_or_framework_applied',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-21',
  //   datapoint:
  //     'european_standards_approved_by_european_standardisation_system_(iso/iec_or_cen/cenelec_standards)_have_been_relied_on',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-22',
  //   datapoint:
  //     'disclosure_of_extent_to_which_data_and_processes_that_are_used_for_sustainability_reporting_purposes_have_been_verified_by_external_assurance_provider_and_found_to_conform_to_corresponding_iso/iec_or_cen/cenelec_standard',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-23',
  //   datapoint: 'list_of_drs_or_dps_mandated_by_a_disclosure_requirement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-24',
  //   datapoint: 'topics_(e4_s1_s2_s3_s4)_have_been_assessed_to_be_material',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-25',
  //   datapoint: 'list_of_sustainability_matters_assessed_to_be_material_(phase-in)',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-26',
  //   datapoint:
  //     'disclosure_of_how_business_model_and_strategy_take_account_of_impacts_related_to_sustainability_matters_assessed_to_be_material_(phase-in)',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-27',
  //   datapoint:
  //     'description_of_any_time-bound_targets_set_related_to_sustainability_matters_assessed_to_be_material_(phase-in)_and_progress_made_towards_achieving_those_targets',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-28',
  //   datapoint:
  //     'description_of_policies_related_to_sustainability_matters_assessed_to_be_material_(phase-in)',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-29',
  //   datapoint:
  //     'description_of_actions_taken_to_identify_monitor_prevent_mitigate_remediate_or_bring_end_to_actual_or_potential_adverse_impacts_related_to_sustainability_matters_assessed_to_be_material_(phase-in)_and_result_of_such_actions',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'BP-2-30',
  //   datapoint:
  //     'disclosure_of_metrics_related_to_sustainability_matters_assessed_to_be_material_(phase-in)',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-1',
  //   datapoint:
  //     'information_about_composition_and_diversity_of_members_of_administrative_management_and_supervisory_bodies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-2',
  //   datapoint: 'number_of_executive_members',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-3',
  //   datapoint: 'number_of_non-executive_members',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-4',
  //   datapoint: 'information_about_representation_of_employees_and_other_workers',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-5',
  //   datapoint:
  //     "information_about_member's_experience_relevant_to_sectors_products_and_geographic_locations_of_undertaking",
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-6',
  //   datapoint: 'percentage_of_members_of_administrative_management_and_supervisory_bodies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-7',
  //   datapoint: "board's_gender_diversity_ratio",
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-8',
  //   datapoint: 'percentage_of_independent_board_members',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-9',
  //   datapoint:
  //     'information_about_roles_and_responsibilities_of_administrative_management_and_supervisory_bodies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-10',
  //   datapoint:
  //     'information_about_identity_of_administrative_management_and_supervisory_bodies_or_individual(s)_within_body_responsible_for_oversight_of_impacts_risks_and_opportunities',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-11',
  //   datapoint:
  //     "disclosure_of_how_body's_or_individuals_within_body_responsibilities_for_impacts_risks_and_opportunities_are_reflected_in_undertaking's_terms_of_reference_board_mandates_and_other_related_policies",
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-12',
  //   datapoint:
  //     "description_of_management's_role_in_governance_processes_controls_and_procedures_used_to_monitor_manage_and_oversee_impacts_risks_and_opportunities",
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-13',
  //   datapoint:
  //     "description_of_how_oversight_is_exercised_over_management-level_position_or_committee_to_which_management's_role_is_delegated_to",
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-14',
  //   datapoint:
  //     'information_about_reporting_lines_to_administrative_management_and_supervisory_bodies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-15',
  //   datapoint:
  //     'disclosure_of_how_dedicated_controls_and_procedures_are_integrated_with_other_internal_functions',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-16',
  //   datapoint:
  //     'disclosure_of_how_administrative_management_and_supervisory_bodies_and_senior_executive_management_oversee_setting_of_targets_related_to_material_impacts_risks_and_opportunities_and_how_progress_towards_them_is_monitored',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-17',
  //   datapoint:
  //     'disclosure_of_how_administrative_management_and_supervisory_bodies_determine_whether_appropriate_skills_and_expertise_are_available_or_will_be_developed_to_oversee_sustainability_matters',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-18',
  //   datapoint:
  //     'information_about_sustainability-related_expertise_that_bodies_either_directly_possess_or_can_leverage',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-1-19',
  //   datapoint:
  //     'disclosure_of_how_sustainability-related_skills_and_expertise_relate_to_material_impacts_risks_and_opportunities',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-2-1',
  //   datapoint:
  //     'disclosure_of_whether_by_whom_and_how_frequently_administrative_management_and_supervisory_bodies_are_informed_about_material_impacts_risks_and_opportunities_implementation_of_due_diligence_and_results_and_effectiveness_of_policies_actions_metrics_and_targets_adopted_to_address_them',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-2-2',
  //   datapoint:
  //     'disclosure_of_how_administrative_management_and_supervisory_bodies_consider_impacts_risks_and_opportunities_when_overseeing_strategy_decisions_on_major_transactions_and_risk_management_process',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-2-3',
  //   datapoint:
  //     'disclosure_of_list_of_material_impacts_risks_and_opportunities_addressed_by_administrative_management_and_supervisory_bodies_or_their_relevant_committees',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-2-4',
  //   datapoint:
  //     'disclosure_of_how_governance_bodies_ensure_that_appropriate_mechanism_for_performance_monitoring_is_in_place',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-3-1',
  //   datapoint:
  //     'incentive_schemes_and_remuneration_policies_linked_to_sustainability_matters_for_members_of_administrative_management_and_supervisory_bodies_exist',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-3-2',
  //   datapoint: 'description_of_key_characteristics_of_incentive_schemes',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-3-3',
  //   datapoint:
  //     'description_of_specific_sustainability-related_targets_and_(or)_impacts_used_to_assess_performance_of_members_of_administrative_management_and_supervisory_bodies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-3-4',
  //   datapoint:
  //     'disclosure_of_how_sustainability-related_performance_metrics_are_considered_as_performance_benchmarks_or_included_in_remuneration_policies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-3-5',
  //   datapoint:
  //     'percentage_of_variable_remuneration_dependent_on_sustainability-related_targets_and_(or)_impacts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'GOV-3-6',
  //   datapoint:
  //     'description_of_level_in_undertaking_at_which_terms_of_incentive_schemes_are_approved_and_updated',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-4-1',
  //   datapoint:
  //     'disclosure_of_mapping_of_information_provided_in_sustainability_statement_about_due_diligence_process',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-5-1',
  //   datapoint:
  //     'description_of_scope_main_features_and_components_of_risk_management_and_internal_control_processes_and_systems_in_relation_to_sustainability_reporting',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-5-2',
  //   datapoint: 'description_of_risk_assessment_approach_followed',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-5-3',
  //   datapoint: 'description_of_main_risks_identified_and_their_mitigation_strategies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-5-4',
  //   datapoint:
  //     'description_of_how_findings_of_risk_assessment_and_internal_controls_as_regards_sustainability_reporting_process_have_been_integrated_into_relevant_internal_functions_and_processes',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'GOV-5-5',
  //   datapoint:
  //     'description_of_periodic_reporting_of_findings_of_risk_assessment_and_internal_controls_to_administrative_management_and_supervisory_bodies',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-1',
  //   datapoint:
  //     'disclosure_of_information_about_key_elements_of_general_strategy_that_relate_to_or_affect_sustainability_matters',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-2',
  //   datapoint: 'description_of_significant_groups_of_products_and_(or)_services_offered',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-3',
  //   datapoint: 'description_of_significant_markets_and_(or)_customer_groups_served',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-4',
  //   datapoint: 'total_number_of_employees_(head_count)',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-5',
  //   datapoint: 'number_of_employees_(head_count)',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-6',
  //   datapoint: 'description_of_products_and_services_that_are_banned_in_certain_markets',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-7',
  //   datapoint: 'total_revenue',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-8',
  //   datapoint: 'revenue_by_esrs_sectors',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-9',
  //   datapoint:
  //     'list_of_additional_significant_esrs_sectors_in_which_significant_activities_are_developed_or_in_which_undertaking_is_or_may_be_connected_to_material_impacts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-10',
  //   datapoint: 'undertaking_is_active_in_fossil_fuel_(coal_oil_and_gas)_sector',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-11',
  //   datapoint: 'revenue_from_fossil_fuel_(coal_oil_and_gas)_sector',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-12',
  //   datapoint: 'revenue_from_coal',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-13',
  //   datapoint: 'revenue_from_oil',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-14',
  //   datapoint: 'revenue_from_gas',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-15',
  //   datapoint: 'revenue_from_taxonomy-aligned_economic_activities_related_to_fossil_gas',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-16',
  //   datapoint: 'undertaking_is_active_in_chemicals_production',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-17',
  //   datapoint: 'revenue_from_chemicals_production',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-18',
  //   datapoint: 'undertaking_is_active_in_controversial_weapons',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-19',
  //   datapoint: 'revenue_from_controversial_weapons',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-20',
  //   datapoint: 'undertaking_is_active_in_cultivation_and_production_of_tobacco',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-21',
  //   datapoint: 'revenue_from_cultivation_and_production_of_tobacco',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'quantitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-22',
  //   datapoint:
  //     'description_of_sustainability-related_goals_in_terms_of_significant_groups_of_products_and_services_customer_categories_geographical_areas_and_relationships_with_stakeholders',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-23',
  //   datapoint:
  //     'disclosure_of_assessment_of_current_significant_products_and_(or)_services_and_significant_markets_and_customer_groups_in_relation_to_sustainability-related_goals',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-24',
  //   datapoint: 'disclosure_of_elements_of_strategy_that_relate_to_or_impact_sustainability_matters',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-25',
  //   datapoint: 'list_of_esrs_sectors_that_are_significant_for_undertaking',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-26',
  //   datapoint: 'description_of_business_model_and_value_chain',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-27',
  //   datapoint: 'description_of_inputs_and_approach_to_gathering_developing_and_securing_inputs',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-28',
  //   datapoint:
  //     'description_of_outputs_and_outcomes_in_terms_of_current_and_expected_benefits_for_customers_investors_and_other_stakeholders',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-1-29',
  //   datapoint:
  //     'description_of_main_features_of_upstream_and_downstream_value_chain_and_undertakings_position_in_value_chain',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-1',
  //   datapoint: 'description_of_stakeholder_engagement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-2',
  //   datapoint: 'description_of_key_stakeholders',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-3',
  //   datapoint: 'description_of_categories_of_stakeholders_for_which_engagement_occurs',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-4',
  //   datapoint: 'description_of_how_stakeholder_engagement_is_organised',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-5',
  //   datapoint: 'description_of_purpose_of_stakeholder_engagement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-6',
  //   datapoint: 'description_of_how_outcome_of_stakeholder_engagement_is_taken_into_account',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-7',
  //   datapoint:
  //     "description_of_understanding_of_interests_and_views_of_key_stakeholders_as_they_relate_to_undertaking's_strategy_and_business_model",
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-8',
  //   datapoint: 'description_of_amendments_to_strategy_and_(or)_business_model',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-9',
  //   datapoint:
  //     'description_of_how_strategy_and_(or)_business_model_have_been_amended_or_are_expected_to_be_amended_to_address_interests_and_views_of_stakeholders',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-10',
  //   datapoint: 'description_of_any_further_steps_that_are_being_planned_and_in_what_timeline',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-11',
  //   datapoint:
  //     'further_steps_that_are_being_planned_are_likely_to_modify_relationship_with_and_views_of_stakeholders',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-2-12',
  //   datapoint:
  //     'description_of_how_administrative_management_and_supervisory_bodies_are_informed_about_views_and_interests_of_affected_stakeholders_with_regard_to_sustainability-related_impacts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-1',
  //   datapoint: 'description_of_material_impacts_resulting_from_materiality_assessment',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-2',
  //   datapoint:
  //     'description_of_material_risks_and_opportunities_resulting_from_materiality_assessment',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-3',
  //   datapoint:
  //     'disclosure_of_current_and_anticipated_effects_of_material_impacts_risks_and_opportunities_on_business_model_value_chain_strategy_and_decision-making_and_how_undertaking_has_responded_or_plans_to_respond_to_these_effects',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-4',
  //   datapoint:
  //     'disclosure_of_how_material_negative_and_positive_impacts_affect_(or_are_likely_to_affect)_people_or_environment',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-5',
  //   datapoint:
  //     'disclosure_of_how_impacts_originate_from_or_are_connected_to_strategy_and_business_model',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-6',
  //   datapoint: 'disclosure_of_reasonably_expected_time_horizons_of_impacts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-7',
  //   datapoint:
  //     'description_of_nature_of_activities_or_business_relationships_through_which_undertaking_is_involved_with_material_impacts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-8',
  //   datapoint:
  //     'disclosure_of_current_financial_effects_of_material_risks_and_opportunities_on_financial_position_financial_performance_and_cash_flows_and_material_risks_and_opportunities_for_which_there_is_significant_risk_of_material_adjustment_within_next_annual_reporting_period_to_carrying_amounts_of_assets_and_liabilities_reported_in_related_financial_statements',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-9',
  //   datapoint:
  //     'disclosure_of_anticipated_financial_effects_of_material_risks_and_opportunities_on_financial_position_financial_performance_and_cash_flows_over_short-_medium-_and_long-term',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-10',
  //   datapoint:
  //     'information_about_resilience_of_strategy_and_business_model_regarding_capacity_to_address_material_impacts_and_risks_and_to_take_advantage_of_material_opportunities',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-11',
  //   datapoint:
  //     'disclosure_of_changes_to_material_impacts_risks_and_opportunities_compared_to_previous_reporting_period',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'SBM-3-12',
  //   datapoint:
  //     'disclosure_of_specification_of_impacts_risks_and_opportunities_that_are_covered_by_esrs_disclosure_requirements_as_opposed_to_those_covered_by_additional_entity-specific_disclosures',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-1',
  //   datapoint:
  //     'description_of_methodologies_and_assumptions_applied_in_process_to_identify_impacts_risks_and_opportunities',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-2',
  //   datapoint:
  //     'description_of_process_to_identify_assess_prioritise_and_monitor_potential_and_actual_impacts_on_people_and_environment_informed_by_due_diligence_process',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-3',
  //   datapoint:
  //     'description_of_how_process_focuses_on_specific_activities_business_relationships_geographies_or_other_factors_that_give_rise_to_heightened_risk_of_adverse_impacts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-4',
  //   datapoint:
  //     'description_of_how_process_considers_impacts_with_which_undertaking_is_involved_through_own_operations_or_as_result_of_business_relationships',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-5',
  //   datapoint:
  //     'description_of_how_process_includes_consultation_with_affected_stakeholders_to_understand_how_they_may_be_impacted_and_with_external_experts',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-6',
  //   datapoint:
  //     'description_of_how_process_prioritises_negative_impacts_based_on_their_relative_severity_and_likelihood_and_positive_impacts_based_on_their_relative_scale_scope_and_likelihood_and_determines_which_sustainability_matters_are_material_for_reporting_purposes',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-7',
  //   datapoint:
  //     'description_of_process_used_to_identify_assess_prioritise_and_monitor_risks_and_opportunities_that_have_or_may_have_financial_effects',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-8',
  //   datapoint:
  //     'description_of_how_connections_of_impacts_and_dependencies_with_risks_and_opportunities_that_may_arise_from_those_impacts_and_dependencies_have_been_considered',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-9',
  //   datapoint:
  //     'description_of_how_likelihood_magnitude_and_nature_of_effects_of_identified_risks_and_opportunities_have_been_assessed',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-10',
  //   datapoint:
  //     'description_of_how_sustainability-related_risks_relative_to_other_types_of_risks_have_been_prioritised',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-11',
  //   datapoint: 'description_of_decision-making_process_and_related_internal_control_procedures',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-12',
  //   datapoint:
  //     'description_of_extent_to_which_and_how_process_to_identify_assess_and_manage_impacts_and_risks_is_integrated_into_overall_risk_management_process_and_used_to_evaluate_overall_risk_profile_and_risk_management_processes',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-13',
  //   datapoint:
  //     'description_of_extent_to_which_and_how_process_to_identify_assess_and_manage_opportunities_is_integrated_into_overall_management_process',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-14',
  //   datapoint:
  //     'description_of_input_parameters_used_in_process_to_identify_assess_and_manage_material_impacts_risks_and_opportunities',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-1-15',
  //   datapoint:
  //     'description_of_how_process_to_identify_assess_and_manage_impacts_risks_and_opportunities_has_changed_compared_to_prior_reporting_period',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-1',
  //   datapoint:
  //     'disclosure_of_list_of_data_points_that_derive_from_other_eu_legislation_and_information_on_their_location_in_sustainability_statement',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-2',
  //   datapoint:
  //     'disclosure_of_list_of_esrs_disclosure_requirements_complied_with_in_preparing_sustainability_statement_following_outcome_of_materiality_assessment',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-3',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_e1_climate_change',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-4',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_e2_pollution',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-5',
  //   datapoint:
  //     'explanation_of_negative_materiality_assessment_for_esrs_e3_water_and_marine_resources',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-6',
  //   datapoint:
  //     'explanation_of_negative_materiality_assessment_for_esrs_e4_biodiversity_and_ecosystems',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-7',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_e5_circular_economy',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-8',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s1_own_workforce',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-9',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s2_workers_in_value_chain',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-10',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s3_affected_communities',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-11',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s4_consumers_and_end-users',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-12',
  //   datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_g1_business_conduct',
  //   state: 'pending',
  //   relevance: 'not_mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // },
  // {
  //   datapoint_id: 'IRO-2-13',
  //   datapoint:
  //     'explanation_of_how_material_information_to_be_disclosed_in_relation_to_material_impacts_risks_and_opportunities_has_been_determined',
  //   state: 'pending',
  //   relevance: 'mandatory',
  //   automatic_calculated_by_dcycle: false,
  //   type: 'qualitative'
  // }
  {
    datapoint_id: 'BP-1-1',
    datapoint: 'basis_for_preparation_of_sustainability_statement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-1-2',
    datapoint:
      'scope_of_consolidation_of_consolidated_sustainability_statement_is_same_as_for_financial_statements',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-1-3',
    datapoint:
      'indication_of_subsidiary_undertakings_included_in_consolidation_that_are_exempted_from_individual_or_consolidated_sustainability_reporting',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-1-4',
    datapoint:
      'disclosure_of_extent_to_which_sustainability_statement_covers_upstream_and_downstream_value_chain',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-1-5',
    datapoint:
      'option_to_omit_specific_piece_of_information_corresponding_to_intellectual_property_know_how_or_results_of_innovation_has_been_used',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-1-6',
    datapoint:
      'option_allowed_by_member_state_to_omit_disclosure_of_impending_developments_or_matters_in_course_of_negotiation_has_been_used',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-1',
    datapoint: 'disclosure_of_definitions_of_medium_or_long_term_time_horizons',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-2',
    datapoint: 'disclosure_of_reasons_for_applying_different_definitions_of_time_horizons',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-3',
    datapoint:
      'disclosure_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-4',
    datapoint:
      'description_of_basis_for_preparation_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-5',
    datapoint:
      'description_of_resulting_level_of_accuracy_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-6',
    datapoint:
      'description_of_planned_actions_to_improve_accuracy_in_future_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-7',
    datapoint:
      'disclosure_of_quantitative_metrics_and_monetary_amounts_disclosed_that_are_subject_to_high_level_of_measurement_uncertainty',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-8',
    datapoint: 'disclosure_of_sources_of_measurement_uncertainty',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-9',
    datapoint: 'disclosure_of_assumptions_approximations_and_judgements_made_in_measurement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-10',
    datapoint:
      'explanation_of_changes_in_preparation_and_presentation_of_sustainability_information_and_reasons_for_them',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-11',
    datapoint:
      'adjustment_of_comparative_information_for_one_or_more_prior_periods_is_impracticable',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-12',
    datapoint:
      'disclosure_of_difference_between_figures_disclosed_in_preceding_period_and_revised_comparative_figures',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'BP-2-13',
    datapoint: 'disclosure_of_nature_of_prior_period_material_errors',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-14',
    datapoint: 'disclosure_of_corrections_for_prior_periods_included_in_sustainability_statement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-15',
    datapoint: 'disclosure_of_why_correction_of_prior_period_errors_is_not_practicable',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-16',
    datapoint:
      'disclosure_of_other_legislation_or_generally_accepted_sustainability_reporting_standards_and_frameworks_based_on_which_information_has_been_included_in_sustainability_statement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-17',
    datapoint: 'disclosure_of_reference_to_paragraphs_of_standard_or_framework_applied',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-18',
    datapoint:
      'european_standards_approved_by_european_standardisation_system_iso_iec_or_cen_cenelec_standards_have_been_relied_on',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-19',
    datapoint:
      'disclosure_of_extent_to_which_data_and_processes_that_are_used_for_sustainability_reporting_purposes_have_been_verified_by_external_assurance_provider_and_found_to_conform_to_corresponding_iso_iec_or_cen_cenelec_standard',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-20',
    datapoint: 'list_of_drs_or_dps_incorporated_by_reference',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-21',
    datapoint: 'topics_e4_s1_s2_s3_s4_have_been_assessed_to_be_material',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-22',
    datapoint: 'list_of_sustainability_matters_assessed_to_be_material_phase_in',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-23',
    datapoint:
      'disclosure_of_how_business_model_and_strategy_take_account_of_impacts_related_to_sustainability_matters_assessed_to_be_material_phase_in',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-24',
    datapoint:
      'description_of_any_time_bound_targets_set_related_to_sustainability_matters_assessed_to_be_material_phase_in_and_progress_made_towards_achieving_those_targets',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-25',
    datapoint:
      'description_of_policies_related_to_sustainability_matters_assessed_to_be_material_phase_in',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-26',
    datapoint:
      'description_of_actions_taken_to_identify_monitor_prevent_mitigate_remediate_or_bring_end_to_actual_or_potential_adverse_impacts_related_to_sustainability_matters_assessed_to_be_material_phase_in_and_result_of_such_actions',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'BP-2-27',
    datapoint:
      'disclosure_of_metrics_related_to_sustainability_matters_assessed_to_be_material_phase_in',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-1',
    datapoint: 'number_of_executive_members',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'GOV-1-2',
    datapoint: 'number_of_non_executive_members',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'GOV-1-3',
    datapoint: 'information_about_representation_of_employees_and_other_workers',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-4',
    datapoint:
      'information_about_members_experience_relevant_to_sectors_products_and_geographic_locations_of_undertaking',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-5',
    datapoint:
      'percentage_of_members_of_administrative_management_and_supervisory_bodies_by_gender_and_other_aspects_of_diversity',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'GOV-1-6',
    datapoint: 'boards_gender_diversity_ratio',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'GOV-1-7',
    datapoint: 'percentage_of_independent_board_members',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'GOV-1-8',
    datapoint:
      'information_about_identity_of_administrative_management_and_supervisory_bodies_or_individual_s_within_body_responsible_for_oversight_of_impacts_risks_and_opportunities',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-9',
    datapoint:
      'disclosure_of_how_bodys_or_individuals_within_body_responsibilities_for_impacts_risks_and_opportunities_are_reflected_in_undertakings_terms_of_reference_board_mandates_and_other_related_policies',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-10',
    datapoint:
      'description_of_managements_role_in_governance_processes_controls_and_procedures_used_to_monitor_manage_and_oversee_impacts_risks_and_opportunities',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-11',
    datapoint:
      'description_of_how_oversight_is_exercised_over_management_level_position_or_committee_to_which_managements_role_is_delegated_to',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-12',
    datapoint:
      'information_about_reporting_lines_to_administrative_management_and_supervisory_bodies',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-13',
    datapoint:
      'disclosure_of_how_dedicated_controls_and_procedures_are_integrated_with_other_internal_functions',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-14',
    datapoint:
      'disclosure_of_how_administrative_management_and_supervisory_bodies_and_senior_executive_management_oversee_setting_of_targets_related_to_material_impacts_risks_and_opportunities_and_how_progress_towards_them_is_monitored',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-15',
    datapoint:
      'disclosure_of_how_administrative_management_and_supervisory_bodies_determine_whether_appropriate_skills_and_expertise_are_available_or_will_be_developed_to_oversee_sustainability_matters',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-16',
    datapoint:
      'information_about_sustainability_related_expertise_that_bodies_either_directly_possess_or_can_leverage',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-1-17',
    datapoint:
      'disclosure_of_how_sustainability_related_skills_and_expertise_relate_to_material_impacts_risks_and_opportunities',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-2-1',
    datapoint:
      'disclosure_of_whether_by_whom_and_how_frequently_administrative_management_and_supervisory_bodies_are_informed_about_material_impacts_risks_and_opportunities_implementation_of_due_diligence_and_results_and_effectiveness_of_policies_actions_metrics_and_targets_adopted_to_address_them',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-2-2',
    datapoint:
      'disclosure_of_how_administrative_management_and_supervisory_bodies_consider_impacts_risks_and_opportunities_when_overseeing_strategy_decisions_on_major_transactions_and_risk_management_process',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-2-3',
    datapoint:
      'disclosure_of_list_of_material_impacts_risks_and_opportunities_addressed_by_administrative_management_and_supervisory_bodies_or_their_relevant_committees',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-2-4',
    datapoint:
      'disclosure_of_how_governance_bodies_ensure_that_appropriate_mechanism_for_performance_monitoring_is_in_place',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-3-1',
    datapoint:
      'incentive_schemes_and_remuneration_policies_linked_to_sustainability_matters_for_members_of_administrative_management_and_supervisory_bodies_exist',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-3-2',
    datapoint: 'description_of_key_characteristics_of_incentive_schemes',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-3-3',
    datapoint:
      'description_of_specific_sustainability_related_targets_and_or_impacts_used_to_assess_performance_of_members_of_administrative_management_and_supervisory_bodies',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-3-4',
    datapoint:
      'disclosure_of_how_sustainability_related_performance_metrics_are_considered_as_performance_benchmarks_or_included_in_remuneration_policies',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-3-5',
    datapoint:
      'percentage_of_variable_remuneration_dependent_on_sustainability_related_targets_and_or_impacts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'GOV-3-6',
    datapoint:
      'description_of_level_in_undertaking_at_which_terms_of_incentive_schemes_are_approved_and_updated',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-4-1',
    datapoint:
      'disclosure_of_mapping_of_information_provided_in_sustainability_statement_about_due_diligence_process',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-5-1',
    datapoint:
      'description_of_scope_main_features_and_components_of_risk_management_and_internal_control_processes_and_systems_in_relation_to_sustainability_reporting',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-5-2',
    datapoint: 'description_of_risk_assessment_approach_followed',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-5-3',
    datapoint: 'description_of_main_risks_identified_and_their_mitigation_strategies',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-5-4',
    datapoint:
      'description_of_how_findings_of_risk_assessment_and_internal_controls_as_regards_sustainability_reporting_process_have_been_integrated_into_relevant_internal_functions_and_processes',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'GOV-5-5',
    datapoint:
      'description_of_periodic_reporting_of_findings_of_risk_assessment_and_internal_controls_to_administrative_management_and_supervisory_bodies',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-1',
    datapoint: 'description_of_significant_groups_of_products_and_or_services_offered',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-2',
    datapoint: 'description_of_significant_markets_and_or_customer_groups_served',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-3',
    datapoint: 'total_number_of_employees_head_count',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-4',
    datapoint: 'number_of_employees_head_count',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-5',
    datapoint: 'description_of_products_and_services_that_are_banned_in_certain_markets',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-6',
    datapoint: 'total_revenue',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-7',
    datapoint: 'revenue_by_significant_esrs_sectors',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-8',
    datapoint:
      'list_of_additional_significant_esrs_sectors_in_which_significant_activities_are_developed_or_in_which_undertaking_is_or_may_be_connected_to_material_impacts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-9',
    datapoint: 'undertaking_is_active_in_fossil_fuel_coal_oil_and_gas_sector',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-10',
    datapoint: 'revenue_from_fossil_fuel_coal_oil_and_gas_sector',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-11',
    datapoint: 'revenue_from_coal',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-12',
    datapoint: 'revenue_from_oil',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-13',
    datapoint: 'revenue_from_gas',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-14',
    datapoint: 'revenue_from_taxonomy_aligned_economic_activities_related_to_fossil_gas',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-15',
    datapoint: 'undertaking_is_active_in_chemicals_production',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-16',
    datapoint: 'revenue_from_chemicals_production',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-17',
    datapoint: 'undertaking_is_active_in_controversial_weapons',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: true,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-18',
    datapoint: 'revenue_from_controversial_weapons',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-19',
    datapoint: 'undertaking_is_active_in_cultivation_and_production_of_tobacco',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-20',
    datapoint: 'revenue_from_cultivation_and_production_of_tobacco',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'quantitative'
  },
  {
    datapoint_id: 'SBM-1-21',
    datapoint:
      'description_of_sustainability_related_goals_in_terms_of_significant_groups_of_products_and_services_customer_categories_geographical_areas_and_relationships_with_stakeholders',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-22',
    datapoint:
      'disclosure_of_assessment_of_current_significant_products_and_or_services_and_significant_markets_and_customer_groups_in_relation_to_sustainability_related_goals',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-23',
    datapoint: 'disclosure_of_elements_of_strategy_that_relate_to_or_impact_sustainability_matters',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-24',
    datapoint: 'list_of_esrs_sectors_that_are_significant_for_undertaking',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-25',
    datapoint: 'description_of_business_model_and_value_chain',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-26',
    datapoint: 'description_of_inputs_and_approach_to_gathering_developing_and_securing_inputs',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-27',
    datapoint:
      'description_of_outputs_and_outcomes_in_terms_of_current_and_expected_benefits_for_customers_investors_and_other_stakeholders',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-1-28',
    datapoint:
      'description_of_main_features_of_upstream_and_downstream_value_chain_and_undertakings_position_in_value_chain',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-1',
    datapoint: 'description_of_stakeholder_engagement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-2',
    datapoint: 'description_of_key_stakeholders',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-3',
    datapoint: 'description_of_categories_of_stakeholders_for_which_engagement_occurs',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-4',
    datapoint: 'description_of_how_stakeholder_engagement_is_organised',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-4',
    datapoint: 'description_of_purpose_of_stakeholder_engagement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-5',
    datapoint: 'description_of_how_outcome_of_stakeholder_engagement_is_taken_into_account',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-6',
    datapoint:
      'description_of_understanding_of_interests_and_views_of_key_stakeholders_as_they_relate_to_undertaking_strategy_and_business_model',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-7',
    datapoint: 'description_of_amendments_to_strategy_and_or_business_model',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-8',
    datapoint:
      'description_of_how_strategy_and_or_business_model_have_been_amended_or_are_expected_to_be_amended_to_address_interests_and_views_of_stakeholders',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-9',
    datapoint: 'description_of_any_further_steps_that_are_being_planned_and_in_what_timeline',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-10',
    datapoint:
      'further_steps_that_are_being_planned_are_likely_to_modify_relationship_with_and_views_of_stakeholders',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-2-11',
    datapoint:
      'description_of_how_administrative_management_and_supervisory_bodies_are_informed_about_views_and_interests_of_affected_stakeholders_with_regard_to_sustainability_related_impacts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-1',
    datapoint: 'description_of_material_impacts_resulting_from_materiality_assessment',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-2',
    datapoint:
      'description_of_material_risks_and_opportunities_resulting_from_materiality_assessment',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-3',
    datapoint:
      'disclosure_of_current_and_anticipated_effects_of_material_impacts_risks_and_opportunities_on_business_model_value_chain_strategy_and_decision_making_and_how_undertaking_has_responded_or_plans_to_respond_to_these_effects',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-4',
    datapoint:
      'disclosure_of_how_material_negative_and_positive_impacts_affect_or_are_likely_to_affect_people_or_environment',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-5',
    datapoint:
      'disclosure_of_whether_and_how_material_impacts_originate_from_or_are_connected_to_strategy_and_business_model',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-6',
    datapoint: 'disclosure_of_reasonably_expected_time_horizons_of_material_impacts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-7',
    datapoint:
      'description_of_nature_of_activities_or_business_relationships_through_which_undertaking_is_involved_with_material_impacts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-8',
    datapoint:
      'disclosure_of_current_financial_effects_of_material_risks_and_opportunities_on_financial_position_financial_performance_and_cash_flows_and_material_risks_and_opportunities_for_which_there_is_significant_risk_of_material_adjustment_within_next_annual_reporting_period_to_carrying_amounts_of_assets_and_liabilities_reported_in_related_financial_statements',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-9',
    datapoint:
      'disclosure_of_anticipated_financial_effects_of_material_risks_and_opportunities_on_financial_position_financial_performance_and_cash_flows_over_short_medium_and_long_term',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-10',
    datapoint:
      'information_about_resilience_of_strategy_and_business_model_regarding_capacity_to_address_material_impacts_and_risks_and_to_take_advantage_of_material_opportunities',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-11',
    datapoint:
      'disclosure_of_changes_to_material_impacts_risks_and_opportunities_compared_to_previous_reporting_period',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'SBM-3-12',
    datapoint:
      'disclosure_of_specification_of_impacts_risks_and_opportunities_that_are_covered_by_esrs_disclosure_requirements_as_opposed_to_those_covered_by_additional_entity_specific_disclosures',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-1',
    datapoint:
      'description_of_methodologies_and_assumptions_applied_in_process_to_identify_impacts_risks_and_opportunities',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-2',
    datapoint:
      'description_of_process_to_identify_assess_prioritise_and_monitor_potential_and_actual_impacts_on_people_and_environment_informed_by_due_diligence_process',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-3',
    datapoint:
      'description_of_how_process_focuses_on_specific_activities_business_relationships_geographies_or_other_factors_that_give_rise_to_heightened_risk_of_adverse_impacts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-4',
    datapoint:
      'description_of_how_process_considers_impacts_with_which_undertaking_is_involved_through_own_operations_or_as_result_of_business_relationships',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-5',
    datapoint:
      'description_of_how_process_includes_consultation_with_affected_stakeholders_to_understand_how_they_may_be_impacted_and_with_external_experts',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-6',
    datapoint:
      'description_of_how_process_prioritises_negative_impacts_based_on_their_relative_severity_and_likelihood_and_positive_impacts_based_on_their_relative_scale_scope_and_likelihood_and_determines_which_sustainability_matters_are_material_for_reporting_purposes',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-7',
    datapoint:
      'description_of_process_used_to_identify_assess_prioritise_and_monitor_risks_and_opportunities_that_have_or_may_have_financial_effects',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-8',
    datapoint:
      'description_of_how_connections_of_impacts_and_dependencies_with_risks_and_opportunities_that_may_arise_from_those_impacts_and_dependencies_have_been_considered',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-9',
    datapoint:
      'description_of_how_likelihood_magnitude_and_nature_of_effects_of_identified_risks_and_opportunities_have_been_assessed',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-10',
    datapoint:
      'description_of_how_sustainability_related_risks_relative_to_other_types_of_risks_have_been_prioritised',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-11',
    datapoint: 'description_of_decision_making_process_and_related_internal_control_procedures',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-12',
    datapoint:
      'description_of_extent_to_which_and_how_process_to_identify_assess_and_manage_impacts_and_risks_is_integrated_into_overall_risk_management_process_and_used_to_evaluate_overall_risk_profile_and_risk_management_processes',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-13',
    datapoint:
      'description_of_extent_to_which_and_how_process_to_identify_assess_and_manage_opportunities_is_integrated_into_overall_management_process',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-14',
    datapoint:
      'description_of_input_parameters_used_in_process_to_identify_assess_and_manage_material_impacts_risks_and_opportunities',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-1-15',
    datapoint:
      'description_of_how_process_to_identify_assess_and_manage_impacts_risks_and_opportunities_has_changed_compared_to_prior_reporting_period',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-1',
    datapoint:
      'disclosure_of_list_of_data_points_that_derive_from_other_EU_legislation_and_information_on_their_location_in_sustainability_statement',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-2',
    datapoint:
      'disclosure_of_list_of_esrs_disclosure_requirements_complied_with_in_preparing_sustainability_statement_following_outcome_of_materiality_assessment',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-3',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_e1_climate_change',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-4',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_e2_pollution',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-5',
    datapoint:
      'explanation_of_negative_materiality_assessment_for_esrs_e3_water_and_marine_resources',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-6',
    datapoint:
      'explanation_of_negative_materiality_assessment_for_esrs_e4_biodiversity_and_ecosystems',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-7',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_e5_circular_economy',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-8',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s1_own_workforce',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-9',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s2_workers_in_value_chain',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-10',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s3_affected_communities',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-11',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_s4_consumers_and_end_users',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-12',
    datapoint: 'explanation_of_negative_materiality_assessment_for_esrs_g1_business_conduct',
    state: 'pending',
    relevance: 'not_mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  },
  {
    datapoint_id: 'IRO-2-13',
    datapoint:
      'explanation_of_how_material_information_to_be_disclosed_in_relation_to_material_impacts_risks_and_opportunities_has_been_determined',
    state: 'pending',
    relevance: 'mandatory',
    automatic_calculated_by_dcycle: false,
    type: 'qualitative'
  }
];
