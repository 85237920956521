import { Trans } from 'react-i18next';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import { CertificationPage } from '../../ui/certificationPage/CertificationPage';
import { useLCATranslation } from '../../../customHooks/translations/useLCATranslation';
import { ReactComponent as LCARequestImg } from '../../../assets/img/lca_request.svg';
import { Variant, VariantType } from '../../ui/certificationPage/types';
import Icon from '../../ui/icon/Icon';
import { useLCARequestStatus } from './hooks/useLCARequestStatus';
import { usePostLCARequest } from './hooks/usePostLCARequest';
import { useDeleteLCARequest } from './hooks/useDeleteLCARequest';

export const RequestLCA = () => {
  const { t } = useLCATranslation({ keyPrefix: 'requestLCA' });
  const checks = [t('checks.1'), t('checks.2'), t('checks.3'), t('checks.4')];

  const handleClickFindOutMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    window.open('https://es.dcycle.io/clientes', '_blank');
  };

  const { data, isLoading } = useLCARequestStatus();

  const { postLCARequest, isPosting } = usePostLCARequest();

  const { deleteLCARequest, isDeleting } = useDeleteLCARequest();

  const isFetching = isLoading || isPosting || isDeleting;

  const handlePostLCARequest = async () => {
    if (isFetching) {
      return;
    }

    await postLCARequest();
  };

  const handleDeleteLCARequest = async () => {
    if (isFetching) {
      return;
    }

    await deleteLCARequest({ id: data?.id });
  };

  const variants: Record<VariantType, Variant> = {
    DEFAULT: {
      lookAndFeel: 'primary',
      text: t('requestButton'),
      onClick: handlePostLCARequest,
      iconNode: null
    },
    REQUESTED: {
      lookAndFeel: 'completed',
      text: t('requestedButton'),
      onClick: handleDeleteLCARequest,
      iconNode: <Icon icon='success' color='white' />
    },
    CANCEL: {
      lookAndFeel: 'warning',
      text: t('cancelButton'),
      onClick: handleDeleteLCARequest,
      iconNode: null
    }
  };

  return (
    <section className='main-page-layout flex-col gap-y-8'>
      <SectionHeader title={t('sectionHeader.title')} />
      <CertificationPage.Layout>
        <div className='flex-col gap-y-6 border-box pr-10 w-full'>
          <CertificationPage.Title>{t('title')}</CertificationPage.Title>
          <CertificationPage.Description>
            <Trans ns='lca' i18nKey='requestLCA.description'>
              <div className='font-body-b1-r'>
                <p className='m-0'>
                  El Análisis de Ciclo de Vida proporciona información crucial sobre la rentabilidad
                  y la eficiencia de los recursos, además de rastrear los esfuerzos sostenibles en
                  la fabricación.
                </p>
                <a
                  href='https://es.dcycle.io/analisis-de-ciclo-de-vida-producto'
                  target='_blank'
                  rel='noreferrer'
                  className='pointer underline'>
                  Descubre más sobre ACVs
                </a>
              </div>
            </Trans>
          </CertificationPage.Description>
          <CertificationPage.SectionLayout>
            <CertificationPage.SectionTitle>{t('checks.title')}</CertificationPage.SectionTitle>
            <CertificationPage.Checks checks={checks} />
          </CertificationPage.SectionLayout>

          <CertificationPage.SectionLayout>
            <CertificationPage.SectionTitle>{t('orderedListTitle')}</CertificationPage.SectionTitle>
            <div className='font-body-b1-r'>
              <Trans ns='lca' i18nKey='requestLCA.orderedList'>
                <ol className='m-0 pl-4'>
                  <li>1</li>
                  <li>2</li>
                  <li>3</li>
                  <li>4</li>
                </ol>
              </Trans>
            </div>
          </CertificationPage.SectionLayout>

          <CertificationPage.ButtonSection>
            <CertificationPage.Button
              VARIANTS={variants}
              isRequested={data?.isRequested ?? false}
              loading={isFetching}
            />
            <Button
              lookAndFeel='secondary'
              text={t('findOutMoreButton')}
              style={{ width: '275px' }}
              size='medium'
              onClick={handleClickFindOutMore}
            />
          </CertificationPage.ButtonSection>
        </div>
        <div className='flex justify-center flex-grow h-full w-full items-center'>
          <LCARequestImg />
        </div>
      </CertificationPage.Layout>
    </section>
  );
};
