import { useTranslation } from 'react-i18next';
import { Column } from '../../../../types/table';
import TagStarTooltip from '../../../improve/sbti/components/criteria/components/tagStar/TagStarTooltip';
import Label from '../../../ui/label/Label';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';

const capitalizeFirstLetter = (text: string) => {
  if (text.length === 0) {
    return text; // Return the empty string if input is empty
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const useColumns = () => {
  const { t } = useTranslation();
  const columns: Column[] = [
    {
      title: t('esgMetrics.general.idDatapoint'),
      dataIndex: 'datapoint_id',
      key: 'datapoint_id'
    },
    {
      title: t('esgMetrics.general.datapoint'),
      dataIndex: 'datapoint',
      key: 'datapoint',
      width: '55%',
      render: (text: string) => (
        <span>{capitalizeFirstLetter(t(`esgMetrics.datapoints.${text}`))}</span>
      )
    },
    {
      title: t('esgMetrics.general.state'),
      dataIndex: 'state',
      key: 'state',
      render: (text: string) =>
        text === 'completed' ? (
          <SuccessLabel key='active'>{t('general.completed')}</SuccessLabel>
        ) : (
          <PendingLabel>{t('general.loading')}</PendingLabel>
        )
    },
    {
      title: t('esgMetrics.general.relevance'),
      dataIndex: 'relevance',
      key: 'relevance',
      render: (text: string) => <span>{t(`esgMetrics.${text}`)}</span>
    },
    {
      title: t('esgMetrics.general.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text: string) =>
        text === 'quantitative' ? (
          <Label lookAndFeel='primary'>{t(`esgMetrics.${text}`)}</Label>
        ) : (
          <Label lookAndFeel='secondary'>{t(`esgMetrics.${text}`)}</Label>
        )
    },
    {
      title: t('esgMetrics.general.ia'),
      dataIndex: 'automatic_calculated_by_dcycle',
      key: 'ia',
      align: 'right',
      render: (autocomplete: boolean) =>
        autocomplete ? (
          <div className='flex justify-around'>
            <TagStarTooltip />
          </div>
        ) : null
    }
  ];
  return columns;
};

export default useColumns;
