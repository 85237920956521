import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../../layout/sectionHeader/SectionHeader';
import { DatapointsTable } from '../components/datapointsTable/DatapointsTable';

import { HeaderCard } from './components/HeaderCard/HeaderCard';
import { datapoints } from './data/datapointsGeneralInfo';

export const GeneralInfo = () => {
  const { t } = useTranslation();

  return (
    <div className='esg-main-container '>
      <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb /> ?? null} />
      <HeaderCard />
      <DatapointsTable datapoints={datapoints} />
    </div>
  );
};
