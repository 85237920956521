import React from 'react';
import SectionHeader from '../../../layout/sectionHeader/SectionHeader';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import { HeaderCard } from './components/headerCard/HeaderCard';
import { DatapointsTable } from '../components/datapointsTable/DatapointsTable';
import { datapoints } from './data/datapoints';

const Social = () => {
  const { t } = useTranslation();
  return (
    <div className='esg-main-container '>
      <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb /> ?? null} />
      <HeaderCard />
      <DatapointsTable datapoints={datapoints} />
    </div>
  );
};

export default Social;
