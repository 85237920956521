import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../../layout/sectionHeader/SectionHeader';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import { DatapointsTable } from '../components/datapointsTable/DatapointsTable';
import { HeaderCard } from './components/headerCard/HeaderCard';
import { datapoints, steps } from './data/datapointsEnvironmental';

export const Environmental = () => {
  const { t } = useTranslation();
  const parsedSteps = steps.map((step) => ({ ...step, title: t(step.text) }));

  return (
    <div className='esg-main-container '>
      <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb /> ?? null} />
      <HeaderCard />
      <StepCarouselList steps={parsedSteps} handleSelect={() => null} />
      <DatapointsTable datapoints={datapoints} />
    </div>
  );
};
