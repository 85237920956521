import { Handle, Position } from 'reactflow';
import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { ScenarioNode } from '../../types/LCAScenarios';

type PieceNodeProps = {
  data: ScenarioNode['data'];
  disableHover?: boolean;
};

const NodeAppearance: React.FC<PieceNodeProps> = ({ data, disableHover }) => {
  const { t } = useLCATranslation();

  return (
    <LCACard.Layout disableHover={disableHover}>
      <div className='flex-col gap-y-4'>
        <LCACard.Content>
          <LCACard.Icon icon={NODE_ICONS[NODE_TYPES.piece]} lookAndFeel='piece' />
          <div className='flex-col'>
            <span className='font-body-b2-sb text-gray-light'>{t('lcaScenarios.piece')}</span>
            <span className='font-body-b1-sb'>{data.label}</span>
          </div>
        </LCACard.Content>
        {Boolean(data.tags?.length) && (
          <div className='flex gap-x-2'>
            {data.tags?.map((tag) => (
              <LCACard.Tag key={data.id + tag} disabled>
                {tag}
              </LCACard.Tag>
            ))}
          </div>
        )}
      </div>
    </LCACard.Layout>
  );
};

export const PieceNode = ({ data }: PieceNodeProps) => {
  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        isConnectableStart={false}
        isConnectableEnd={data.inputs.length === 0}
      />
      <NodeAppearance data={data} />
      <Handle
        type='source'
        position={Position.Right}
        isConnectableStart={data.outputs.length === 0}
      />
    </>
  );
};

PieceNode.Appearance = NodeAppearance;
