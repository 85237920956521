import './styles.scss';

export const VARIANTS = {
  primary: 'body3-font label-div tag-selected-bg-text-color',
  secondary: 'text-tag body3-font label-div tag-bg-text-color',
  tertiary: 'text-tag body3-font label-div tag2-bg-text-color',
  error: 'text-tag body3-font label-div error',
  success: 'text-tag body3-font label-div success',
  disabled: 'text-tag body3-font gray-bg-color2 label-div',
  warning: 'text-tag body3-font label-div warning'
} as const;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  lookAndFeel?: keyof typeof VARIANTS;
  width?: string;
  size?: 'small' | 'medium';
}

const Label = ({ children, lookAndFeel = 'primary', width, size = 'medium', ...rest }: Props) => {
  const style = {
    width,
    ...rest.style
  };
  return (
    <div className={`${VARIANTS[lookAndFeel]} ${size}`} style={style} {...rest}>
      {children}
    </div>
  );
};

export default Label;
