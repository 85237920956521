import { Trans, useTranslation } from 'react-i18next';
import { LogisticReportFront } from '../../../../../../types/entities/logistics';
import formatNumber from '../../../../../../utils/formatNumber';
import numberToDecimal, { numberToDecimalNonZero } from '../../../../../../utils/numberToDecimal';
import './styles.scss';

const emptyValue = {
  value: 0,
  units: '-'
};

const empty = {
  total: emptyValue,
  // scope1: 0,
  // scope2: 0,
  // scope3: {
  //   wttEmissions: 0,
  //   wtwSubcontracted: 0,
  //   wtwEmissionsCleaning: 0
  // },
  vehicleInfo: {},
  emissionFactorsUsed: {
    logisticFuels: [],
    logisticsTocs: []
  },
  tocEmissions: {
    iv: emptyValue,
    tkm: emptyValue,
    kgCO2e: emptyValue
  },
  hocEmissions: {
    iv: emptyValue,
    tkm: emptyValue,
    kgCO2e: emptyValue
  },
  hocsInfo: {},
  transportModeInfo: {},
  hubCategoryInfo: {}
};

const useGetLogisticExcelReportData = (startDate: string, endDate: string, language: string) => {
  const { t } = useTranslation();

  const parseSelfOrganizationData = (logisticReport: LogisticReportFront) => {
    if (!logisticReport) return null;
    if (
      logisticReport.scope1 === undefined ||
      logisticReport.scope2 === undefined ||
      logisticReport.scope3 === undefined ||
      logisticReport.total === undefined
    )
      return null;

    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.totalCarbonFootprint', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1,
        width: '19.03%'
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.scope1', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1,
        width: '19.03%'
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.scope2', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1,
        width: '19.03%'
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.scope3', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 3,
        width: '42.9%'
      }
    ];

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimal(logisticReport.total.value)).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(
                numberToDecimal(
                  logisticReport.scope1.ttwEmissions.value +
                    logisticReport.scope1.hubsEmissions.value
                )
              ).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimal(logisticReport.scope2.hubsEmissions.value)).toString() +
                ' kg CO₂e'}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row last-ones' style={{ paddingLeft: '0.75rem' }}>
              <span>{t('logistics.fuel', { lng: language })}:</span>
              {formatNumber(numberToDecimal(logisticReport.scope3.wttEmissions.value)).toString() +
                ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: 'calc(42.9% / 3)'
        },
        {
          value: (
            <div className='self-organization-row last-ones' style={{ paddingLeft: '0.75rem' }}>
              <span>{t('logistics.cleaning', { lng: language })}:</span>
              {formatNumber(
                numberToDecimal(logisticReport.scope3.wtwEmissionsCleaning.value)
              ).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: 'calc(42.9% / 3)'
        },
        {
          value: (
            <div className='self-organization-row last-ones' style={{ paddingLeft: '0.75rem' }}>
              <span>{t('logistics.subcontracted', { lng: language })}:</span>
              {formatNumber(
                numberToDecimal(
                  logisticReport.scope3.wtwSubcontracted.value +
                    logisticReport.scope3.hubEmissions.value
                )
              ).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: 'calc(42.9% / 3)'
        }
      ]
    ];

    return {
      header,
      rows
    };
  };

  const parseTocData = (
    item: LogisticReportFront['vehicleInfo']['[vehicleId: string]'],
    vehicleId: string,
    index: number,
    total: number
  ) => {
    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.toc', { lng: language })} {`(${index}/${total})`}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.geiValue', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.tkmClientSpecific', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.wtwGEIEmission', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      }
    ];

    let fuelData = '-';
    Object.entries(item.fuelsUsed).map(([key, value], i) => {
      if (i === 0)
        fuelData = `${t(`templates.logistics_recharges.fuel_types.${key}`)} ${formatNumber(
          value.value
        )} ${t(`units.${value.units}`)}`;
      else
        fuelData += `, ${t(`templates.logistics_recharges.fuel_types.${key}`)} ${formatNumber(
          value.value
        )} ${t(`units.${value.units}`)}`;
    });

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t(`logistics.tocs.${vehicleId}`)}</strong>
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.iv.value || 0, 2)).toString() +
                ' g CO₂e/tkm'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimal(item?.tkm.value || 0, 2)).toString() + ' tkm'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.kgCO2e.value || 0, 2)).toString() +
                ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '1.5rem' }}>
              {t('logistics.fuel', { lng: language })}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {fuelData}
            </div>
          ),
          colspan: 3
        }
      ],
      [
        {
          value: (
            <div
              className='self-organization-row'
              style={{ paddingLeft: '1.5rem', whiteSpace: 'pre-wrap' }}>
              {t('logistics.kmEmpty', { lng: language })}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(item.kmEmpty.value) + ' km'}
            </div>
          ),
          colspan: 3
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '1.5rem' }}>
              {t('logistics.kmTravelled', { lng: language })}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(item.kmTotal.value) + ' km'}
            </div>
          ),
          colspan: 3
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '1.5rem' }}>
              {t('logistics.tonsTransported', { lng: language })}
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(item.tonsTransported.value) + ' t'}
            </div>
          ),
          colspan: 3
        }
      ]
    ];

    return {
      header,
      rows: rows
    };
  };

  const parseSumData = (logisticReport: LogisticReportFront, total: number) => {
    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.tocsSum', { total, lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.geiValue', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.tkmClientSpecific', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.wtwGEIEmission', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      }
    ];

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row gray' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.totalEmissions', { lng: language })}</strong>
            </div>
          ),
          width: '25%',
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>
                {formatNumber(
                  numberToDecimalNonZero(logisticReport.tocEmissions.iv.value || 0, 2)
                ).toString() + ' g CO₂e/tkm'}
              </strong>
            </div>
          ),
          width: '25%',
          colspan: 1
        },
        {
          value: (
            <strong>
              <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
                <strong>
                  {formatNumber(
                    numberToDecimal(logisticReport.tocEmissions.tkm.value || 0, 2)
                  ).toString() + ' tkm'}
                </strong>
              </div>
            </strong>
          ),
          width: '25%',
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>
                {formatNumber(
                  numberToDecimalNonZero(logisticReport.tocEmissions.kgCO2e.value || 0, 2)
                ).toString() + ' kg CO₂e'}
              </strong>
            </div>
          ),
          width: '25%',
          colspan: 1
        }
      ]
    ];

    return {
      header,
      rows
    };
  };

  const parseSumDataHocs = (logisticReport: LogisticReportFront, total: number) => {
    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.hubs', { total, lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.intensityValue', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.clientTons', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.geiEmission', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      }
    ];

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row gray' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.totalEmissions', { lng: language })}</strong>
            </div>
          ),
          width: '25%',
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>
                {formatNumber(
                  numberToDecimalNonZero(logisticReport.hocEmissions.iv.value || 0, 2)
                ).toString() + ' g CO₂e/t'}
              </strong>
            </div>
          ),
          width: '25%',
          colspan: 1
        },
        {
          value: (
            <strong>
              <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
                <strong>
                  {formatNumber(
                    numberToDecimal(logisticReport.hocEmissions.tkm.value || 0, 2)
                  ).toString() + ' t'}
                </strong>
              </div>
            </strong>
          ),
          width: '25%',
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>
                {formatNumber(
                  numberToDecimalNonZero(logisticReport.hocEmissions.kgCO2e.value || 0, 2)
                ).toString() + ' kg CO₂e'}
              </strong>
            </div>
          ),
          width: '25%',
          colspan: 1
        }
      ]
    ];

    return {
      header,
      rows
    };
  };

  const parseGeneralData = () => {
    const rows = [
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.period', { lng: language })}</strong>
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {`${startDate} - ${endDate}`}
            </div>
          ),
          colspan: 1,
          width: '75%'
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.dataType', { lng: language })}</strong>
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div
              className='self-organization-row'
              style={{ paddingLeft: '0.75rem', whiteSpace: 'pre-wrap' }}>
              {t('logistics.primaryDataSecondaryData', { lng: language })}
            </div>
          ),
          colspan: 1,
          width: '75%'
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.isoTitle', { lng: language })}</strong>
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div
              className='self-organization-row'
              style={{ paddingLeft: '0.75rem', whiteSpace: 'pre-wrap' }}>
              {t('logistics.isoText', { lng: language })}
            </div>
          ),
          colspan: 1,
          width: '75%'
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.dataVerificationDeclaration', { lng: language })}</strong>
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.dataHasNotBeenVerified', { lng: language })}
            </div>
          ),
          colspan: 1,
          width: '75%'
        }
      ],
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t('logistics.additionalInfoTitle', { lng: language })}</strong>
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <Trans i18nKey='logistics.additionalInfo' lang={language}>
                <span className='flex gap-x-1'>
                  Consulta este
                  <strong>
                    <a
                      className='hyperlink-logistics'
                      href='https://es.dcycle.io/post/beneficios-del-marco-operativo-glec'
                      target='_blank'
                      rel='noreferrer'>
                      link
                    </a>
                  </strong>
                  para entender la
                  <strong>
                    <a
                      className='hyperlink-logistics'
                      href='https://smart-freight-centre-media.s3.amazonaws.com/documents/GLEC_FRAMEWORK_v3_UPDATED_13_12_23.pdf'
                      target='_blank'
                      rel='noreferrer'>
                      metodología
                    </a>
                  </strong>
                  utilizada
                </span>
              </Trans>
            </div>
          ),
          colspan: 1,
          width: '75%'
        }
      ]
    ];

    return {
      rows
    };
  };

  const parseHocsData = (
    item: LogisticReportFront['hocsInfo']['[hubName: string]'],
    hubName: string,
    index: number,
    total: number
  ) => {
    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.hubs', { lng: language })} {`(${index}/${total})`}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.intensityValue', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.clientTons', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.geiEmission', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      }
    ];

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{hubName}</strong>
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.intensityValue.value || 0, 2)).toString() +
                ' g CO₂e/t'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.clientTons.value || 0, 2)).toString() +
                ' t'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimal(item?.emissions.value || 0, 2)).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        }
      ]
    ];
    return {
      header,
      rows: rows
    };
  };

  const parseTransportModes = (
    item: LogisticReportFront['transportModeInfo']['[transportMode: string]'],
    transportMode: string,
    index: number,
    total: number
  ) => {
    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ padding: '0 0.75rem' }}>
              {t('logistics.transportMode', { lng: language })} {`(${index}/${total})`}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ padding: '0 0.75rem' }}>
              {t('logistics.intensityValue', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ padding: '0 0.75rem' }}>{t('logistics.tkm', { lng: language })} *</span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ padding: '0 0.75rem' }}>
              {t('logistics.geiEmission', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ padding: '0 0.75rem' }}>
              {t('logistics.activityDistance', { lng: language })} (1)
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ padding: '0 0.75rem' }}>
              {t('logistics.adjustmentFactor', { lng: language })} (2)
            </span>
          </div>
        ),
        colspan: 1
      }
    ];

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              <strong>{t(`logisticMode.${transportMode}`, { lng: language })}</strong>
            </div>
          ),
          colspan: 1,
          width: '16.66666%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.iv || 0, 2)).toString() + ' g CO₂e/tkm'}
            </div>
          ),
          colspan: 1,
          width: '16.66666%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.tkm || 0, 2)).toString() + ' tkm'}
            </div>
          ),
          colspan: 1,
          width: '16.66666%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimal(item?.emissions || 0, 2)).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: '16.66666%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {item?.activityDistance}
            </div>
          ),
          colspan: 1,
          width: '16.66666%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {item?.adjustmentFactor !== 'not_apply'
                ? item?.adjustmentFactor
                : t('logistics.not_apply', { lng: language })}
            </div>
          ),
          colspan: 1,
          width: '16.66666%'
        }
      ]
    ];

    return {
      header,
      rows
    };
  };

  const parseHubsCategory = (
    item: LogisticReportFront['hubCategoryInfo']['[hubCategory: string]'],
    hubCategory: string,
    index: number,
    total: number
  ) => {
    const header = [
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.hubCategory', { lng: language })} {`(${index}/${total})`}
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.intensityValue', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.tons', { lng: language })} *
            </span>
          </div>
        ),
        colspan: 1
      },
      {
        value: (
          <div className='self-organization-header'>
            <span style={{ paddingLeft: '0.75rem' }}>
              {t('logistics.geiEmission', { lng: language })}
            </span>
          </div>
        ),
        colspan: 1
      }
    ];

    const rows = [
      [
        {
          value: (
            <div className='self-organization-row' style={{ padding: '0 0.75rem' }}>
              <strong>{t(`logisticHocs.${hubCategory}`)}</strong>
            </div>
          ),
          colspan: 1
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.iv || 0, 2)).toString() + ' g CO₂e/t'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimalNonZero(item?.tons || 0, 2)).toString() + ' t'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        },
        {
          value: (
            <div className='self-organization-row' style={{ paddingLeft: '0.75rem' }}>
              {formatNumber(numberToDecimal(item?.emissions || 0, 2)).toString() + ' kg CO₂e'}
            </div>
          ),
          colspan: 1,
          width: '25%'
        }
      ]
    ];

    return {
      header,
      rows
    };
  };

  return {
    parseSelfOrganizationData,
    parseTocData,
    parseSumData,
    parseSumDataHocs,
    parseGeneralData,
    parseHocsData,
    parseTransportModes,
    parseHubsCategory,
    empty
  };
};

export default useGetLogisticExcelReportData;
