import { adaptLCAPatchNodeToAPI, adaptLCAPostNodeToAPI } from '../../../adapters/lca';
import type {
  LCAConnection,
  ScenarioNodeType
} from '../../../components/lca/Scenarios/types/LCAScenarios';
import type {
  LCABlockBack,
  LCAPatchNode,
  LCAPostNode
} from '../../../components/lca/types/LCABlock';
import apiFetch from '../../../utils/apiFetch';

type Params = {
  acv_id: string;
};

export const getLCABlocks = async ({ acv_id }: Params): Promise<LCABlockBack[]> => {
  const response = await apiFetch('GET', `/lca/${acv_id}/canvas/nodes`);

  const items: LCABlockBack[] = response.data.items;

  return items;
};

export const getLCAConnections = async (): Promise<LCAConnection[]> => {
  const response = await apiFetch('GET', '/lca/canvas/connections');

  return response.data.items;
};

type PostLCAConnectionParams = {
  sourceId: string;
  sourceType: ScenarioNodeType;
  targetId: string;
  targetType: ScenarioNodeType;
  quantity: number;
  unit: Unit;
};

export const postLCAConnection =
  (organization_id?: string) =>
  async ({
    sourceId,
    sourceType,
    targetId,
    targetType,
    quantity,
    unit
  }: PostLCAConnectionParams) => {
    const response = await apiFetch('POST', '/lca/canvas/connections', {
      source_id: sourceId,
      source_type: sourceType,
      target_id: targetId,
      target_type: targetType,
      quantity,
      unit_id: unit.id,
      organization_id
    });

    return response.data;
  };

type DeleteLCAConnectionParams = {
  target_id: string;
  source_id: string;
  acv_id: string;
};

export const deleteLCAConnection = async ({
  target_id,
  source_id,
  acv_id
}: DeleteLCAConnectionParams) => {
  const response = await apiFetch(
    'DELETE',
    `/lca/${acv_id}/canvas/connections/${source_id}/${target_id}`
  );

  return response.data;
};

type PutLCAConnectionParams = {
  connection_id: string;
  quantity: number;
  unit: Unit;
};

export const patchLCAConnection = async ({
  connection_id,
  quantity,
  unit
}: PutLCAConnectionParams) => {
  const response = await apiFetch('PATCH', `/lca/canvas/connections/${connection_id}`, {
    quantity,
    unit_id: unit.id
  });

  return response.data;
};

export const createLCANode = async (data: LCAPostNode) => {
  const response = await apiFetch(
    'POST',
    `/lca/${data.acv_id}/canvas/node`,
    adaptLCAPostNodeToAPI(data)
  );

  return response.data as LCABlockBack;
};

export const patchLCANode = async (data: LCAPatchNode) => {
  const response = await apiFetch(
    'PATCH',
    `/lca/${data.acv_id}/canvas/node/${data.node_id}`,
    adaptLCAPatchNodeToAPI(data)
  );

  return response.data as LCABlockBack;
};

export const getLCAMaterials = async () => {
  const response = await apiFetch('GET', `/lca/materials`);

  return response.data.items;
};
type IGetLCAMaterialNodeMetadata = {
  node_id: string;
  acv_id: string;
};

export const getLCAMaterialNodeMetadata = async ({
  node_id,
  acv_id
}: IGetLCAMaterialNodeMetadata) => {
  return { material_id: null };

  const response = await apiFetch('GET', `/lca/${acv_id}/canvas/material/${node_id}`);

  return response.data as {
    material_id?: string;
  };
};

type ICreateLCAConnection = {
  source_id: string;
  target_id: string;
  acv_id: string;
};
export const createLCAConnection = async ({
  acv_id,
  source_id,
  target_id
}: ICreateLCAConnection) => {
  const response = await apiFetch('POST', `/lca/${acv_id}/canvas/connections`, {
    source: source_id,
    target: target_id
  });

  return response.data;
};
