import { createContext, useContext, useEffect, useState } from 'react';
import useAdapterGHG from '../adapter/useAdapterGHG';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import useGetData from '../../../../../customHooks/useGetData';
import { getGHGReportInfo } from '../../../../../services/api/ghg';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';

const useForm = () => {
  const { adaptBack2Front, emptyFormData } = useAdapterGHG();
  const selectedOrganization = useSelectedOrganization();

  const {
    data: formDataFilled,
    loading: loadingData,
    error
  } = useGetData<IGHGBack>(getGHGReportInfo, []);

  const [formData, setFormData] = useState<IGHGFront>(emptyFormData); // todo
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    if (!formDataFilled || error) return;
    adaptBack2Front({
      ...formDataFilled,
      trade_name: selectedOrganization?.trade_name,
      description: selectedOrganization?.description
    })
      .then((res) => {
        setFormData(res);
        setIsAllSelected(res.scopes.every((scope) => scope.selected));
      })
      .catch(() => {
        setFormData(emptyFormData);
        setIsAllSelected(false);
      });
  }, [formDataFilled]);

  const onChangeScopes = (id: string) => () => {
    setFormData((prev) => {
      const newScopes = prev.scopes.map((scope) => {
        if (scope.id === id) {
          return {
            ...scope,
            selected: !scope.selected
          };
        }

        return scope;
      });

      const allSelected = newScopes.every((scope) => scope.selected);

      setIsAllSelected(allSelected);
      return {
        ...prev,
        errors: prev.errors.filter((error) => error.error !== 'scopes'),
        scopes: newScopes
      };
    });
  };

  const selectAll = () => {
    setIsAllSelected((prev) => {
      const newAllSelected = !prev;

      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.filter((error) => error.error !== 'scopes'),
        scopes: prev.scopes.map((scope) => ({ ...scope, selected: newAllSelected }))
      }));

      return newAllSelected;
    });
  };

  return {
    loadingData,
    isAllSelected,
    formData,
    setFormData,
    onChangeValue,
    onChangeScopes,
    selectAll
  };
};

export default useForm;

type FormContext = {
  loadingData: boolean;
  isAllSelected: boolean;
  formData: IGHGFront;
  onChangeValue: (attribute: Exclude<keyof IGHGFront, 'errors'>) => (arg: unknown) => void;
  onChangeScopes: (id: string) => () => void;
  selectAll: () => void;
  intensityMetricOptions: SelectOptionFormat[];
  approachOptions: SelectOptionFormat[];
  verificationOptions: SelectOptionFormat[];
  loadingOptions: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postData: (data: IGHGFront) => Promise<any>;
};

const FormContext = createContext<FormContext | undefined>(undefined);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};

export const FormProvider = FormContext.Provider;
