import { createContext, useContext } from 'react';
import { ScenarioNodeData } from '../../types/LCAScenarios';
import { DraggableProcess } from '../DraggableItems/DraggableProcess';
import { DraggableMaterial } from '../DraggableItems/DraggableMaterial';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';

type CreateNodeContextType = {
  target?: ScenarioNodeData;
  setTarget: React.Dispatch<React.SetStateAction<ScenarioNodeData | undefined>>;
};

const CreateNodeContext = createContext<CreateNodeContextType | null>(null);

type ProviderProps = {
  target?: ScenarioNodeData;
  setTarget: React.Dispatch<React.SetStateAction<ScenarioNodeData | undefined>>;
};

const CreateNodeProvider: React.FC<ProviderProps> = ({ children, target, setTarget }) => {
  return (
    <CreateNodeContext.Provider value={{ target, setTarget }}>
      {children}
    </CreateNodeContext.Provider>
  );
};

export const useCreateNodeContext = () => {
  const context = useContext(CreateNodeContext);
  if (context === null) {
    throw new Error('useCreateNodeContext must be used within a CreateNodeProvider');
  }

  return context;
};

export const CreateNode = () => {
  const { t } = useLCATranslation();

  return (
    <div className='flex-col gap-y-4'>
      <div className='flex-col gap-y-2'>
        <p className='m-0 font-body-b1-b'>{t('modules.title')}</p>
        <p className='m-0 font-body-b2-r text-neutral-gray-warm-20'>{t('modules.description')}</p>
      </div>
      <div className='flex-col gap-y-2'>
        <DraggableProcess />
        <DraggableMaterial />
      </div>
    </div>
  );
};

CreateNode.Provider = CreateNodeProvider;
