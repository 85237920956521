import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CSRD_ARTICLE, DOUBLE_MATERIALITY_ARTICLE, VIDEOS } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import Button from '../../ui/button/Button';
import CardList from '../../ui/cardList/CardListRefactored';
import FormHeader from '../../ui/formComponents/formHeader/FormHeader';
import HelpCTA from '../../ui/helpCTA/HelpCTA';
import Modal from '../../ui/modal/Modal';
import YoutubeVideo from '../../ui/videoPlayer/YoutubeVideo';
import VideoTutorialPopup from '../../ui/videoTutorialPopup/VideoTutorialPopup';
import useData from './hooks/useData';

export const EsgMetrics = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });
  const { t: tN } = useTranslation();

  const navigate = useNavigate();
  const { data } = useData();

  const [showVideo, setShowVideo] = useState(false);

  const videos = VIDEOS.csrd as { [key: string]: string };

  const videoLink = videos[i18n.resolvedLanguage] as string;
  const handleOnClickButton = () => {
    navigate(ROUTES.MEASURE_ESG_METRICS_INFOGRAPHY);
  };

  const handleGoToArticle = () => {
    window.open(DOUBLE_MATERIALITY_ARTICLE['es'], '_blank');
  };

  return (
    <section className='vehicles'>
      <div className='vehicles__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
        <Breadcrumb />
      </div>
      <HelpCTA description={tN('doubleMateriality.help')}>
        <div className='flex'>
          <Button
            lookAndFeel='primary'
            text={tN('doubleMateriality.helpButton')}
            size='tiny'
            onClick={handleGoToArticle}
          />
        </div>
      </HelpCTA>

      <div className='infinite-scroll-wrapper-card'>
        <CardList
          listView={false}
          title={t('title')}
          description={t('description')}
          buttons={
            <Button
              lookAndFeel='primary'
              text={t('button')}
              onClick={handleOnClickButton}
              size='small'
              style={{ width: '100%' }}
            />
          }
          loading={false}>
          <>
            {data.map((element) => (
              <>{element}</>
            ))}
          </>
        </CardList>
        <VideoTutorialPopup
          title={t('videoTitle')}
          description={t('discover')}
          image={'/images/carla.png'}
          onClick={() => setShowVideo(true)}
        />
        <Modal show={showVideo} onClose={() => setShowVideo(false)} width='700px'>
          <FormHeader title={t('modaltutorialTitle')} />
          <div className='flex-col gap-y-4 font-body-b2-sb mb-8'>
            <p className='m-0'>{t('subtitle')}</p>
            <a
              href={
                i18n.resolvedLanguage in CSRD_ARTICLE
                  ? CSRD_ARTICLE[i18n.resolvedLanguage as 'en' | 'es']
                  : CSRD_ARTICLE.es
              }
              target='_blank'
              rel='noreferrer noopener'
              className='link'>
              {t('articleLink')}
            </a>
          </div>
          <div className='flex-col gap-y-2 font-body-b2-sb'>
            <p className='m-0'>{t('tutorialTitle')}</p>
            <YoutubeVideo url={videoLink} />
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default EsgMetrics;
