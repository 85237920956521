import { useQuery } from '@tanstack/react-query';
import { getLCADetail } from '../services/getLCADetail';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';

export const useLCADetail = (id?: string) => {
  const flags = useFeatureFlags();

  const { data, isLoading } = useQuery({
    queryKey: ['lca', id],
    queryFn: () => getLCADetail(id, !flags?.lcaScenariosDiagram),
    enabled: Boolean(id)
  });

  return { data, isLoading };
};
