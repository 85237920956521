import { useLCATranslation } from '../../../customHooks/translations/useLCATranslation';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import { ReactComponent as Toolbar } from '../../../assets/img/lca_toolbar.svg';
import { ReactComponent as TotalImpact } from '../../../assets/img/lca_total_impact.svg';
import styles from './scenarios.module.scss';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { LCADiagram } from './components/LCADiagram';

export const LCAScenarios = () => {
  const { t } = useLCATranslation();

  const flags = useFeatureFlags();

  const isShowingDiagram = flags?.lcaScenariosDiagram;

  return (
    <>
      <section className='flex-col gap-y-6 h-95svh'>
        <SectionHeader
          title={t('lcaScenarios.sectionHeader.title')}
          subtitle={t('lcaScenarios.sectionHeader.subtitle')}
        />
        {isShowingDiagram && <LCADiagram />}

        {!isShowingDiagram && (
          <div
            className={`flex-grow relative border-box on-card-gray-bg-color card-border-color border-1 border-solid rounded-8 ${styles['scenarios']}`}>
            <TotalImpact className='absolute top-4 right-4' />
            <Toolbar className='absolute bottom-4 right-4' />
          </div>
        )}
      </section>
    </>
  );
};
